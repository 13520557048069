import { useState, useEffect } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import {
  SelectedFilters,
} from "../../../models/Items/ItemListRequest";
import { Permissions, PermissionsFriendlyNames, SortDirection } from "../../../models/Enum";
import Filters from "../Filters";
import {
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import Loader from "../../../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { GRID_ACTION_DELETE_ICON, NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE, NO_ROWS_GRID_MESSAGE } from "../../../components/GridUtilityComponents";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import SessionService from "../../../services/SessionService";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { updateQuoteListRequest } from "../../../reducers/quotesReducer";
import { QuoteBasicDetail } from "../../../models/Quotes/QuoteBasicDetail";
import moment from "moment";
import { DATE_FORMAT } from "../../../utils/constants";
import ExportQuoteListing from "../../../components/ExportQuoteListing";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Add } from "@mui/icons-material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { formatAmount } from "../../../utils/helpers";

export interface QuoteListResponse {
  productQuotes?: QuoteBasicDetail[];
  totalCount?: number;
}

export default function QuoteList() {
  const [isLoading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState<QuoteBasicDetail[]>([]);

  const [allRecordsSelected, setAllRecordsSelected] = useState(false);

  const [quotesTotalCount, setQuotesTotalCount] = useState(0);
  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);

  const [deleteModalOpen, setDeleteOpenModal] = useState(false);
  const [quoteId, setQuoteId] = useState(0);

  let navigate = useNavigate();

  const dispatch = useAppDispatch();

  const quoteListRequestState = useAppSelector((state) => state.quotes.quoteListRequest);

  const sessionService = SessionService.getInstance();

  const initialQuoteListRequest = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: "quoteId",
    sortDirection: SortDirection.DESC,
  };

  const actionColumn = {
    field: "actions",
    headerName: "Action",
    sortable: false,
    width: 130,
    renderCell: (params: GridCellParams) => {
      const { id, productId, shortDesc, stockCode  } = params?.row || {};
      const hasPermission = sessionService.hasPermission(Permissions.ManageQuote);
      const hasOnlyViewPermission = sessionService.hasPermission(Permissions.ViewQuotes) && !(sessionService.hasPermission(Permissions.ManageQuote)
      || sessionService.hasPermission(Permissions.ApproveQuoteCost) || sessionService.hasPermission(Permissions.ApproveDNAndUMAP)
      || sessionService.hasPermission(Permissions.ViewQuoteHistory));
  
      const handleEditClick = () => {
        navigate(`/edit-quote/${id}`, { state: { productId, shortDesc, stockCode } });
      };

      const handleCopyClick = () => {
        navigate(`/create-quote`, { state: { quoteId: id, viewMode: "CopyQuoteDetail" } });
      };
  
      return (
        <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          {!hasOnlyViewPermission && <Tooltip title="Edit Quote" arrow>
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={handleEditClick}
            label="Edit"
          />
          </Tooltip>}

          {hasPermission && (
            <Tooltip title="Delete Quote" arrow>
              {GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)}
            </Tooltip>
          )}

          {hasPermission && (
            <Tooltip title="Copy Quote" arrow>
            <GridActionsCellItem
              icon={<ContentCopyIcon />}
              onClick={handleCopyClick}
              label="Copy"
            />
            </Tooltip>
          )}
        </div>
      );
    },
  };

  const QuoteListGridColumns: GridColDef[] = [
    { field: "id", headerName: "Quote Id", width: 120, disableColumnMenu: true },
    { field: "productId", headerName: "Product Id", width: 120, disableColumnMenu: true },
    {
      field: "quoteNumber",
      headerName: "Quote Number",
      sortable: false,
      disableColumnMenu: true,
      width: 160
    },
    {
      field: "quoteDate",
      headerName: "Quote Date",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      valueGetter: (params) => params?.value ? moment(params?.value).format(DATE_FORMAT) : ''
    },
    {
      field: "quoteApprovalType",
      headerName: "Quote Approval Type",
      sortable: false,
      disableColumnMenu: true,
      width: 160
    },
    {
      field: "isAccepted",
      headerName: "Is Accepted",
      sortable: false,
      disableColumnMenu: true,
      width: 160,
      renderCell: (params) => {
        const isAccepted = params.value;
        
        return isAccepted && 
        <GridActionsCellItem
          icon={<CheckBoxIcon />}
          label="Is Accepted"
          style={{ cursor: "default" }}
        />
      }
    },
    {
      field: "stockCode",
      headerName: "Stock Code",
      disableColumnMenu: true,
      width: 125,
    },
    // {
    //   field: "pdStockcode",
    //   headerName: "PD StockCode",
    //   disableColumnMenu: true,
    //   width: 130,
    // },
    {
      field: "baseCode",
      headerName: "Base Code",
      disableColumnMenu: true,
      width: 130,
    },
    {
      field: "pdCode",
      headerName: "PD BaseCode",
      disableColumnMenu: true,
      width: 130,
    },
    {
      field: "brandCollectionDisplayText",
      headerName: "Brand Collection",
      disableColumnMenu: true,
      width: 130
    },
    {
      field: "shortDesc",
      headerName: "Short Description",
      disableColumnMenu: true,
      width: 180
    },
    {
      field: "fobCost",
      headerName: "FOB Cost",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      valueGetter: (params) => params?.value ? formatAmount(params.value, "USD") : ''
    },
    {
      field: "ipCube",
      headerName: "IP Cube",
      sortable: false,
      disableColumnMenu: true,
      width: 100
    },
    {
      field: "dnPrice",
      headerName: "DN Price",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      valueGetter: (params) => params?.value ? formatAmount(params.value, "USD") : ''
    },
    {
      field: "umapPrice",
      headerName: "UMAP Price",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      valueGetter: (params) => params?.value ? formatAmount(params.value, "USD") : ''
    },
    {
      field: "grossGMPercent",
      headerName: "Gross GM Percent",
      sortable: false,
      disableColumnMenu: true,
      width: 140,
      valueGetter: (params) => params?.value ? `${params?.value}%` : ''
    },
    {
      field: "vendorDisplayText",
      headerName: "Vendor",
      sortable: false,
      disableColumnMenu: true,
      width: 120
    },
    
    {
      field: "height",
      headerName: "Height",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "width",
      headerName: "Width",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "stageDisplayText",
      headerName: "Stage",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "statusDisplayText",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      width: 155,
    },
    {
      field: "pdFamilyName",
      headerName: "PD Family",
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      align: 'center',
    },
    {
      field: "seriesNameDisplayText",
      headerName: "Series Name",
      sortable: false,
      disableColumnMenu: true,
      width: 110,
    },
    {
      field: "marketingDesignerDisplayText",
      headerName: "Designer",
      sortable: false,
      disableColumnMenu: true,
      width: 155,
    },
    {
      field: "categoryDisplayText",
      headerName: "Category",
      sortable: false,
      disableColumnMenu: true,
      width: 80,
    },
    {
      field: "functionDisplayText",
      headerName: "Function",
      sortable: false,
      disableColumnMenu: true,
      width: 90,
    },
    {
      field: "primaryMaterialDisplayText",
      headerName: "Primary Material",
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      align: 'center',
    },
    {
      field: "secondaryMaterialDisplayText",
      headerName: "Secondary Material",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      align: 'center',
    },
  ];

  // mapping used to find original column name that should be used e.g. for sortBy parameter
  const QuoteListReferenceColumnsMapping = {
    brandCollectionDisplayText: "brandCollection",
    stageDisplayText: "stage",
    statusDisplayText: "status",
    marketingDesignerDisplayText: "marketingDesigner",
    categoryDisplayText: "category",
    functionDisplayText: "function",
    id: "quoteId",
    productId: "id",
    seriesNameDisplayText: "seriesName",
  };

  const handleClose = () => {
    setDeleteOpenModal(false);
  }

  const handleDeleteModalOpen = (params) => {
    setDeleteOpenModal(true);
    setQuoteId(params.row.id);
  };

  const deleteSubmit = async () => {
    try {
      api
        .delete<ApiResponse<boolean>>(`/quote/${quoteId}`)
        .then(async (response: any) => {
          if (response?.isSuccess) {
            toast.success(response?.message);
            setDeleteOpenModal(false);
            await getQuotes();
          }
        })
        .catch((error) => {
          setDeleteOpenModal(false);
          toast.error(error?.message);
        });
    } catch (error: any) {
      setDeleteOpenModal(false);
      toast.error(error.message);
    }
  };

  const gridToolbar = () => (
    <GridToolbarContainer>
      <div style={{ marginLeft: "12px" }}>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <ExportQuoteListing
          // selectedRows={selectedRows}
          quotesTotalCount={quotesTotalCount}
          // selectAll={allRecordsSelected}
          setLoading={setLoading}
        />
      </div>
      <GridToolbarQuickFilter sx={{ margin: "5px 30px 5px 5px", width: "20%" }} />
      <div
        style={{
          flexGrow: 1, textAlign: "center", padding: 5,
          display: "flex", justifyContent: "flex-end"
        }}
      >
        {sessionService.hasPermission(Permissions.ManageQuote) &&
          <Button variant="contained"
            component="a"
            href="/create-quote"
            style={{ padding: "12px" }}
          >
            <Add />
            Create New Quote
          </Button>}
      </div>
    </GridToolbarContainer>
  );

  useEffect(() => {
    getQuotes();
  }, [quoteListRequestState]);

  const getQuotes = async () => {
    try {
      setLoading(true);
      api
        .post<ApiResponse<QuoteListResponse>>("/Quote/list", quoteListRequestState)
        .then((response) => {
          setLoading(false);
          if (response.isSuccess) {
            setQuotesTotalCount(response?.data?.totalCount);
            const productQuotes: QuoteBasicDetail[] = (response?.data?.productQuotes || []);
            setQuotes(productQuotes);
          } else {
            throw new Error(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Exception from product list", error);
        });
    } catch (error: any) {
      setLoading(false);
      console.error("Exception from product list", error);
    }
  };

  // if sorted by a field which is not direct field of product table then find the correct column from mapping
  const getSortFieldName = (gridFieldName: string): string => {
    return QuoteListReferenceColumnsMapping[gridFieldName] || gridFieldName;
  };

  // convert state sortBy value to column name that is bound in grid
  const getReverseSortFieldName = (fieldName: string): string => {
    const gridFieldName = Object.keys(QuoteListReferenceColumnsMapping).find(
      (prmKey) => QuoteListReferenceColumnsMapping[prmKey] === fieldName
    );
    return gridFieldName || fieldName;
  };

  const onSortChange = (sorting: GridSortModel) => {
    if (sorting.length === 0 || !sorting[0]?.field) return;

    dispatch(updateQuoteListRequest({
      ...quoteListRequestState,
      sortBy: getSortFieldName(sorting[0].field),
      sortDirection:
        sorting[0].sort === "asc" ? SortDirection.ASC : SortDirection.DESC,
    }));
  };

  const onPaginationChange = (pagination: GridPaginationModel) => {
    dispatch(updateQuoteListRequest({
      ...quoteListRequestState,
      pageIndex: pagination.page + 1,
      pageSize: pagination.pageSize,
    }));
  };

  const handleApplyFilterClick = (filterValues: SelectedFilters) => {
    if (filterValues.projectCode) {
      dispatch(updateQuoteListRequest({
        ...quoteListRequestState,
        projectCode: filterValues.projectCode,
        pageIndex: 1,
      }));
    } else if (Object.values(filterValues)?.length) {
      dispatch(updateQuoteListRequest({
        ...quoteListRequestState,
        ...filterValues,
        pageIndex: 1,
      }));
    } else {
      //reset the filters
      dispatch(updateQuoteListRequest(initialQuoteListRequest));
      setAllRecordsSelected(false);
    }
  };

  const handleExpandClick = () => {
    setFiltersExpanded(!filtersExpanded);
  };

  const hasOnlyViewPermission = sessionService.hasPermission(Permissions.ViewQuotes) && !(sessionService.hasPermission(Permissions.ManageQuote)
    || sessionService.hasPermission(Permissions.ApproveQuoteCost) || sessionService.hasPermission(Permissions.ApproveDNAndUMAP)
    || sessionService.hasPermission(Permissions.ViewQuoteHistory));
  return (
    <>
      {deleteModalOpen &&
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleClose}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={`quote #${quoteId}`}
        />}
      <Filters
        onApplyFilter={handleApplyFilterClick}
        filtersExpanded={filtersExpanded}
        handleExpandClick={handleExpandClick}
        viewMode={PermissionsFriendlyNames[Permissions.ViewQuotes]}
      />
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          height: "70%",
          width: "100%",
          display: "block",
        }}
      >
        <DataGrid
          rows={quotes}
          columns={[...(!hasOnlyViewPermission ? [actionColumn] : []), ...QuoteListGridColumns]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              },
            },
          }}
          rowCount={quotesTotalCount}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick={true}
          disableColumnFilter={true}
          sortingMode="server"
          paginationMode="server"
          sortModel={[
            {
              field: getReverseSortFieldName(quoteListRequestState.sortBy),
              sort:
                quoteListRequestState.sortDirection == SortDirection.ASC
                  ? "asc"
                  : "desc",
            },
          ]}
          onSortModelChange={onSortChange}
          onPaginationModelChange={onPaginationChange}
          paginationModel={{
            page: quoteListRequestState.pageIndex - 1,
            pageSize: quoteListRequestState.pageSize,
          }}
          slots={{
            toolbar: gridToolbar,
            noRowsOverlay: () => NO_ROWS_GRID_MESSAGE,
            noResultsOverlay: () => NO_LOCAL_SEARCH_RESULTS_GRID_MESSAGE,
          }}
          hideFooterSelectedRowCount={allRecordsSelected}
          getRowHeight={() => 'auto'}
          columnVisibilityModel={{
            actions: (
              sessionService.hasPermission(Permissions.ManageQuote) ||
              sessionService.hasPermission(Permissions.ApproveQuoteCost) ||
              sessionService.hasPermission(Permissions.ApproveDNAndUMAP) ||
              sessionService.hasPermission(Permissions.ViewQuotes) ||
              sessionService.hasPermission(Permissions.ViewQuoteHistory)
            )
          }}
        />
      </Box>
    </>
  );
}