import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { Category } from "../../../models/Category";

interface ItemFunctionModalProps {
    isOpen: boolean;
    onClose: VoidFunction;
    formik: any;
    categoryList: Category[];
};

const ItemFunctionModal = (props: ItemFunctionModalProps & {isButtonDisabled: boolean}) => {
    const {isOpen, onClose, formik, categoryList, isButtonDisabled} = props;

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogActions>
                <Button onClick={onClose}>x</Button>
            </DialogActions>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid item xs={12}>
                        <FormControl 
                            fullWidth
                            margin="dense"
                        >
                            <InputLabel 
                            id="category-label"
                            error={formik.errors.category || formik.touched.category && (!formik.values.category) }>
                                {formik.errors.category || formik.touched.category && (!formik.values.category)? "Category is required":"Select Category"}
                            </InputLabel>
                            <Select
                                autoFocus
                                labelId="category-label"
                                id="category"
                                name="category"
                                value={formik.values.category}
                                label= "Select Category"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.category || formik.touched.category && (!formik.values.category)}
                            >
                                {categoryList.filter((category) => category.isActive).map((category) => (
                                    <MenuItem
                                        key={category.id}
                                        value={category.id}
                                    >
                                        {category.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="sapCode"
                                name="sapCode"
                                label={
                                    formik.touched.sapCode && formik.errors.sapCode
                                    ? formik.errors.sapCode
                                    : "Enter SAP Code"
                                }
                                type="number"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.sapCode}
                                error={formik.touched.sapCode && formik.errors.sapCode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin = "dense"
                                id = "jdeCode"
                                name = "jdeCode"
                                label = {
                                    formik.errors.jdeCode && formik.touched.jdeCode ?
                                    formik.errors.jdeCode : 
                                    "Enter JDE Code"
                                }
                                type = "text"
                                fullWidth
                                value = {formik.values.jdeCode ?? null}
                                onChange = {formik.handleChange}
                                error = {formik.touched.jdeCode && formik.errors.jdeCode}
                                onBlur = {formik.handleBlur}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={15}>
                        <TextField
                            margin="dense"
                            id="description"
                            name="description"
                            label={
                                formik.touched.description && formik.errors.description
                                ? formik.errors.description
                                : "Enter Description"
                            }
                            type="text"
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                            error={formik.touched.description && formik.errors.description}
                        />
                    </Grid>
                    <h4>Set Active</h4>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="isActive"
                        value={formik.values.isActive}
                        onChange={(event) => {
                            formik.handleChange(event);
                        }}
                    >
                    <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Active"
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Inactive"
                    />
                    </RadioGroup>

                    <DialogActions>
                        <Button variant="contained" type="submit" disabled={isButtonDisabled}
                        >
                            {formik?.values.id > 0
                            ? "Update Function"
                            : "Add Function"}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default ItemFunctionModal;