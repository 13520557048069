import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ApiResponse } from "../../models/ApiResponse";
import api from "../../services/ApiService";
import { CreateItemRequest } from "../../models/Items/CreateItemRequest";
import { CreateItemDropdownOptions } from "../../models/Items/CreateItemDropdownOptions";
import AttachmentsUploader, { AttachmentsUploaderRef, MultipleAttachment } from "../../components/AttachmentsUploader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import { PrjStageWithStatusAndTag } from "../../models/PrjStage";
import { FIELD_NAMES, ITEM_STATUS_ENUM, PROJECT_MANAGER_ASSIGNMENT, USER_ASSIGNMENTS_VALIDATION_MESSAGES, attachmentLevels, attachmentsValidationMessages, createProductValidations, dataFieldNames, validExtensions } from "./EditItem/Constants";
import Select from '@mui/material/Select';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { Tag } from "../../models/Tag";
import Tags from "../../components/Tags";
import UserAssignments, { UserAssignmentFieldValue, UserDropdownOptions } from "../../components/UserAssignments";
import { UserAssignment } from "../../models/UserAssignment";
import SessionService from "../../services/SessionService";
import { Permissions, PermissionsFriendlyNames } from "../../models/Enum";
import { ProductLayoutCategoryDetail } from "../../models/Items/ProductLayoutCategoryDetail";
import { ProductLayoutFieldDetail } from "../../models/Items/ProductLayoutFieldDetail";
import { KeyValues } from "../../models/Items/FilterOptions";
import { User } from "../../models/User";

const CreateProduct = () => {

  const [attachments, setAttachments] = useState<MultipleAttachment[]>([]);
  const [dropDownOptions, setDropDownOptions] = useState<CreateItemDropdownOptions>({});
  const [selectedDropDownOptions, setSelectedDropDownOptions] = useState<any>(
    {}
  );
  const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState<string[]>([ FIELD_NAMES.status, FIELD_NAMES.function]);
  const [selectedTagOptions, setSelectedTagOptions] = useState<Tag[]>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [productLayoutFields, setProductLayoutFields] = useState<ProductLayoutFieldDetail[]>([]);
  const [statusDropDownOptions, setStatusDropDownOptions] = useState<KeyValues[]>([]);
  const [tagMenuOptions, setTagMenuOptions] = useState<Tag[]>([]);

  const initialUserAssignment: UserAssignment = {
    userId: "",
    assignmentId: "",
    id: 0
  };

  const [selectedUserAssignments, setSelectedUserAssignments] = useState<UserAssignmentFieldValue[]>([initialUserAssignment]);
  const sessionService = SessionService.getInstance();

  const [prjStageTags, setPrjStageTags] = useState<PrjStageWithStatusAndTag[]>([]);
  const [assignmentRoleDropdownOptions, setAssignmentRoleDropdownOptions] = useState<KeyValues[]>([]);
  const [userDropdownOptions, setUserDropdownOptions] = useState<UserDropdownOptions[]>([]);
  const [autoAssignments, setAutoAssignments] = useState<UserAssignment[]>([]);
  const [showAutoAssignmentConfirmModal, setShowAutoAssignmentConfirmModal] = useState(false);

  let navigate = useNavigate();

  const uploaderRef = useRef<AttachmentsUploaderRef>(null);

  // these fields stores the reference id and not the actual value e.g. Stage, Category
  const REFERENCE_FIELDS = [
    "stage",
    "status",
    "marketingDesigner",
    "category",
    "function",
    "brandCollection",
    "style",
    "skuType",
    "romanticSize",
    "seriesName"
  ];

  useEffect(() => {
    getAllDropDownOptions();
    fetchProductLayoutFields();
    fetchStageTag();
    getAssignmentRoleList();
    getUserList();
  }, []);

  const tagIdMap = prjStageTags.map(i => i.tags.map(i => i.id))
  const uniqueTagsIdList = tagIdMap.flat().filter((value, index, self) => self.indexOf(value) === index);
  
  const fetchStageTag = async () => {
      const stageTagResponse = await api.get<ApiResponse<PrjStageWithStatusAndTag[]>>("/Picklist/stages");
      setPrjStageTags(stageTagResponse.data || []);
  }

  /* 
    This function is used to get drop down options data from api
   */
  const getAllDropDownOptions = async () => {
    try {
      // using same filter options that we are using in Product Listing screen + some other drop down using separate API
      const dropDownSources = await api.get<
        ApiResponse<CreateItemDropdownOptions>
      >(`/Item/active-dropdown-options`);

      if (dropDownSources?.isSuccess) {

        if (dropDownSources.data.tags?.length > 0) {
          dropDownSources.data.tags = dropDownSources.data.tags.map(s => (
            {
              id: s.id,
              name: s.name
            }
          ));
        }
        dropDownSources.data.functions = [];
        setDropDownOptions(dropDownSources.data);
      }
    } catch (error) {
      console.error("Exception from API", error);
    }
  };

  /* 
    This function is used to get assignment role drop down options from api
   */
    const getAssignmentRoleList = () => {
      try {
        api
          .get<ApiResponse<any[]>>(`/Assignment/list`)
          .then((response: ApiResponse<any[]>) => {
            if (response.isSuccess) {
              if (response.data?.length > 0) {
                const assignments = response.data.filter(a => a.isActive === true)
                .map(u => {
                  return {
                    id: u.id,
                    name: u.name
                  }
                });
                setAssignmentRoleDropdownOptions(assignments);
              }
            } else {
              throw new Error(response?.message);
            }
          })
          .catch((error) => {
            console.error("Exception from Assignments", error);
          });
      } catch (error: any) {
        console.error(error.message);
      }
    };

  /* 
    This function is used to get user drop down options from api
  */
  const getUserList = () => {
    try {
      api
        .get<ApiResponse<User[]>>(`/UserRole`)
        .then((response: ApiResponse<User[]>) => {
          if (response.isSuccess) {
            if (response.data?.length > 0) {
              const users = response.data.filter((ul) => ul.isActive === true).map(ul => {
                return {
                  id: ul.id,
                  name: ul.name,
                  disabled: false
                }
              });
              setUserDropdownOptions(users);
            }
          } else {
            throw new Error(response?.message);
          }
        })
        .catch((error) => {
          console.error("Exception from User Role", error);
        });
    } catch (error: any) {
      console.error(error.message);
    }
  };

  /**
   * This function is used to fetch edit product layout fields from api and set it to state
   */
  const fetchProductLayoutFields = async () => {
    try {
      const response = await api
        .get<ApiResponse<ProductLayoutCategoryDetail[]>>(`/PickList/layoutFields`);
      if (response.isSuccess) {
        const { data } = response;
        if (data?.length > 0) {
          // Flatten fields array from the api response data
          let flattenedFields = data.flatMap(category => category.fields);

          const prepareLayoutFields = [];
          // Update fields names recieved from api so that it matches with formRenderer keys to avoid casing errors
          flattenedFields.map((field) => {
            const matchedName = formRendererNames.find(
              (fn) => fn.toLowerCase() === field.fieldName.toLowerCase()
            );

            if (matchedName) {
              prepareLayoutFields.push({
                ...field,
                fieldName: matchedName
              });
            }
          });
          setProductLayoutFields(prepareLayoutFields);
        }
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  /**
   * This function is used to handle Autocomplete dropdown value
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const handleAutocompleteOptionsChange = (fieldName, selectedItem) => {
    if (!selectedItem) {
      formik.setFieldValue(fieldName, undefined);
        setSelectedDropDownOptions({
          ...selectedDropDownOptions,
          [fieldName]: undefined,
        });
    } else {
      /* 
        selectedValue variable logic: 
        if dropdown option contains string type value, then string value will be selected.
        else if Reference fields array includes dropdown field name, then id will be selected.
        else name will be selected.
      */
      const selectedValue =
        typeof selectedItem === "string"
          ? selectedItem
          : REFERENCE_FIELDS.includes(fieldName)
          ? selectedItem.id
          : selectedItem.name;
      formik.setFieldValue(fieldName, selectedValue);
        setSelectedDropDownOptions({
          ...selectedDropDownOptions,
          [fieldName]: selectedItem,
        });
    }
  };

  /**
   * This function is used to handle Select dropdown value
   * @param fieldName - Name of the field
   * @param selectedItem - Selected option value
   */
  const handleDropDownOptionsChange = (fieldName, selectedItem) => {
    const selectedValue =
      typeof selectedItem === "string"
        ? selectedItem
        : REFERENCE_FIELDS.includes(fieldName)
          ? selectedItem.value
          : selectedItem.children;
    formik.setFieldValue(fieldName, selectedValue);

    if (fieldName === FIELD_NAMES.category) {
      formik.setFieldValue(FIELD_NAMES.function, undefined);
      handleDropDownFieldsEnabled(FIELD_NAMES.function);
      prepareFunctionDropDownOptions(selectedItem.value);
      
    }
    if (fieldName === FIELD_NAMES.stage) {
      formik.setFieldValue(FIELD_NAMES.status, undefined);
      handleDropDownFieldsEnabled(FIELD_NAMES.status);
      prepareStatusDropDownOptions(selectedItem.value);
      prepareTagOptions(selectedItem.value, selectedTagOptions);
    }
  };

  /**
     * This function is used to remove array product from state and enable the dropdown field
     * @param fieldName - Name of the field to enable it
     */
  const handleDropDownFieldsEnabled = (fieldName) => {
    const isFieldExists = fieldsDisabled.includes(fieldName);

    if (isFieldExists) {
      // Remove the product from the array
      setFieldsDisabled(fieldsDisabled.filter(sec => sec !== fieldName));
    }
  };

  /**
   * This function is used to returns list of DataTypes for a given field name
   * @param categoryName - Category display text field api data
   */
  const prepareFunctionDropDownOptions = (categoryId: number) => {
    if (categoryId && (dropDownOptions.productFunctions.length > 0)) {
      const functionDropDownOptions = dropDownOptions.productFunctions
        .filter(func => func.category === categoryId)
        .map(item => {
          return {
            id: item.id,
            description: item.description
          }
        });
      setDropDownOptions({
        ...dropDownOptions,
        functions: functionDropDownOptions
      });
    }
  };

  const prepareStatusDropDownOptions = (stageId: number) => {
    if (stageId && dropDownOptions?.stageStatusTags?.length) {
      const statusDropDownOptions = dropDownOptions.stageStatusTags
        .find(stage => stage.id === stageId)
        .statuses
        .map(s => {
          return {
            id: s.id,
            description: s.description,
          }
        });
      setStatusDropDownOptions(statusDropDownOptions);
    }
  };

  const prepareTagOptions = (stageId: number, selectedTags: Tag[]) => {
    if (stageId && dropDownOptions?.stageStatusTags?.length) {
      const stageWiseTagOptionsForDropdown = dropDownOptions.stageStatusTags
        .find(stage => stage.id == stageId)
        .tags
        .filter(t => !selectedTags.some((st) => st.id === t.id))
        .map(s => {
          return {
            id: s.id,
            name: s.name
          }
        });
        
      setTagMenuOptions(stageWiseTagOptionsForDropdown);
    }
  };

  /**
   * This function is used to render autocomplete with dropdown option list
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param optionList - Dropdown option array list
   */
  const renderAutocomplete = (
    fieldName: string,
    label: string,
    optionList: Array<any> | undefined
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "32%" }}>
      <label htmlFor={`outlined-${label}`}>
        {label}
        {(fieldName === FIELD_NAMES.brandCollection) && <span className="required-field-asterisk-icon">{` *`}</span>}
      </label>
      <Autocomplete
        id={`outlined-${label}`}
        value={
          selectedDropDownOptions[fieldName]
            ? selectedDropDownOptions[fieldName]
            : null
        }
        onChange={(event, newValue) =>
          handleAutocompleteOptionsChange(fieldName, newValue)
        }
        onBlur={(e) => {
          if(createProductValidations.fields[fieldName]) {
            formik.validateField(fieldName);
          }
          formik.handleBlur(e);
        }}
        options={optionList ? optionList : []}
        getOptionLabel={(option) => option.name || option.description || option}
        disabled={fieldsDisabled.includes(fieldName)}
        style={{
          backgroundColor: fieldsDisabled.includes(fieldName) ? "#EBEBE4" : "#FFFFFF",
          marginTop: "8px"
        }}
        renderInput={(params) => (
          <TextField
            error={
              Boolean(formik.errors[fieldName])
            }
            label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
            key={params.id}
            {...params}
            placeholder="- Select -"
          />
        )}
      />
      {formik.touched[fieldName] && formik.errors[fieldName] && (
        <div className={`formik-error-${fieldName}`}></div>
      )}
    </FormControl>
  );

  /**
   * This function is used to render select dropdown option list
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param optionList - Dropdown option array list
   */
  const renderDropDown = (
    fieldName: string = "",
    label: string = "",
    optionList: Array<any> | undefined
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "32%" }}>
      <label
        htmlFor={`outlined-${'fieldName'}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
        {(fieldName === FIELD_NAMES.stage || fieldName === FIELD_NAMES.status || fieldName === FIELD_NAMES.category || fieldName === FIELD_NAMES.function) && 
        <span className="required-field-asterisk-icon">{` *`}</span>}
      </label>
      <Select
        id={`outlined-${fieldName}`}
        name={fieldName}
        type="text"
        onBlur={(e) => {
          if(createProductValidations.fields[fieldName]) {
            formik.validateField(fieldName);
          }
          formik.handleBlur(e);
        }}
        value={formik.values[fieldName] || ""}
        displayEmpty={true}
        onChange={(event, selectedItem: any) =>
          handleDropDownOptionsChange(fieldName, selectedItem.props)
        }
        renderValue={(selected) => {
          if (!selected) {
            return formik.errors[fieldName] ? formik.errors[fieldName] : `Select`;
          }
          return optionList.find((item) => item.id === selected)?.name || optionList.find((item) => item.id === selected)?.description || selected;
        }}
        disabled={fieldsDisabled.includes(fieldName)}
        sx={{
          backgroundColor: fieldsDisabled.includes(fieldName) ? "#EBEBE4" : "#FFFFFF",
          marginTop: "8px"
        }}
        style={{color: Boolean(formik.errors[fieldName] && formik.touched[fieldName]) && "#d32f2f"}}
        error={
          Boolean(formik.errors[fieldName])
        }
      >
        <MenuItem value="" disabled>Select</MenuItem>
        {optionList && optionList.length > 0 &&
          optionList.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description || item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );

  /**
   * This function is used to render text field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param numeric - Boolean for numeric type field
   */
  const renderTextField = (fieldName, label, numeric = false) => (
    <FormControl style={{ margin: "5px 5px", width: "32%" }}>
      <label htmlFor={`outlined-${label}`}>
        {label}
        {(fieldName === FIELD_NAMES.shortDesc) && <span className="required-field-asterisk-icon">{` *`}</span>}
      </label>
      <TextField
        id={`outlined-${label}`}
        value={formik.values[fieldName] ?? ""}
        label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
        onChange={formik.handleChange}
        onBlur={(e) => {
          if(createProductValidations.fields[fieldName]) {
            formik.validateField(fieldName);
          }
          formik.handleBlur(e);
        }}
        name={fieldName}
        onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
        className="hide-input-arrow"
        inputProps={{ type: numeric ? 'number' : 'text' }}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        style={{ marginTop: "8px" }}
      />
      {formik.touched[fieldName] && formik.errors[fieldName] && (
        <div className={`formik-error-${fieldName}`}></div>
      )}
    </FormControl>
  );

    /**
   * This function is used to render text field for Base Code Automation
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
    const renderTextFieldBaseCodeAuto = (fieldName, label, numeric = false) => (
      <FormControl style={{ margin: "5px 5px", width: "32%" }}>
        <label htmlFor={`outlined-${label}`}>
          {label}
          {(fieldName === FIELD_NAMES.shortDesc) && <span className="required-field-asterisk-icon">{` *`}</span>}
        </label>
        <TextField
          id={`outlined-${label}`}
          value={formik.values[fieldName] ?? ""}
          label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
          onChange={formik.handleChange}
          onBlur={(e) => {
            if(createProductValidations.fields[fieldName]) {
              formik.validateField(fieldName);
            }
            formik.handleBlur(e);
          }}
          name={fieldName}
          onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
          className="hide-input-arrow"
          InputProps={{ 
            endAdornment:
            <InputAdornment position="end">
              <AutoAwesomeOutlinedIcon onClick = {handleBaseCodeAuto} sx = {{cursor:"pointer"}}/>
            </InputAdornment>
          }}
          error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
          style={{ marginTop: "8px" }}
        />
        {formik.touched[fieldName] && formik.errors[fieldName] && (
          <div className={`formik-error-${fieldName}`}></div>
        )}
      </FormControl>
    );

  /**
   * This function is used to Remove attachment from the attachments state
   * @param index - Array index of attachment list
   */
  const handleRemoveAttachment = (index: number) => {
    const updatedAttachments = attachments.filter(
      (a, i) => i !== index
    );
    setAttachments(updatedAttachments);
  };

  // This function is used to reset the Form and Attachment(s)
  const handleFormReset = () => {
    setSelectedDropDownOptions({});
    setAttachments([]);
    setSelectedUserAssignments([initialUserAssignment]);
    formik.resetForm();
    setShowResetConfirmationModal(false);
  };

  const handleCloseModal = () => {
    setShowResetConfirmationModal(false);
  };

  const initialValues: CreateItemRequest = {
    stockcode: null,
    brandCollection: null,
    shortDesc: "",
    // familyName: "",
    seriesName: null,
    pdFamilyName: "",
    baseCode: null,
    pdCode: "",
    stage: null,
    status: null,
    marketingDesigner: null,
    category: null,
    function: null,
    style: null,
    skuType: null,
    drawingNum: "",
    romanticSize: null,
    targetWholesalePrice: null,
    targetIMAP: null,
    tags: [],
    userAssignments: [],
    bC_DesignerSeq: null,
    bC_CategorySeq: null,
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: createProductValidations,
    onSubmit: async () => {

      let isValid = true;
      const formData = new FormData();
      
      // error messages to display in toast
      const newErrors: any = {};

      const containsLevelBC = attachments.some((a) => a.lvl === attachmentLevels.BC);
      const containsLevelSC = attachments.some((a) => a.lvl === attachmentLevels.SC);

      // Add attachment validation check
      if (attachments && attachments.length > 0) {
        const {
          stockCodeToastError,
          baseCodeToastError,
          stockCodeInputBoxError,
          baseCodeInputBoxError,
          attachmentTypeToastError
        } = attachmentsValidationMessages;

        if ((containsLevelBC && !(formik.values[FIELD_NAMES.baseCode])) ||
          (containsLevelBC && formik.values[FIELD_NAMES.baseCode].trim() === "")) {
          formik.setErrors({ baseCode: baseCodeInputBoxError });
          newErrors.baseCode = baseCodeToastError;
          isValid = false;
        }

        if ((containsLevelSC && !(formik.values[FIELD_NAMES.stockcode])) ||
          (containsLevelSC && formik.values[FIELD_NAMES.stockcode].trim() === "")) {
          formik.setErrors({ stockcode: stockCodeInputBoxError });
          newErrors.stockCode = stockCodeToastError;
          isValid = false;
        }

        const checkAttachmentType = attachments.some((a) => a.attachType == 0 && a.disabled === false);

        if (checkAttachmentType) {
          newErrors.attachType = attachmentTypeToastError;
          isValid = false;
        }

        if (isValid === true) {
          // Append form data if Attachment type contains BC / SC Level
          if (containsLevelBC) {
            formData.append(FIELD_NAMES.baseCode, (formik.values[FIELD_NAMES.baseCode]).trim());
          }
          if (containsLevelSC) {
            formData.append(FIELD_NAMES.stockcode, (formik.values[FIELD_NAMES.stockcode]).trim());
          }
        }
      }

      // Add user assignment validation check
      if(selectedUserAssignments.length > 0) {
        const checkFirstRowUserAssignment = selectedUserAssignments.some((ua) => ua.userId && ua.assignmentId === "");
        
        const checkUserFromSecondRow = selectedUserAssignments.slice(1).some((ua) => ua.userId === "");
        const checkAssignmentFromSecondRow = selectedUserAssignments.slice(1).some((ua) => ua.assignmentId === "");

        const { userError, assignmentRoleError } = USER_ASSIGNMENTS_VALIDATION_MESSAGES;
        if(checkFirstRowUserAssignment) {
          newErrors.assignmentId = assignmentRoleError;
          isValid = false;
        }

        if(checkUserFromSecondRow) {
          newErrors.userId = userError;
          isValid = false;
        }

        if(checkAssignmentFromSecondRow) {
          newErrors.assignmentId = assignmentRoleError;
          isValid = false;
        }
      }
      
      // Check if there are any errors
      if (isValid === false) {
        // Combine all error messages
        const errorMessage = Object.values(newErrors).join(', ');
        // Display the combined error message in a toast notification
        toast.error(errorMessage);
        setDisableSubmit(false);
      }

      try {
        if (isValid === true) {
          const isBaseCodePresent = !!formik.values[FIELD_NAMES.baseCode]?.trim();
          const isProjectManagerAssigned = checkProjectManagerAssignmentWithProduct();
          // Check if baseCode contains value and project manager assignment doesn't exists
          if (isBaseCodePresent && !isProjectManagerAssigned) {
            fetchAutoAssignmentForProduct();
          }
          // Else submit form data to update product api
          else {
            submitFormDataToItemApi();
          }
        }
      } catch (error: any) {
        setDisableSubmit(false);
        console.error("Exception from create product", error);
      }
    }
  });

  /**
   * This function is used to submit product data to api
   */
  const submitFormDataToItemApi = async (newUserAssignments = null) => {
    const containsLevelBC = attachments.some((a) => a.lvl === attachmentLevels.BC);
    const containsLevelSC = attachments.some((a) => a.lvl === attachmentLevels.SC);

    const data = {
      ...formik.values,
    };

    // Add tags data to submit in api request
    data.tags = (selectedTagOptions || []).map(t => t.id);

    const userAssignments = newUserAssignments || selectedUserAssignments;
    
    // Add user assignments data in api request if first row is not empty
    const checkIsFirstRowEmpty = userAssignments.slice(0, 1).some((ua) => ua.userId === "" && ua.assignmentId === "");
    data.userAssignments = (checkIsFirstRowEmpty ? [] : userAssignments);

    // Trim stockcode if it contains value
    if (data.stockcode) {
      data.stockcode = data.stockcode.trim();
    }
    // Trim basecode if it contains value
    if (data.baseCode) {
      data.baseCode = data.baseCode.trim();
    }

    setDisableSubmit(true);

    try {
      const createItemResponse = await api.post<ApiResponse<CreateItemRequest>>(
        "/Item",
        data
      );

      // Append Product id in Form data to submit in api request
      const itemId = createItemResponse?.data;

      if (createItemResponse?.isSuccess) {
        toast.success(createItemResponse?.message);

        if (uploaderRef.current) {
          // Handle the uploading of attachments to API, including small and large files,
          // by separating them into appropriate categories and uploading them accordingly.
          await uploaderRef.current.uploadAttachmentsToAPI(itemId, formik, containsLevelBC, containsLevelSC);
        }
      }
      navigate("/products");
    } catch (err) {
      setDisableSubmit(false);
    }
  };

  // This is used to check if the project manager assignment is already assigned to the product
  const checkProjectManagerAssignmentWithProduct = () => {
    return assignmentRoleDropdownOptions.some(
      (assignment) =>
        selectedUserAssignments.some(
          (selected) => selected.assignmentId === assignment.id
        ) && assignment.name === PROJECT_MANAGER_ASSIGNMENT
    );
  };

  // This function is used to fetch auto assignment api
  const fetchAutoAssignmentForProduct = () => {
    const baseCodeValue = formik.values[FIELD_NAMES.baseCode];
    try {
      api
        .get<ApiResponse<any[]>>(`/Item/auto-assignments/0/${baseCodeValue}`)
        .then((response: ApiResponse<UserAssignment[]>) => {
          if (response.isSuccess) {
            if (response.data?.length > 0) {
              setAutoAssignments(response.data);
              setShowAutoAssignmentConfirmModal(true);
            }
            else {
              submitFormDataToItemApi();
            }
          } else {
            throw new Error(response?.message);
          }
        })
        .catch((error) => {
          console.error("Exception from Auto Assignments", error);
        });
    } catch (error: any) {
      console.error(error?.message);
    };
  };

  const handleAutoAssignmentModalClose = () => {
    setShowAutoAssignmentConfirmModal(false);
    setDisableSubmit(false);
  };

  // This function is used to auto assign the project manager assignment with conditions
  const processAutoAssignments = () => {
    // find the user assignments that are currently assigned as project manager
    const matchAutoAssignmentWithExistingProductAssignment = autoAssignments?.find(a =>
      selectedUserAssignments?.some((ua) => ua.userId === a.userId)
    );

    if (matchAutoAssignmentWithExistingProductAssignment) {
      // find the existing selected user assignments with auto assignment list
      const matchingUserAssignment = selectedUserAssignments?.find(ua =>
        ua.userId === matchAutoAssignmentWithExistingProductAssignment?.userId
      );
      // Get the assignment name that already exists as Project Manager assignment from the assignment dropdown options
      const assignmentName = assignmentRoleDropdownOptions?.find(option =>
        option.id === matchingUserAssignment?.assignmentId
      )?.name;
      // Get the user name that already exists as Project Manager assignment from the user dropdown options
      const username = userDropdownOptions?.find(u => u.id === matchAutoAssignmentWithExistingProductAssignment?.userId)?.name;
      // Display error toast message when existing selected assignment and auto assignment contains the same user with it's assignment
      const errorMessage = `User ${username} is already assigned as ${assignmentName}. Cannot add the same user as Project Manager to this product`
      toast.error(errorMessage);
    }

    // find out the user assignments which are not already assigned in current product and also return auto assignments with id = 0
    const autoAssignmentList = autoAssignments?.filter(a =>
      !selectedUserAssignments?.some((ua) => ua.userId === a.userId)
    ).map(a => ({
      ...a,
      id: 0
    }));

    if (autoAssignmentList?.length > 0) {
      // remove the default blank assignment
      const removeEmptyUserAssignment = selectedUserAssignments?.filter(
        assignment => assignment.userId !== ""
      );
      const combinedAssignmentList = [...removeEmptyUserAssignment, ...autoAssignmentList];

      setSelectedUserAssignments(combinedAssignmentList);
      submitFormDataToItemApi(combinedAssignmentList);
    }
    else {
      submitFormDataToItemApi();
    }
  };

  // This function is used to handle auto assignment confirmation
  const handleAutoAssignmentModalConfirm = (autoAssignConfirm: string) => {
    if (autoAssignConfirm === "Yes") {
      processAutoAssignments();
      setShowAutoAssignmentConfirmModal(false);
    }
    else {
      setShowAutoAssignmentConfirmModal(false);
      submitFormDataToItemApi();
    }
  };

  const getFieldLabel = (fieldName) => {
    if (productLayoutFields?.length > 0) {
      let fieldLabel;
      productLayoutFields.find((fl) => {
        if (fl.fieldName === fieldName) {
          fieldLabel = fl.label;
        }
      });
      return fieldLabel || fieldName;
    }
  };

  //TODO: can be use as component
  //This validate the required filed for basecode automation
  const validateBaseCodeAuto = (missingFields) => {
    const { marketingDesigner, seriesName, category } = formik.values;
    
    if (!marketingDesigner) {
      missingFields.push(getFieldLabel(FIELD_NAMES.marketingDesigner));
    };
    if (!seriesName) {
      missingFields.push(getFieldLabel(FIELD_NAMES.seriesName));
    };
    if (!category) {
      missingFields.push(getFieldLabel(FIELD_NAMES.category));
    };
  }

  //TODO: can be use as component
  //This call the basecode API and set the basecode field ONLY to the response  
  const handleBaseCodeAuto = async () => {
    const missingFields = [];
    validateBaseCodeAuto(missingFields);
    if (missingFields.length > 0) {
      toast.error(`${missingFields.join(', ')} ${missingFields.length > 1 ? 'are' : 'is'} required to generate Base Code`);
    } else {
      try {
        const baseCodeAutoResponse = await api.post<ApiResponse<any>>("/Item/base-code-automation", {
          marketingDesigner: formik.values.marketingDesigner,
          seriesName: formik.values.seriesName,
          category: formik.values.category,
        })
        if (baseCodeAutoResponse.isSuccess) {
          formik.setFieldValue(FIELD_NAMES.baseCode, baseCodeAutoResponse?.data?.baseCode);
          if (!(formik.values.pdCode)) {
            formik.setFieldValue(FIELD_NAMES.pdCode, baseCodeAutoResponse?.data?.baseCode);
          }
          formik.setFieldValue(FIELD_NAMES.bC_DesignerSeq, baseCodeAutoResponse?.data?.designerSeqNo);
          formik.setFieldValue(FIELD_NAMES.bC_CategorySeq, baseCodeAutoResponse?.data?.categorySeqNo);
          toast.success(baseCodeAutoResponse.message);
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  }

  /**
   * This function is used to handle tag option selection
   * @param tagToAdd - Selected Tag Option
   */
  const handleTagOptionSelect = (tagToAdd: Tag) => {
    // Add tag in selected tag option to display selected tags button
    setSelectedTagOptions([...selectedTagOptions, tagToAdd]);
    // Remove the selected tag from tag menus so that it cannot be selected again
    let updateTagOptions = tagMenuOptions.filter(t => t.id !== tagToAdd.id);
    setTagMenuOptions(updateTagOptions);
  };

  /**
   * This function is used to handle remove selected tag
   * @param tagId - Tag Id
   */
  const handleRemoveTag = (tagId: number) => {
    // Remove tag from selected tag state
    const tagsAfterRemoval = selectedTagOptions.filter((st) => st.id !== tagId);
    // Add tag in tag menu options which is currently removed from selected tag
    const removedTagInfo = selectedTagOptions.find(t => t.id === tagId);
    const sortedTagMenuOptions = [...tagMenuOptions, removedTagInfo].sort((a, b) => a.id > b.id ? 1 : -1);
    setSelectedTagOptions(tagsAfterRemoval);
    setTagMenuOptions(sortedTagMenuOptions); 
  };
  
  useEffect(() => {
    const errorFields = Object.keys(formik.errors);

    if (errorFields.length > 0) {
      formik.setTouched({ [errorFields[0]]: true }, false);

      const firstErrorField = document.querySelector(
        `.formik-error-${errorFields[0]}`
      );

      if (firstErrorField) {
        firstErrorField.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  }, [formik.errors]);

  /**
   * This function is used to returns list of DataTypes for a given field name
   * @param attributeName - Api response field name
   */
  const getAttributeValues = (attributeName: string) => {
    if (dropDownOptions && dropDownOptions.dataFieldsTypes) {
      return dropDownOptions.dataFieldsTypes
        ?.find(df => df.field.toLowerCase() === attributeName.toLowerCase())
        ?.types
        ?.map(t => {
          return {
            id: t.id,
            description: t.description,
          }
        });
    }
    else return [];
  };

  const formFieldsRenderer = {
    brandCollection: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.brandCollection)),
    marketingDesigner: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productDesignerMkts),
    seriesName: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.seriesNames),
    pdFamilyName: (field) => () => renderTextField(field.fieldName, field.label),
    shortDesc: (field) => () => renderTextField(field.fieldName, field.label),
    romanticSize: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.romanticSize)),
    category: (field) => () => renderDropDown(field.fieldName, field.label, dropDownOptions.productcategories),
    function: (field) => () => renderDropDown(field.fieldName, field.label, dropDownOptions.functions),
    baseCode: (field) => () => renderTextFieldBaseCodeAuto(field.fieldName, field.label),
    stockcode: (field) => () => renderTextField(field.fieldName, field.label),
    stage: (field) => () => renderDropDown(field.fieldName, field.label, dropDownOptions.stageStatusTags),
    status: (field) => () => renderDropDown(field.fieldName, field.label, statusDropDownOptions),
    style: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.styles),
    skuType: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.skuTypes),
    targetWholesalePrice: (field) => () => renderTextField(field.fieldName, field.label, true),
    targetIMAP: (field) => () => renderTextField(field.fieldName, field.label, true),
    drawingNum: (field) => () => renderTextField(field.fieldName, field.label),
    pdCode: (field) => () => renderTextField(field.fieldName, field.label),
  };
  
  // This variable to used to display Name of the User in Auto Assignment confirmation dialog
  const autoAssignmentUsername = userDropdownOptions?.find(u => 
    u.id === autoAssignments?.find(a =>
      !selectedUserAssignments?.some((ua) => ua.userId === a.userId)
    )?.userId
  )?.name;

  const formRendererNames = Object.keys(formFieldsRenderer);

  return (
    <>
      <ConfirmationModal
        isOpen={showResetConfirmationModal}
        onClose={handleCloseModal}
        title={"Confirm Reset Form"}
        message={"Are you sure you want to reset this form?"}
        handleConfirmYes={handleFormReset}
        handleConfirmNo={handleCloseModal}
      />
      <ConfirmationModal
        isOpen={showAutoAssignmentConfirmModal}
        onClose={handleAutoAssignmentModalClose}
        title={"Confirm Auto Assignment"}
        titleForConfirmYesButton={"Yes"}
        titleForConfirmNoButton={"No"}
        message={<>
          Do you want to Assign the <b>{autoAssignmentUsername}</b> as <b>Project Manager</b> Assignment to this Product for the modified BaseCode <b>{formik.values[FIELD_NAMES.baseCode]}</b>?
        </>}
        handleConfirmYes={() => handleAutoAssignmentModalConfirm("Yes")}
        handleConfirmNo={() => handleAutoAssignmentModalConfirm("No")}
      />
      <Card sx={{ marginBottom: 1 }}>
        <CardContent>
          <p>Create New Product</p>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <form onSubmit={formik.handleSubmit}>
              {sessionService.hasPermission(Permissions.ManageTags) && Object.keys(dropDownOptions).length > 0 && (
                <Tags
                  selectedTagOptions={selectedTagOptions}
                  tagDropDownOptions={tagMenuOptions}
                  handleRemoveTag={handleRemoveTag}
                  handleTagOptionSelect={handleTagOptionSelect}
                  uniqueTagsIdList = {uniqueTagsIdList}
                />
              )}

              {productLayoutFields?.length > 0 && productLayoutFields.map(field => {
                const renderFunction = formFieldsRenderer[formRendererNames.find((fieldName) => fieldName.toLowerCase() === field.fieldName.toLowerCase())]; // Use the camelCase version of the field name
                return renderFunction ? renderFunction(field)() : null;
              })}

              {sessionService.hasPermission(Permissions.ManageProductAssignments) &&
                <UserAssignments
                  selectedUserAssignments={selectedUserAssignments}
                  setSelectedUserAssignments={setSelectedUserAssignments}
                  viewMode="CreateProduct"
                  assignmentRoleDropdownOptions={assignmentRoleDropdownOptions}
                  userDropdownOptions={userDropdownOptions}
                />}

              {(sessionService.hasPermission(Permissions.ManageAttachments)) &&
                <AttachmentsUploader
                  title="Upload pdf, doc, docx, jpeg, png, tiff, ai, zip"
                  onRemove={handleRemoveAttachment}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  attachmentTypeDropdownOptions={dropDownOptions.attachmentTypes}
                  setDisableSubmit={setDisableSubmit}
                  ref={uploaderRef}
                />}
              <Button
                type="submit"
                variant="contained"
                style={{ margin: "8px 5px", width: "135px" }}
                disabled={disableSubmit}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                style={{ margin: "8px 5px", width: "135px" }}
                onClick={() => setShowResetConfirmationModal(true)}
              >
                Reset
              </Button>
            </form>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CreateProduct;
