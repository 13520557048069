import { useEffect, useMemo, useState } from "react";
import { Category } from "../../../models/Category";
import { ApiResponse } from "../../../models/ApiResponse";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import api from "../../../services/ApiService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Box, Button, IconButton, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { SessionDetails } from "../../../models/SessionDetails";
import CategoryModal from "./CategoryModal";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { MRT_ColumnDef, MRT_Row, MRT_TableContainer, useMaterialReactTable } from "material-react-table";
import { Delete, Edit } from "@mui/icons-material";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";

export default function CategorySetting() {
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const categoryGridCollumns = useMemo<MRT_ColumnDef<Category>[]>(
    () => [
      { accessorKey: "id", header: "Id", width: 100 },
      { accessorKey: "description", header: "Category Name", width: 300 },
      { accessorKey: "itemGrpCode", header: "SAP Code", width: 100},
      { accessorKey: "pimKey", header: "PIM Key", width: 100 },
      { accessorKey: "jdeCode", header: "JDE Code", width: 100},
      { accessorKey: "sort", header: "Sort", width: 100 },
      { accessorKey: "isActive", header: "Is Active", width: 100,
        Cell: ({row}) => 
        <Switch
          checked={row.original.isActive}
          onChange={async (e) => {
            await updateCategoryActive(row.original, e.target.checked);
          }}
          color="primary"
          inputProps={{ "aria-label": "controlled" }}
          disabled = {!manageAppSetting}
        />
      },
      // { accessorKey: "updatedDate", header: "Updated Date", width: 100,
      //   Cell: ({row}) => moment(row.original.updatedDate).format(DATE_TIME_FORMAT)
      // }
    ],
    []
  );

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const table = useMaterialReactTable({
    columns: categoryGridCollumns,
    data: categoryList,
    enablePagination: false,
    enableRowActions: manageAppSetting,
    positionActionsColumn: "last",
    enableRowOrdering: manageAppSetting,
    enableSorting: false,
    enableFilters: false,
    enableCellActions: true,
    enableColumnActions: false,
    renderRowActions:({row}) => (
      <Box style={{ width: "200px" }}>
        <IconButton onClick={() => handleEditClickOpen(row.original)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => handleDeleteModalOpen(row.original)}>
          <Delete />
        </IconButton>
      </Box>
    ),
    muiRowDragHandleProps: ({table}) => ({
      onDragEnd: async () => {
        const {draggingRow, hoveredRow} = table.getState();
        if(draggingRow && hoveredRow) {
          categoryList.splice(
            (hoveredRow as MRT_Row<Category>).index,0,
            categoryList.splice(draggingRow.index, 1)[0]
          );
          setCategoryList([...categoryList]);
          await updateSort(categoryList);
        }
      },
    }),
  });

  const updateSort = async (reOrderedList) => {
    let sort = 1;
    reOrderedList.forEach((category) => {
      category.sort = sort;
      sort++;
    });

    const response = await api.put<ApiResponse<boolean>>("/Picklist/category/update-sorts", {
      categories: reOrderedList
    });
    if(response.isSuccess) {
      await fetchData();
      toast.success("Sort updated successfully");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getCategoryResponse = await api.get<ApiResponse<Category[]>>(
      "/Picklist/categories"
    );
    setCategoryList(getCategoryResponse?.data.sort((a,b) => a.sort - b.sort) || []);
  };

  const handleEditClickOpen = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams });
  };

  const handleDeleteModalOpen = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  const updateCategoryActive = async (row: Category, isActive: boolean) => {
    row.isActive = isActive;
    try {
      api
        .put<ApiResponse<number>>("/Picklist/category", row)
        .then(async (response: any) => {
          if (response.isSuccess) {
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const deleteSubmit = () => {
    try {
      api
        .delete<ApiResponse<SessionDetails>>(
          `/Picklist/category/${formik.values.id}`
        )
        .then(async (response: any) => {
          if (response.isSuccess) {
            setDeleteModalOpen(false);
            formik.resetForm();
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      itemGrpCode: null,
      pimKey: null,
      jdeCode: null,
      sort: 0,
      description: "",
      isActive: true,
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .trim()
        .required("Category Name is required")
        .min(1, "Category Name must be at least 1 characters")
        .max(40, "Category Name must be at most 40 characters")
        .test(
          "check-category-duplicate",
          "Category Name already exists",
          (value) => {
            return !categoryList.some(
              (item) =>
                item.description.trim().toLowerCase() ===
                  value.trim().toLowerCase() && item.id !== formik.values.id
            );
          }
        ),
      sort: Yup.number()
        .required("Sort is required")
        .min(1, "Sort must be greater than 0")
        .test(
          "check-sort-duplicate",
          "Sort already exists",
          function async(value) {
            return new Promise((resolve) => {
              const sortDuplicate = categoryList.some(
                (item) => item.sort === value && item.id !== formik.values.id
              );
              sortDuplicate ? resolve(false) : resolve(true);
            });
          }
        ),
      itemGrpCode: Yup.string()
      .nullable()
      .max(3, "SAP Code must be at most 3 characters"),
      pimKey: Yup.string()
      .nullable(),
      jdeCode: Yup.string()
        .nullable()
        .max(20, "JDE Code must be at most 20 characters")
        .test("check-jdeCode-duplicate", "JDE Code already exists", 
            (value) => {
                if(!value) {
                    return true;
                }

                return !!value && !categoryList.some(
                    (item) => item?.jdeCode && value && item.jdeCode?.trim().toLowerCase() === 
                    value.trim().toLowerCase() && item.id !== formik.values.id
                )
            })
    }),
    onSubmit: async (value) => {
      setDisableSubmit(true); // Set disableSubmit to true when the form is submitted
      if (value.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/category", {
            id: value.id,
            itemGrpCode: value.itemGrpCode || null,
            pimKey: value.pimKey || null,
            jdeCode: value?.jdeCode || null,
            sort: value.sort,
            description: value.description.trim(),
            isActive: JSON.parse(`${value.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/category", {
            description: value.description,
            itemGrpCode: value.itemGrpCode || null,
            pimKey: value.pimKey || null,
            jdeCode: value?.jdeCode || null,
            sort: value.sort,
            isActive: JSON.parse(`${value.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  return (
    <>
      <div>
        {manageAppSetting && <Button
          variant="contained"
          onClick={() => {
            setShowAddUpdateModal(true);
            setDisableSubmit(false);
          }}
        >
          Add Category
        </Button>}
        <CategoryModal
          isOpen={showAddUpdateModal}
          onClose={handleCloseModal}
          formik={formik}
          isButtonDisabled={disableSubmit}
        />
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleCloseModal}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={"Category Name"}
        />
      </div>

      <MRT_TableContainer table={table} style={{ marginTop: "10px" }} />
    </>
  );
}
