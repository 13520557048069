import { 
    Button,
    Dialog, 
    DialogActions, 
    DialogContent,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import { useEffect } from "react";

interface DataTypeSettingProps {
    isOpen: boolean;
    onClose: VoidFunction;
    formik: any;  
};

const DataTypeSetting = (props: DataTypeSettingProps & { isButtonDisabled: boolean }) => {
    const {isOpen, onClose, formik, isButtonDisabled} = props;

    useEffect(() => {
        // Sync pimKey with description
        if(formik.values.id <= 0) {
            formik.setFieldValue('pimKey', String(formik.values.description));
        }
    }, [formik.values.description]);

    return (
        <Dialog open = {isOpen} onClose = {onClose} fullWidth>
            <DialogActions>
                <Button onClick = {onClose}>x</Button>
            </DialogActions>
            <form onSubmit={formik.handleSubmit}>
            <DialogContent>
                <TextField
                    autoFocus
                    id = "description"
                    name = "description"
                    margin ="dense"
                    label = {
                        formik.touched.description && formik.errors.description ? 
                        formik.errors.description : 
                        "Enter Data Type"
                    }
                    type = "text"
                    fullWidth
                    value = {formik.values.description}
                    onChange = {formik.handleChange}
                    error = {formik.touched.description && formik.errors.description}
                    onBlur = {formik.handleBlur}
                />
                <Grid container spacing={2}> 
                    <Grid item xs={6}>
                        <TextField
                            id = "pimKey"
                            name = "pimKey"
                            margin ="dense"
                            label = {
                                formik.touched.pimKey && formik.errors.pimKey ? 
                                formik.errors.pimKey : 
                                "Enter PIM Key"
                            }
                            type = "text"
                            fullWidth
                            value = {formik.values.pimKey}
                            onChange = {formik.handleChange}
                            error = {formik.touched.pimKey && formik.errors.pimKey}
                            onBlur = {formik.handleBlur}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin = "dense"
                            id = "jdeCode"
                            name = "jdeCode"
                            label = {
                                formik.errors.jdeCode && formik.touched.jdeCode ?
                                formik.errors.jdeCode : 
                                "Enter JDE Code"
                            }
                            type = "text"
                            fullWidth
                            value = {formik.values.jdeCode ?? null}
                            onChange = {formik.handleChange}
                            error = {formik.touched.jdeCode && formik.errors.jdeCode}
                            onBlur = {formik.handleBlur}
                        />
                    </Grid>
                </Grid>
                <h4>Set Active</h4>
                <RadioGroup
                    row
                    name = "isActive"
                    value = {formik.values.isActive}
                    onChange = {(event) => formik.handleChange(event)}
                >
                    <FormControlLabel value = {true} control = {<Radio/>} label = "Active"/>
                    <FormControlLabel value = {false} control = {<Radio/>} label = "Inactive"/>
                </RadioGroup>
                <DialogActions>
                    <Button variant = "contained" type = "submit" disabled={isButtonDisabled}>
                        {formik.values.id > 0 ? 
                            "Update Data Type": "Add Data Type"}
                    </Button>
                </DialogActions>
            </DialogContent>
            </form>
        </Dialog>
    )
}; 

export default DataTypeSetting;