/* eslint-disable no-template-curly-in-string */
import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  Divider,
  FormControl,
  Typography,
  Autocomplete,
  TextField,
  Tooltip,
  IconButton,
  Checkbox,
} from "@mui/material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { CSSDefaults } from "../models/GlobalConstants";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { AttachmentType } from "../models/AttachmentType";
import imageNotAvailable from "../no-image.png";
import { FIELD_NAMES, FILE_UPLOAD_CHUNK_SIZE, MAX_SIZE_FOR_REGULAR_UPLOAD, THUMBNAIL_IMAGE_API_URL, TOOLING_ATTACHMENT_LABEL, validExtensions } from "../pages/Products/EditItem/Constants";
import ImageSliderView, { ImageDetail } from "./ImageSliderView";
import SessionService from "../services/SessionService";
import { Permissions } from "../models/Enum";
import DownloadIcon from '@mui/icons-material/Download';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import GetIconByFileType from "./GetIconByFileType";
import GetFileType from "./GetFileType";
import PDFViewModal, { PDFDetail } from "../pages/Report/ViewAttachment/PDFViewModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import getFileType from "./GetFileType";
import { ApiResponse } from "../models/ApiResponse";
import api from "../services/ApiService";
import { DATE_TIME_FORMAT } from "../utils/constants";
import moment from "moment";

type AttachmentsUploaderProps = {
  title: string;
  attachments?: MultipleAttachment[];
  onRemove?: (index: number) => void;
  attachmentTypeDropdownOptions: AttachmentType[];
  setShowFab?: (value: boolean) => void;
  setDisableSubmit?: (value: boolean) => void;
  setAttachments?: (attachments: MultipleAttachment[]) => void;
};

export type MultipleAttachment = {
  attachId?: number;
  fileName: string;
  file?: File;
  attachType: number;
  attachmentTypeDisplayText?: string;
  lvl?: string;
  link?: string;
  disabled: boolean;
  progressBarId?: string;
  attachmentDate?: string;
}

export interface AttachmentsUploaderRef {
  uploadAttachmentsToAPI: (itemId, formik, containsLevelBC, containsLevelSC) => Promise<void>;
}

const AttachmentsUploader = forwardRef<AttachmentsUploaderRef, AttachmentsUploaderProps>(
  (props, ref) => {

    const {
      title,
      attachments,
      onRemove,
      attachmentTypeDropdownOptions,
      setShowFab,
      setDisableSubmit,
      setAttachments
    } = props;

    const [attachmentTypes, setAttachmentTypes] = useState<any>([]);
    const [totalFileSize, setTotalFileSize] = useState<string>("");
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [images, setImages] = useState<ImageDetail[]>([]);
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [selectedImageSlide, setSelectedImageSlide] = useState<number>(0);
    const [pdfDetailView, setPDFDetailView] = useState<PDFDetail>();
    const [isPDFViewOpen, setIsPDFViewOpen] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [smallAttachmentsUploadingProgress, setSmallAttachmentsUploadingProgress] = useState<number>(0);
    const [largeAttachmentsUploadingProgress, setLargeAttachmentsUploadingProgress] = useState<Record<number, number>>({});
    
    const [selectedAttachments, setSelectedAttachments] = useState<Record<number, boolean>>({});

    const sessionService = SessionService.getInstance();

    const closeSlider = () => {
      setIsSliderOpen(false);
      setIsPDFViewOpen(false);
      setShowFab(true);
    };

    useEffect(() => {
      if (attachmentTypeDropdownOptions?.length > 0) {

        const setAttachmentTypeOptions = attachmentTypeDropdownOptions.map((attachmentTypeItem) => {
          return {
            attachmentType: attachmentTypeItem.id,
            attachmentTypeDisplayText: attachmentTypeItem.attachType,
            lvl: attachmentTypeItem.lvl
          }
        })

        setAttachmentTypes(setAttachmentTypeOptions || []);
      }
    }, [attachmentTypeDropdownOptions]);

    // Prevent page refresh
    useEffect(() => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (isUploading) {
          event.preventDefault();
          // This ensures the browser's default confirmation dialog appears
          event.returnValue = "";
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [isUploading]);

    const handleCheckboxChange = (id: number) => {
      setSelectedAttachments((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };

    const handleImageSlider = (attachId: number) => {
      const filterImageAttachments = attachments.filter(a => a.disabled === true && GetFileType(a.fileName).isImage)

      const images = filterImageAttachments.map((item) => {
        return {
          fileName: item.link,
          imageUrl: `${THUMBNAIL_IMAGE_API_URL}?fileName=${encodeURIComponent(item.link)}&height=600&width=600`,
        }
      });

      if (images && images.length > 0) {
        setImages(images);
        setIsSliderOpen(true);
        setShowFab(false);
        setSelectedImageSlide(filterImageAttachments.findIndex(num => num.attachId === attachId));
      }
    };

    const prepareFileUploadChange = (event, fileUploadType) => {
      let fileList;
      if (fileUploadType === "fileUploadButtonClick") {
        fileList = event.target.files || [];
      } else {
        fileList = event.dataTransfer.files;
      }

      const fileArray: File[] = Array.from(fileList);
      const validFilesArray = []; // Array to store valid files

      if (fileArray && fileArray.length > 0) {

        // Loop through all the files
        fileArray.forEach((file) => {
          const { name, size } = file;

          // Combine all valid file extensions into a single array
          const allValidExtensions = Object.values(validExtensions);

          // Check if the file has a valid extension and size
          if (allValidExtensions.includes(GetFileType(name).fileExtension)) {
              // Add valid files to the array
              validFilesArray.push({
                attachmentType: 0,
                file: file,
              });
          } else {
            toast.error(`Invalid file extension for "${name}"`);
          }
        });

        // Pass the array of valid files to onChange
        if (validFilesArray.length > 0) {
          handleAttachChange(validFilesArray);
        }

        // Reset the input after all files are processed
        if (fileUploadType === "fileUploadButtonClick") {
          event.target.value = ""; // reset the file input
          fileInputRef.current.files = null;
        }
      }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      prepareFileUploadChange(event, "fileUploadButtonClick");
    };

    const handleAttachmentTypeChange = (selectedItem, index) => {
      const newStateValue = {
        attachmentType: selectedItem.attachmentType,
        attachmentTypeDisplayText: selectedItem.attachmentTypeDisplayText,
        level: selectedItem.lvl,
      };
      handleAttachChange(newStateValue, index);
    };

    useEffect(() => {
      calculateFileSize(attachments);
    }, [attachments]);

    const calculateFileSize = (attachmentsData: MultipleAttachment[]) => {
      const filterAttachments = attachmentsData.filter(a => a.disabled === false);

      if (filterAttachments && filterAttachments.length > 0) {
        const totalSize = filterAttachments.reduce(
          (total, file) => total + file.file.size,
          0
        );

        const formattedSize = formatBytes(totalSize); // Format bytes for better readability
        setTotalFileSize(formattedSize);
      }
      else {
        setTotalFileSize("");
      }
    };

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const handleDrop = (event) => {
      event.preventDefault();
      if(!isUploading) prepareFileUploadChange(event, "fileDropUpload");
    };

    /**
  * This function is used to handle Attachment change and update state
  * @param newAttachments - Array / Object that contains the attachment value changes
  * @param index - Array index of attachment list
  */
  const handleAttachChange = (newAttachments: any | any[], index: number = 0) => {  
    // Create a shallow copy of the existing attachments
    let attachmentItems = [...attachments];
  
    // Check if newAttachments is an array (for multiple files)
    if (Array.isArray(newAttachments)) {

      newAttachments.forEach((newAttachment) => {
        const attachmentFile = newAttachment.file;
        const link = newAttachment.link;
        const attachmentTypeValue = newAttachment.attachmentType;
  
        if (attachmentFile || attachmentTypeValue) {

          if (attachmentFile) {
            const { name } = attachmentFile;
            // Add each new attachment file to the attachmentItems array
            attachmentItems.push({
              fileName: name,
              link: link,
              file: attachmentFile, // This property is used to add new attachment file
              attachType: attachmentTypeValue,
              disabled: false,
            });
          }
        }
      });
    } else {
      const attachmentTypeValue = newAttachments.attachmentType;
      const attachmentTypeDisplayValue = newAttachments.attachmentTypeDisplayText;
      const attachmentLevelValue = newAttachments.level;
      const generateUUID = window.crypto.randomUUID();

      // Update the attachment at the given index with attach type information
      if (attachmentTypeValue) {
        attachmentItems[index].attachType = attachmentTypeValue;
        attachmentItems[index].attachmentTypeDisplayText = attachmentTypeDisplayValue;
        attachmentItems[index].lvl = attachmentLevelValue;
        // Adding attachment id to uniquely identify the file upload progress indicator
        attachmentItems[index].progressBarId = generateUUID;
      }
    }
  
    setAttachments(attachmentItems); // Update the state with the new attachment list
  };

    const getFileNameTextContent = (attachmentItem: MultipleAttachment) => (
      <>
        <Tooltip title={attachmentItem.link || ""} arrow>
          <Typography
            variant="body2"
            color="text.secondary"
            className="attachment-file-name"
          >
            {`${attachmentItem.fileName}`}
          </Typography>
        </Tooltip>
        <Typography
          variant="caption"
          color="text.secondary"
          className="attachment-date"
        >
          {attachmentItem.attachmentDate ? moment.utc(attachmentItem.attachmentDate).local().format(DATE_TIME_FORMAT) : ""}
        </Typography>
      </>
    );

    const filterInputStyle = {
      flex: "0 0 calc(20% - 10px)",
      margin: "8px 5px",
      "& .MuiOutlinedInput-root": {
        padding: 0,
        input: {
          paddingBottom: 0,
        },
      },
    };

    const handlePDFViewer = (link) => {
      setPDFDetailView({
        fileName: link,
      });
      setIsPDFViewOpen(true);
      setShowFab(false);
    };

    const getProgressValue = (
      attachmentItem: MultipleAttachment,
    ) => {
      return attachmentItem.file.size <= MAX_SIZE_FOR_REGULAR_UPLOAD
        ? smallAttachmentsUploadingProgress
        : largeAttachmentsUploadingProgress[attachmentItem.progressBarId] || 0;
    };

    // Function to prepare FormData with common fields
    const prepareAttachmentSubmitFormData = (itemId, formik, containsLevelBC, containsLevelSC) => {
      const formData = new FormData();

      // Append the ItemId
      formData.append("ItemId", `${itemId}`);

      // Append baseCode if the condition is met
      if (containsLevelBC) {
        formData.append(
          FIELD_NAMES.baseCode,
          formik.values[FIELD_NAMES.baseCode].trim()
        );
      }

      // Append stockcode if the condition is met
      if (containsLevelSC) {
        formData.append(
          FIELD_NAMES.stockcode,
          formik.values[FIELD_NAMES.stockcode].trim()
        );
      }

      return formData;
    };


    // Recursive function to upload file in chunks using .then
    const uploadFileInChunks = (zipAttachment, uuid, baseFormData, chunkIndex = 0) => {

      const { file, attachType, progressBarId } = zipAttachment;

      if (!file) return Promise.resolve();

      const totalChunks = Math.ceil(file.size / FILE_UPLOAD_CHUNK_SIZE);

      // Prepare the chunk to upload
      const start = chunkIndex * FILE_UPLOAD_CHUNK_SIZE;
      const chunk = file.slice(start, start + FILE_UPLOAD_CHUNK_SIZE);
      const chunkNumber = chunkIndex + 1;

      // Clone the base FormData manually
      const formData = cloneFormData(baseFormData);
      formData.append("uniqId", uuid);
      formData.append("attachType", attachType);
      formData.append("file", chunk);
      formData.append("chunkNumber", String(chunkNumber));
      formData.append("totalChunks", String(totalChunks));
      formData.append("fileName", file?.name);

      setDisableSubmit(true);

      // Upload the current chunk and recursively call for the next chunk
      return api
        .post("/attachment/upload-chunks", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: () => {
            // dispatch(disableLoader());
            const progress = Math.round((chunkNumber / totalChunks) * 100);

            // Update the progress for this specific file
            setLargeAttachmentsUploadingProgress((prevProgress: any) => ({
              ...prevProgress,
              [progressBarId]: progress,
            }));
          },
        })
        .then((res: any) => {
          // Check if all chunks are uploaded
          if (chunkNumber === totalChunks) {
            toast.success(res?.message);
            setDisableSubmit(false);
            return Promise.resolve();
          }
          else {
            setDisableSubmit(false);
            // Call the next chunk recursively
            return uploadFileInChunks(zipAttachment, uuid, baseFormData, chunkIndex + 1);
          }
        })
        .catch((error) => {
          console.error(`Error uploading chunk ${chunkNumber}:`, error);
          setDisableSubmit(false);
          // Handle error if needed, or stop further uploads
          return Promise.reject(error);
        });
    };

    // Function to clone FormData manually by copying key-value pairs
    const cloneFormData = (sourceFormData) => {
      const newFormData = new FormData();
      for (const [key, value] of sourceFormData.entries()) {
        newFormData.append(key, value);
      }
      return newFormData;
    };

    // Props function to handle the attachments data processing and submit to API
    const uploadAttachmentsToAPI = async (itemId, formik, containsLevelBC, containsLevelSC) => {

      // Filter and return attachments data for submitting to api request
      const newAttachmentsData = attachments
        .filter(a => a.disabled === false)
        .map((attachmentItem) => {
          return {
            attachType: attachmentItem.attachType,
            file: attachmentItem.file,
            progressBarId: attachmentItem.progressBarId
          }
        });

      if (newAttachmentsData.length > 0) {

        // Separate attachments based on file size
        const smallSizeAttachments = newAttachmentsData.filter(
          (a) => a.file.size <= MAX_SIZE_FOR_REGULAR_UPLOAD
        );

        const largeSizeAttachments = newAttachmentsData.filter(
          (a) => a.file.size > MAX_SIZE_FOR_REGULAR_UPLOAD
        );

        // Submit attachments
        if (smallSizeAttachments.length > 0) {
          const smallAttachmentsFormData = prepareAttachmentSubmitFormData(itemId, formik, containsLevelBC, containsLevelSC);

          // Append Attachment in Form-Data to submit api data 
          smallSizeAttachments.forEach((item, index) => {
            smallAttachmentsFormData.append(`Files[${index}].file`, item.file);
            smallAttachmentsFormData.append(`Files[${index}].attachType`, `${item.attachType}`)
          });

          setIsUploading(true);
          setDisableSubmit(true);
          // Submit attachments data in Add attachment api
          const addAttachmentResponse = await api.post<ApiResponse<any>>(
            "/attachment/add",
            smallAttachmentsFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                // Calculate progress bar
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setSmallAttachmentsUploadingProgress(progress); // Update progress state
              },
            }
          );

          if (addAttachmentResponse?.isSuccess) {
            toast.success(addAttachmentResponse?.message);
          }
          setIsUploading(false);
          setDisableSubmit(false);
        }

        // Submit large size attachments separately in chunks
        if (largeSizeAttachments.length > 0) {

          const zipAttachmentsFormData = prepareAttachmentSubmitFormData(itemId, formik, containsLevelBC, containsLevelSC);

          // Collect all promises for chunk uploads
          setIsUploading(true);

          const chunkUploadPromises = largeSizeAttachments.map((zipAttachment) => {
            const generateUUID = window.crypto.randomUUID();
            return uploadFileInChunks(zipAttachment, generateUUID, zipAttachmentsFormData);
          });

          try {
            // Wait for all chunk uploads to complete
            await Promise.all(chunkUploadPromises);
            setIsUploading(false);
          } catch (error) {
            console.error("Failed to upload all large size attachments:", error);
          }
        }
      }
    };
    
    // This function is used to prepare the selected file(s) link for download and pass it to download file function
    const handleDownloadSelectedAttachments = () => {
      // Filter selected attachments and get the necessary link for each
      const selectedAttachmentsLinks = Object.keys(selectedAttachments)
        .filter((attachmentId) => selectedAttachments[attachmentId]) // Only selected items
        .map((attachmentId) => {
          const attachment = attachments.find(a => a.attachId === Number(attachmentId)); // Find the attachment by ID
          return attachment ? attachment.link : null; // Return fileName or null if not found
        })
        .filter(Boolean); // Remove any null values

      // Now, download each file by calling `downloadFile`
      selectedAttachmentsLinks.forEach((link: string) => {
        downloadFile(link);
      });

      setSelectedAttachments({});
    };

    /**
     * This function is used to create blob url link to download attachment file
     * @param fileUrl - File name of attachment with relative path
     */
    const downloadFile = (fileUrl: string) => {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none'; // Hide the iframe
      iframe.src = `${process.env.REACT_APP_API_URL}/Attachment/RetrieveFile?fileName=${encodeURIComponent(fileUrl)}`;
      document.body.appendChild(iframe);

      iframe.onload = () => {
        // Add a 60-second timeout before removing the iframe
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 60000); // Wait for 60 seconds
      };
    };
    
    // Expose uploadAttachmentsToAPI to the parent using ref
    useImperativeHandle(ref, () => ({
      uploadAttachmentsToAPI,
    }));


    return (
      <>
        {isPDFViewOpen && <PDFViewModal
          pdf={pdfDetailView}
          isOpen={isPDFViewOpen}
          onClose={closeSlider}
        />
        }

        {images.length > 0 &&
          <ImageSliderView images={images} selectedImageSlide={selectedImageSlide} isOpen={isSliderOpen} onClose={closeSlider} />}
        <Box
          flexGrow="1"
          display="flex"
          sx={{
            border: "1px solid",
            borderRadius: "4px",
            boxShadow: "1px 1px 5px 1px #888888",
            padding: "15px",
            margin: "20px 0",
            ul: {
              listStyle: "none",
              display: "flex",
              flexDirection: "row",
              li: {
                padding: 1,
                border: `solid 1px ${CSSDefaults.primaryColor}`,
                margin: 1,
                borderRadius: "2px",
              },
            },
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div style={{ width: "100%" }}>
            {sessionService.hasPermission(Permissions.ManageAttachments)
              && <div
                style={{
                  margin: "0 0 10px",
                  padding: "20px",
                  border: "2px dashed #ccc",
                  textAlign: "center",
                  cursor: !isUploading ? "pointer" : "default"
                }}
              >
                {!isUploading ? (
                  <>
                    <input
                      ref={fileInputRef}
                      id="contained-button-file"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple // Enables multiple file upload
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<UploadFileOutlinedIcon />}
                      >
                        {title}
                      </Button>
                    </label>
                    <p>or</p>
                    <p>Drag and drop your files here</p>
                  </>) : (
                  <Typography variant="h6">
                    Uploading in progress, please wait...
                  </Typography>
                )}
              </div>}
            {attachments && attachments.length > 0 && (
              <>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "36px", marginBottom: "6px" }}>
                    <Typography variant="h6">
                      {totalFileSize ?
                        `New Attached Files (Total Size : ${totalFileSize})` :
                        `Attachments`}
                    </Typography>
                  {Object.values(selectedAttachments).some(Boolean) && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<DownloadIcon />}
                      onClick={handleDownloadSelectedAttachments}
                    >
                      Download
                    </Button>
                  )}
                </div>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    float: "none",
                    padding: "10px 0 0",
                    verticalAlign: "top",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {attachments.length > 0 &&
                    attachments
                      .map((attachmentItem, index) => (
                        <div
                          key={index + 1}
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            padding: "7px 0 0",
                          }}
                        >
                          <div style={{ margin: "8px 15px 8px 0" }}>
                            <Card sx={{ width: 240, overflow: "visible", position: "relative" }}>
                              <CardContent
                                className={`attachment-card-content ${
                                  selectedAttachments[attachmentItem.attachId] ? "checked" : ""
                                }`}
                              >
                                {(attachmentItem.disabled) &&
                                  <Checkbox
                                    checked={!!selectedAttachments[attachmentItem.attachId]}
                                    onChange={() => handleCheckboxChange(attachmentItem.attachId)}
                                    style={{
                                      position: "absolute",
                                      top: "6px",
                                      left: "2px",
                                      zIndex: 1,
                                      padding: 4,
                                      transition: "opacity 0.3s",
                                    }}
                                    className="hover-checkbox"
                                  />}
                                {sessionService.hasPermission(Permissions.ManageAttachments) &&
                                  (attachmentItem.attachmentTypeDisplayText !== TOOLING_ATTACHMENT_LABEL)
                                  && <CloseIcon
                                    onClick={() => !isUploading && onRemove(index)}
                                    style={{
                                      position: "absolute",
                                      top: "-15px",
                                      right: "-10px",
                                      cursor: isUploading ? "not-allowed" : "pointer",
                                      zIndex: 1,
                                      padding: 4,
                                      backgroundColor: CSSDefaults.headerFontColor,
                                      borderRadius: "50%",
                                      color: CSSDefaults.headerBgColor,
                                      fontSize: "1.2rem",
                                    }}
                                  />}
                                {((attachmentItem.disabled) &&
                                  (GetFileType(attachmentItem.fileName).isImage)) ? (
                                  <Box className="image-thumbnail-container">
                                    <CardMedia
                                      component="img"
                                      src={`${THUMBNAIL_IMAGE_API_URL}?fileName=${encodeURIComponent(attachmentItem.link)}`}
                                      alt={`Attachment ${attachmentItem.fileName}`}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imageNotAvailable;
                                      }}
                                      style={{ position: 'relative', objectFit: "cover", width: "auto", maxWidth: "100%", maxHeight: "68px", margin: "0 auto" }}
                                    />
                                    <div style={{ position: 'absolute', display: 'flex', gap: '5px' }}>
                                      <IconButton
                                        className="attachment-hover-icon-button"
                                        size="small"
                                        onClick={() => downloadFile(attachmentItem.link)}
                                      >
                                        <DownloadIcon fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        className="attachment-hover-icon-button"
                                        size="small"
                                        onClick={() => handleImageSlider(attachmentItem.attachId)}
                                      >
                                        <SlideshowIcon fontSize="small" />
                                      </IconButton>
                                    </div>
                                  </Box>
                                ) : (!attachmentItem.disabled &&
                                  GetFileType(attachmentItem.fileName).isImage &&
                                  // This below condition is used to not display an actual image of new uploaded attachment
                                  (GetFileType(attachmentItem.fileName).fileExtension !== validExtensions.tiff) &&
                                  (GetFileType(attachmentItem.fileName).fileExtension !== validExtensions.tif) &&
                                  <>
                                    <Box className="image-thumbnail-container">
                                      <CardMedia
                                        component="img"
                                        image={URL.createObjectURL(attachmentItem.file)}
                                        alt={`Attachment ${attachmentItem.fileName}`}
                                        style={{
                                          position: 'relative', objectFit: "cover", width: "auto", maxWidth: "100%", maxHeight: "68px", margin: "0 auto",
                                          opacity: (isUploading && attachmentItem.progressBarId) ? "0.1" : "unset"
                                        }}
                                      />
                                      {isUploading && (
                                        <div style={{ position: 'absolute', display: 'flex', marginTop: '4px' }}>
                                          <CircularProgressWithLabel progressValue={getProgressValue(attachmentItem)} />
                                        </div>
                                      )}
                                    </Box>
                                  </>
                                )}
                                {((!GetFileType(attachmentItem.fileName).isImage) ||
                                  ((!attachmentItem.disabled) && GetFileType(attachmentItem.fileName).fileExtension == validExtensions.tif) ||
                                  ((!attachmentItem.disabled) && GetFileType(attachmentItem.fileName).fileExtension == validExtensions.tiff)) &&
                                  <div
                                    style={{
                                      backgroundColor: "rgba(0,0,0,.05)",
                                      textAlign: "center",
                                      cursor: attachmentItem.disabled ? "pointer" : "default",
                                    }}
                                    className="image-thumbnail-container"
                                  >
                                    {(attachmentItem.disabled || !isUploading) && GetIconByFileType(attachmentItem.fileName)}
                                    {!attachmentItem.disabled && isUploading && (
                                      <div style={{ position: 'absolute', display: 'flex', marginTop: '4px' }}>
                                        <CircularProgressWithLabel progressValue={getProgressValue(attachmentItem)} />
                                      </div>
                                    )}
                                    {attachmentItem.disabled &&
                                      <div style={{ position: 'absolute', display: 'flex', gap: '5px' }}>
                                        <IconButton
                                          className="attachment-hover-icon-button"
                                          size="small"
                                          onClick={() => downloadFile(attachmentItem.link)}
                                        >
                                          <DownloadIcon fontSize="small" />
                                        </IconButton>
                                        {GetFileType(attachmentItem.fileName).isPdf && (<IconButton
                                          className="attachment-hover-icon-button"
                                          size="small"
                                          onClick={() => handlePDFViewer(attachmentItem.link)}
                                        >
                                          <VisibilityIcon fontSize="small" />
                                        </IconButton>)}
                                      </div>}
                                  </div>}
                                {getFileNameTextContent(attachmentItem)}
                              </CardContent>
                            </Card>
                            {(attachmentItem.attachmentTypeDisplayText === TOOLING_ATTACHMENT_LABEL) && (
                              <Box width={"240px"}>
                                <Typography
                                  sx={{
                                    backgroundColor: "#EBEBE4",
                                    border: "1px solid",
                                    borderColor: "#D1D1D6",
                                    padding: "8px 10px",
                                    borderRadius: "4px",
                                    fontSize: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    color: "#8b8888",
                                    marginTop: "22px",
                                  }}
                                >
                                  Tooling Attachment
                                </Typography>
                              </Box>
                            )}
                            {(attachmentItem.attachmentTypeDisplayText !== TOOLING_ATTACHMENT_LABEL) && (
                              <FormControl style={{ margin: "15px 0", width: "240px" }}>
                                <Autocomplete
                                  sx={filterInputStyle}
                                  value={
                                    attachmentItem.attachmentTypeDisplayText
                                      ?
                                      {
                                        attachmentTypeDisplayText: attachmentItem.attachmentTypeDisplayText,
                                        lvl: attachmentItem.lvl
                                      }
                                      : null
                                  }
                                  onChange={(event, selectedItem) => handleAttachmentTypeChange(selectedItem, index)}
                                  options={attachmentTypes || []}
                                  getOptionLabel={(option) =>
                                    (`${option.lvl} - ${option.attachmentTypeDisplayText}`)
                                  }
                                  disableClearable
                                  disabled={!!attachmentItem.disabled || isUploading}
                                  style={{ backgroundColor: (attachmentItem.disabled || isUploading) ? "#EBEBE4" : "#FFFFFF" }}
                                  renderInput={(params) => (
                                    <Tooltip title={attachmentItem.disabled ? attachmentItem.attachmentTypeDisplayText : ""} arrow>
                                      <TextField
                                        key={params.id}
                                        error={
                                          Boolean(attachmentItem.attachType == 0)
                                        }
                                        label={"Attachment Type"}
                                        {...params}
                                        variant="outlined"
                                      />
                                    </Tooltip>
                                  )}
                                />
                              </FormControl>
                            )}
                          </div>
                        </div>
                      ))
                  }
                </div>
              </>
            )}
          </div>
        </Box>
      </>
    );
  });

export default AttachmentsUploader;
