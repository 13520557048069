import { Button, Switch } from "@mui/material";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import { useEffect, useState } from "react";
import { ItemFunction } from "../../../models/ItemFunction";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import ItemFunctionModal from "./ItemFunctionModal";
import { useFormik } from "formik";
import { initialValues } from "../../Products/EditItem/Constants";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { Category } from "../../../models/Category";
import { toast } from "react-toastify";
import { SessionDetails } from "../../../models/SessionDetails";
import * as Yup from "yup";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";

export default function ItemfunctionSetting() {
    const [itemFunctionList, setItemFunctionList] = useState<ItemFunction[]> ([]);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [categoryList, setCategoryList] = useState<Category[]>([]);
    const [disableSubmit, setDisableSubmit] = useState(false);

    //for sorting category
    const getCategoryDescription = (categoryId) => {
        const category = categoryList.find(i => i.id === categoryId);
        return category ? category.description : "";
    };

    const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

    const ItemFunctionGridCollumns: GridColDef[] = [
        { field: "id", headerName: "Id", width: 70},
        { field: "sapCode", headerName: "SAP Code", width: 100},
        { field: "jdeCode", headerName: "JDE Code", width: 100},
        { field: "description", headerName: "Description", width: 230},
        { field: "category", headerName: "Category", width: 180,
            renderCell: (params: any) => {
                const category = categoryList.find(i => i.id === params.value);
                return (
                    <>
                        {category.isActive ? category.description : `${category.description} (Inactive)`}
                    </>
                );
            },
            sortComparator: (v1, v2) => {
                const desc1 = getCategoryDescription(v1);
                const desc2 = getCategoryDescription(v2);
                return desc1.localeCompare(desc2);
            }
        },
        { field: "isActive", 
          headerName: "Is Active",
          width: 200,
          sortable: false,
          renderCell: (params: any) => (
            <>
                <Switch 
                    checked = {params.value}
                    onChange={async (e) => {
                        await updateItemFunctionActive(
                          params.row,
                          e.target.checked
                        );
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "controlled" }}
                      disabled = {!manageAppSetting}
                />
            </>
          )
        },
        // {
        //     field: "updatedDate",
        //     headerName: "Updated Date",
        //     width: 200,
        //     valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
        // },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 200,
            getActions: (params: any) => [
              GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
              GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
            ],
          },
    ];

    useEffect(() => {
        const fetchDataSequentially = async () => {
            await fetchCategoryData();  // Fetch category data first
            await fetchData();          // Then fetch item function data
        };
    
        fetchDataSequentially(); 
    }, []);

    const fetchData = async () => {
        const getItemFunctionListResponse = await api.get<ApiResponse<ItemFunction[]>>("/Picklist/item-functions");
        setItemFunctionList(getItemFunctionListResponse?.data || []);
    };

    const fetchCategoryData = async () => {
        const getCatehoryListResponse = await api.get<ApiResponse<Category[]>>("/Picklist/categories");
        setCategoryList(getCatehoryListResponse?.data || []);
    };

    const handleAddClickOpen = () => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
    };

    const handleCloseModal = () => {
        setShowAddUpdateModal(false);
        setDeleteModalOpen(false);
        formik.resetForm();
    };

    const handleEditClickOpen = (gridParams: any) => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
        formik.setValues({...gridParams.row})
    };

    const handleDeleteModalOpen = (gridParams: any) => {
        setDeleteModalOpen(true);
        formik.setValues({ ...gridParams.row });
    };

    const updateItemFunctionActive = async (
        row: ItemFunction,
        isAcitve: boolean
    ) => {
        row.isActive = isAcitve;
        try{
            api
                .put<ApiResponse<number>>(`/Picklist/item-function`, row)
                .then(async (response: any) => {
                    if(response.isSuccess){
                        toast.success(response.message);
                        await fetchData();
                    }else {
                        throw new Error(response.message);
                    }
                })
                .catch(()=>{});
        }catch( error: any) {
            toast.error(error.message);
        }
    };

    const deleteSubmit = async () => {
        try{
            api
                .delete<ApiResponse<SessionDetails>>(`/Picklist/item-function/${formik.values.id}`)
                .then(async (response: any) => {
                    if(response.isSuccess){
                        setDeleteModalOpen(false);
                        formik.resetForm();
                        toast.success(response.message);
                        await fetchData();
                    }else {
                        throw new Error(response.message);
                    }
                })
                .catch(()=>{});
        }catch(error: any){
            toast.error(error.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            id: 0,
            sapCode: 0,
            jdeCode: null,
            description: "",
            category: "",
            isActive: true
        },
        validationSchema: Yup.object({
            sapCode: Yup.number()
                .required("SAP Code is required")
                .min(1, "Sort must be greater than 0")
                .test("check-duplicate", "SAP Code already exists", (value) => {
                    return !itemFunctionList.some(
                      (item) =>
                        item.sapCode === value &&
                        item.id !== formik.values.id
                    );
                }),
            description: Yup.string()
                .trim()
                .required("Description is required")
                .min(1, "Description must be at least 1 characters")
                .max(40, "Description must be at most 40 characters")
                .test("check-duplicate", "Method already exists", (value) => {
                    return !itemFunctionList.some(
                      (item) =>
                        item.description.trim().toLowerCase() === value.trim().toLowerCase() &&
                        item.id !== formik.values.id
                    );
                }),
            category: Yup.string()
            .required("Category is required"),
            jdeCode: Yup.string()
                .nullable()
                .max(20, "JDE Code must be at most 20 characters")
                .test("check-jdeCode-duplicate", "JDE Code already exists", 
                    (value) => {
                        if(!value) {
                            return true;
                        }

                        return !!value && !itemFunctionList.some(
                            (item) => item?.jdeCode && value && item.jdeCode?.trim().toLowerCase() === 
                            value.trim().toLowerCase() && item.id !== formik.values.id
                        )
                    })
        }),
        onSubmit: async (formData) => {
            setDisableSubmit(true);
            if(formData.id > 0){
                api
                    .put<ApiResponse<number>>("/Picklist/item-function", {
                        id: formData.id,
                        description: formData.description.trim(),
                        sapCode: formData.sapCode,
                        jdeCode: formData?.jdeCode || null,
                        category: formData.category,
                        isActive: JSON.parse(`${formData.isActive}`),
                    })
                    .then(async (response: any) => {
                    if (response.isSuccess) {
                        setShowAddUpdateModal(false);
                        formik.resetForm();
                        toast.success(response.message);
                        await fetchData();
                    } else {
                        throw new Error(response.message);
                    }
                    })
                    .catch(() => {});
            }else {
                api
                    .post<ApiResponse<number>>("/Picklist/item-function", {
                        description: formData.description.trim(),
                        sapCode: formData.sapCode,
                        jdeCode: formData?.jdeCode || null,
                        category: formData.category,
                        isActive: JSON.parse(`${formData.isActive}`),
                    })
                    .then(async (response: any) => {
                        if (response.isSuccess) {
                            setShowAddUpdateModal(false);
                            formik.resetForm();
                            toast.success(response.message);
                            await fetchData();
                        } else {
                            throw new Error(response.message);
                        }
                    })
                    .catch(() => {});
            }
        }
    });

    return (
        <div>
            {manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
                Add Function
            </Button>}
            <DeleteValidationModal 
                addModalOpen={deleteModalOpen} 
                handleCloseModal={handleCloseModal} 
                deleteSubmit={deleteSubmit} 
                fieldIdentifier={"Function"}            
            />
            <ItemFunctionModal 
                isOpen={showAddUpdateModal} 
                onClose={handleCloseModal} 
                formik={formik}   
                categoryList = {categoryList}   
                isButtonDisabled={disableSubmit}
            />
            <SettingGrid 
                data={itemFunctionList}
                column={ItemFunctionGridCollumns}
                sortByField="category"
                sortByOrder="asc"
                columnVisibility={{
                    actions: manageAppSetting,
                  }}          
            />
        </div>
    )
};
