import { useEffect, useRef, useState } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { CSSDefaults } from "../models/GlobalConstants";
import { QuoteBasicDetail } from "../models/Quotes/QuoteBasicDetail";
import api from "../services/ApiService";
import { ApiResponse } from "../models/ApiResponse";
import { formatAmount } from "../utils/helpers";
import { Item } from "../models/Items/Item";

type ProductCommentsProps = {
  productDetail: Item;
  productId: number;
  setSelectedQuote: (value: string) => void;
  selectedQuote: string;
  setSelectedVendorForQuote: (value: number) => void;
  getProductIPCube: () => number;
  setIPCubeUpdatesModalOpen: (value: boolean) => void;
  setOldQuoteDetailsForIPCubeUpdates: (value: QuoteBasicDetail) => void;
  setNewQuoteDetailsForIPCubeUpdates: (value: QuoteBasicDetail) => void;
};

const AcceptedQuote = (props: ProductCommentsProps) => {

  const {
    productDetail, 
    productId,
    selectedQuote,
    setSelectedQuote,
    setSelectedVendorForQuote,
    getProductIPCube,
    setIPCubeUpdatesModalOpen,
    setOldQuoteDetailsForIPCubeUpdates,
    setNewQuoteDetailsForIPCubeUpdates
  } = props;

  const [quoteOptionsList, setQuoteOptionsList] = useState([]);

  const hasQuoteDefaultSelected = useRef(false);

  /* 
    This function is used to get quotes from api
   */
  const getQuoteList = () => {
    api
      .get<ApiResponse<QuoteBasicDetail[]>>(`/quote/approved-quotes/${productId}`)
      .then((response: ApiResponse<QuoteBasicDetail[]>) => {
        if (response?.isSuccess && response?.data?.length > 0) {

          const quotes = response.data.map(ql => {
            return {
              id: ql.id,
              name: [ql.quoteNumber, ql.vendorDisplayText, ql.fobCost !== undefined ? formatAmount(ql.fobCost, "USD") : null]
                .filter(value => value) // Remove undefined/null/empty values
                .join(" - "), // Join only available values with "-"
              vendorId: ql?.vendor || null,
              ...ql
            }
          });

          setQuoteOptionsList(quotes);
        }
      })
      .catch((error) => {
        console.error("Exception from Get Approved Quotes API", error);
      });
  };

  useEffect(() => {
    getQuoteList();
  }, []);

  useEffect(() => {
    if (quoteOptionsList?.length > 0 && selectedQuote && !hasQuoteDefaultSelected.current) {
      hasQuoteDefaultSelected.current = true;

      const selectedVendorId = quoteOptionsList.find(q => q.id == selectedQuote)?.vendorId || null;
      setSelectedVendorForQuote(selectedVendorId);
    }
  }, [quoteOptionsList, selectedQuote]);

  const fetchCalculationAPI = async (selectedQuoteDetail: QuoteBasicDetail) => {
    try {
      const {
        productId,
        fobCost,
        tariffPercent,
        umapPrice,
      } = selectedQuoteDetail;

      const {
        ipCube,
        brandCollection,
        brandCollectionDisplayText,
        marketingDesigner,
        marketingDesignerDisplayText,
        royaltyDesigner,
        royaltyDesignerDisplayText,
        category,
        categoryDisplayText,
        htsMaterial,
        htsMaterialDtl,
        countryOfOrigin,
        countryOfOriginDisplayText,
      } = productDetail;

      // setDisableSubmit(true);
      const quoteCalculationResponse = await api.post<ApiResponse<QuoteBasicDetail>>(
        "/Quote/calculation",
        {
          productId,
          fobCost,
          tariffPercent,
          ipCube: Number(ipCube || 0),
          umapPrice: umapPrice || null,
          brandCollection,
          brandCollectionDisplayText,
          marketingDesigner,
          marketingDesignerDisplayText,
          royaltyDesigner,
          royaltyDesignerDisplayText,
          category,
          categoryDisplayText,
          htsMaterial,
          htsMaterialDtl,
          countryOfOrigin,
          countryOfOriginDisplayText,
        }
      );

      if (quoteCalculationResponse?.isSuccess) {
        const { data } = quoteCalculationResponse;
        setNewQuoteDetailsForIPCubeUpdates({
          ...data,
          ipCube: Number(productDetail.ipCube || 0)
        });
        setIPCubeUpdatesModalOpen(true);
      }
    } catch (error) {
      console.error("Quote Calculation API call failed", error);
    }
  };

  const handleQuoteDropdownSelection = async (event: any) => {
    // Get the selected quote ID from the dropdown event
    const selectedQuoteId = event.target.value as string;
     // Find the selected quote details from the available quote options
    const selectedQuoteDetail = quoteOptionsList.find(q => q.id == selectedQuoteId) || null;
    
    // Store the selected quote details for IPCube updates
    setOldQuoteDetailsForIPCubeUpdates(selectedQuoteDetail);
    
    // Extract the VendorId value from the selected quote details
    const selectedVendorId = selectedQuoteDetail?.vendorId;
    // Extract the IPCube value from the selected quote details
    const quoteIPCube = selectedQuoteDetail?.ipCube;

    // Update the state with the selected quote ID
    setSelectedQuote(selectedQuoteId);
     // Update the state with the vendor ID associated with the selected quote
    setSelectedVendorForQuote(selectedVendorId);

    // Get the IPCube value of the product for comparison
    const productIPCube = getProductIPCube();
    
    // If the selected quote's IPCube is different from the product's IPCube, fetch new calculations
    if (productIPCube !== null && Number(productIPCube) !== quoteIPCube) {
      await fetchCalculationAPI(selectedQuoteDetail);
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Box
        sx={{
          border: "1px solid",
          borderRadius: "4px",
          padding: "15px",
          margin: "20px 0",
          ul: {
            listStyle: "none",
            display: "flex",
            flexDirection: "row",
            li: {
              padding: 1,
              border: `solid 1px ${CSSDefaults.lightColor}`,
              margin: 1,
              borderRadius: "2px",
            },
          },
        }}
      >
        <Typography variant="h6" sx={{ marginLeft: "5px", marginBottom: "10px" }}>Accepted Quote</Typography>
        <FormControl style={{ margin: "5px 5px", width: "32%" }}>
          <Select
            name={`accepted_quote`}
            displayEmpty={true}
            value={selectedQuote}
            onChange={handleQuoteDropdownSelection}
          >
            <MenuItem value="" disabled>
              - Select Quote -
            </MenuItem>
            {quoteOptionsList?.length > 0 &&
              quoteOptionsList.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  )
}

export default AcceptedQuote;