import { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { PRICING_LAYOUT_CATEGORY, PRODUCT_BASIC_CATEGORY, QUOTE_CALCULATION_FIELDS, QUOTE_APPROVAL_FIELDS, QUOTE_FIELD_SYMBOLS } from "../EditItem/Constants";
import { ProductLayoutFieldDetail } from "../../../models/Items/ProductLayoutFieldDetail";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import { DATE_FORMAT } from "../../../utils/constants";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QuoteBasicDetail } from "../../../models/Quotes/QuoteBasicDetail";
import * as Yup from "yup";
import { QuoteHistory } from "../../../models/Quotes/QuoteHistory";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { DataGrid } from "@mui/x-data-grid";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ProductSearchAutoComplete from "../../../components/ProductSearchAutoComplete";
import { Supplier } from "../../../models/Supplier";
import { formatAmount } from "../../../utils/helpers";
import { debounce } from 'lodash';

export type CreateUpdateQuoteRequest = {
  fobCost: number | null;
  targetFOBCost: number | null;
  tariffPercent: number | null;
  toolingNumber: string | null;
  umapPrice: number | null;
  carNumber: string | null;
  cubeMultiplier: number | null;
  freightCost: number | null;
  quoteNumber: string | null;
  processedDate: number | null;
  quoteDate: string | null;
  commissionDollars: number | null;
  commissionPercent: number | null;
  dnToUMAPMultiplier: number | null;
  dutyCost?: number | null;
  grossGMDollars: number | null;
  grossGMPercent: number | null;
  landedCost: number | null;
  netMargin: number | null;
  netMarginDollars: number | null;
  percentOfFOB?: number | null;
  royaltyDollars?: number | null;
  royaltyPercent?: number | null;
  tariffDollars?: number | null;
  ipCube?: number | null;
  dnPrice?: number | null;
  quoteApproved?: boolean | null;
  quoteApprovedDate?: string | null;
  quoteApprovedBy?: number | null;
  umapApproved?: boolean | null;
  umapApprovedDate?: string | null;
  umapApprovedBy?: number | null;
  quoteApprovedByDisplayText?: string | null;
  umapApprovedByDisplayText?: string | null;
  updatedDate?: Date | null;
  productId?: number | null;
  id?: number | null;
  // baseCode?: string | null;
  // stockCode?: string | null;
  brandCollection?: number | null;
  brandCollectionDisplayText?: string | null;
  shortDesc?: string | null;
  marketingDesigner?: number | null;
  marketingDesignerDisplayText?: string | null;
  royaltyDesigner?: number | null;
  royaltyDesignerDisplayText?: string | null;
  category?: number | null;
  categoryDisplayText?: string | null;
  htsMaterial?: string | null;
  htsMaterialDtl?: string | null;
  vendor?: number | null;
  vendorDisplayText?: string | null;
  updatedBy?: number | null;
};

const CreateUpdateQuote = () => {

  const [accordianExpanded, setAccordianExpanded] = useState<string[]>([PRODUCT_BASIC_CATEGORY.categoryName, PRICING_LAYOUT_CATEGORY.categoryName]);
  const [quoteDetails, setQuoteDetails] = useState<QuoteBasicDetail>(null);
  const [quoteHistoryGridData, setQuoteHistoryGridData] = useState<QuoteHistory[]>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fieldToApprove, setFieldToApprove] = useState(null);
  const [approvalChecked, setApprovalChecked] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDropDownOptions, setSelectedDropDownOptions] = useState<any>(
    {}
  );
  const [vendorDropdownOptions, setVendorDropdownOptions] = useState([]);


  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();

  // Provide a default value (empty object) for destructuring
  const { quoteId = '', viewMode = '', shortDesc = '', stockCode = '' } = location.state || {};

  const copyQuoteDetailView = "CopyQuoteDetail";
  const isCopyQuoteDetailViewMode = viewMode === copyQuoteDetailView;

  const sessionService = SessionService.getInstance();

  const initialValues: QuoteBasicDetail = {
    fobCost: null,
    targetFOBCost: 0,
    tariffPercent: 0,
    umapPrice: null,
    quoteNumber: "",
    quoteDate: null,
    ipCube: null,
    cubeMultiplier: 0,
    freightCost: 0,
    commissionDollars: 0,
    commissionPercent: 0,
    dnToUMAPMultiplier: 0,
    grossGMDollars: 0,
    grossGMPercent: 0,
    landedCost: 0,
    netMargin: 0,
    netMarginDollars: 0,
    dutyCost: 0,
    dnPrice: 0,
    percentOfFOB: 0,
    royaltyDollars: 0,
    royaltyPercent: 0,
    tariffDollars: 0,
    quoteApproved: false,
    umapApproved: false,
    id: 0,
    productId: null,
    baseCode: null,
    stockCode: null,
    brandCollection: null,
    brandCollectionDisplayText: null,
    shortDesc: null,
    marketingDesigner: null,
    marketingDesignerDisplayText: null,
    royaltyDesigner: null,
    royaltyDesignerDisplayText: null,
    category: null,
    categoryDisplayText: null,
    htsMaterial: null,
    htsMaterialDtl: null,
    vendor: null,
    vendorDisplayText: null,
  };

  const customValidationForInputField = (fieldLabel: string) => {
    return Yup.string().trim().nullable().required(`${fieldLabel} is required`)
      .test('not-zero', `${fieldLabel} cannot be zero`, value => {
        return value != "0";
      })
  };

  const validations = Yup.object().shape({
    quoteNumber: customValidationForInputField("Quote Number"),
    quoteDate: customValidationForInputField("Quote Date"),
    fobCost: customValidationForInputField("FOB Cost"),
    ipCube: customValidationForInputField("IP Cube"),
    tariffPercent: customValidationForInputField("Tariff Percent"),
    umapPrice: Yup.string().trim().nullable()
      .test("not-zero", "UMAP Price cannot be zero", value => {
      return value !== "0";
    }),
    vendor: Yup.string().trim().nullable().required("Vendor is required"),
  });

  const fetchQuoteDetails = async (quoteId: number) => {
    try {
      const resp = await api.get<ApiResponse<QuoteBasicDetail>>(`/Quote/${quoteId}`);

      if (resp?.isSuccess) {
        const { data } = resp;

        if (id) {

          formik.setValues({
            ...data,
            vendor: data?.vendor,
            vendorDisplayText: data?.vendorDisplayText,
            umapApproved: data?.umapApproved || false,
            quoteApproved: data?.quoteApproved || false,
          });

          setQuoteDetails({
            ...data,
            vendor: data?.vendor,
            vendorDisplayText: data?.vendorDisplayText,
            umapApproved: data?.umapApproved || false,
            quoteApproved: data?.quoteApproved || false,
          });

          setQuoteHistoryGridData(data?.quoteHistory || []);
        }
        else if (isCopyQuoteDetailViewMode) {

          formik.setValues({
            ...data,
            vendor: data?.vendor,
            vendorDisplayText: data?.vendorDisplayText,
            quoteApproved: false,
            quoteApprovedBy: null,
            quoteApprovedByDisplayText: null,
            quoteApprovedDate: null,
            umapApproved: false,
            umapApprovedBy: null,
            umapApprovedByDisplayText: null,
            umapApprovedDate: null,
            quoteHistory: []
          });

          setQuoteDetails({
            ...data,
            vendor: data?.vendor,
            vendorDisplayText: data?.vendorDisplayText,
            quoteApproved: false,
            quoteApprovedBy: null,
            quoteApprovedByDisplayText: null,
            quoteApprovedDate: null,
            umapApproved: false,
            umapApprovedBy: null,
            umapApprovedByDisplayText: null,
            umapApprovedDate: null,
            quoteHistory: []
          });

          setSearchTerm(String(data?.productId || ""));
        }

        setSelectedDropDownOptions({
          vendor: data?.vendor ?
            {
              id: data?.vendor,
              name: data?.vendorDisplayText
            } : undefined
        });
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  const fetchVendorList = async () => {
    try {
      const resp = await api.get<ApiResponse<Supplier[]>>(`/PickList/suppliers`);
      if (resp?.isSuccess) {
        const { data } = resp;
        setVendorDropdownOptions(data);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  const fetchQuoteDetailsFromHistory = async (quoteHistoryId: number) => {
    try {
      const resp = await api.get<ApiResponse<QuoteBasicDetail>>(`/Quote/history/${quoteHistoryId}`);

      if (resp?.isSuccess && resp?.data) {
        const { id, productQuoteId, ...filteredData } = resp.data;

        formik.setValues({
          ...formik.values,
          ...filteredData,
          quoteApproved: false,
          quoteApprovedBy: null,
          quoteApprovedByDisplayText: null,
          quoteApprovedDate: null,
          umapApproved: false,
          umapApprovedBy: null,
          umapApprovedByDisplayText: null,
          umapApprovedDate: null
        });

        setQuoteDetails({
          ...quoteDetails,
          ...filteredData,
          quoteApproved: false,
          quoteApprovedBy: null,
          quoteApprovedByDisplayText: null,
          quoteApprovedDate: null,
          umapApproved: false,
          umapApprovedBy: null,
          umapApprovedByDisplayText: null,
          umapApprovedDate: null
        });

        setSelectedDropDownOptions({
          vendor: filteredData?.vendor ?
            {
              id: filteredData?.vendor,
              name: filteredData?.vendorDisplayText
            } : undefined
        });

        toast.success(resp?.message);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    const idToFetch = isCopyQuoteDetailViewMode ? Number(quoteId) : Number(id);
    if (idToFetch && !isNaN(idToFetch)) {
      fetchQuoteDetails(idToFetch);
    }
  }, [viewMode, quoteId, id]);

  useEffect(() => {
    fetchVendorList();
  }, []);

  useEffect(() => {
    // Reset Formik values and state when route changes
    formik.resetForm();
    setQuoteDetails(null);
  }, [location.pathname]); // Runs whenever the route path changes

  const getFormattedDate = (fieldName: string) => {
    return formik.values[fieldName]
      ? moment(formik.values[fieldName]).format(DATE_FORMAT)
      : null
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validations,
    onSubmit: async (values) => {
      if (!values.productId) {
        toast.error("Please enter product details");
        return;
      }

      const data = {
        ...values,
        umapPrice: values?.umapPrice || null,
        quoteDate: getFormattedDate("quoteDate"),
      };

      delete data.isAccepted;

      setDisableSubmit(true);
      try {
        let response: ApiResponse<boolean>;
        if (id) {
          // Update existing quote
          response = await api.put<ApiResponse<boolean>>("/Quote", data);
          fetchQuoteDetails(Number(id));
        } else {

          if (isCopyQuoteDetailViewMode) {
            delete data?.id;
          }

          // Create new quote
          response = await api.post<ApiResponse<boolean>>("/Quote", data);
          navigate("/quotes");
        }

        // Handle successful response here, if needed
        if (response?.isSuccess) {
          toast.success(response?.message);
          setDisableSubmit(false);
        }
      } catch (error) {
        console.error("Create / Update Quote API Failed", error);
      } finally {
        setDisableSubmit(false); // Re-enable the submit button
      }
    }
  });

  const isSubmitButtonHidden = !(formik.values?.quoteApproved === true && formik.values?.umapApproved === true);

  const hasEmptyOrInvalidFields = QUOTE_CALCULATION_FIELDS.some(
    (field) => {
      if(field !== "umapPrice") {
        return formik.values[field] === null || formik.values[field] === "" || formik.values[field] === 0;
      }
    }
  );

  const shouldFetchCalculationAPI = (fieldName) => {

    const valuesChanged = quoteDetails &&
      QUOTE_CALCULATION_FIELDS.some(field =>
        formik.values[field] !== quoteDetails[field]
      );

    return QUOTE_CALCULATION_FIELDS.includes(fieldName) &&
      !hasEmptyOrInvalidFields &&
      (valuesChanged || !quoteDetails);
  };

  const fetchCalculationAPI = async () => {
    try {
      const {
        productId,
        fobCost,
        tariffPercent,
        ipCube,
        umapPrice,
        brandCollection,
        brandCollectionDisplayText,
        marketingDesigner,
        marketingDesignerDisplayText,
        royaltyDesigner,
        royaltyDesignerDisplayText,
        category,
        categoryDisplayText,
        htsMaterial,
        htsMaterialDtl,
        countryOfOrigin,
        countryOfOriginDisplayText,
      } = formik.values;

      setDisableSubmit(true);
      const quoteCalculationResponse = await api.post<ApiResponse<QuoteBasicDetail>>(
        "/Quote/calculation",
        {
          productId,
          fobCost,
          tariffPercent,
          ipCube,
          umapPrice: umapPrice || null,
          brandCollection,
          brandCollectionDisplayText,
          marketingDesigner,
          marketingDesignerDisplayText,
          royaltyDesigner,
          royaltyDesignerDisplayText,
          category,
          categoryDisplayText,
          htsMaterial,
          htsMaterialDtl,
          countryOfOrigin,
          countryOfOriginDisplayText,
        }
      );

      if (quoteCalculationResponse?.isSuccess) {
        const { data } = quoteCalculationResponse;
        formik.setValues({ ...formik.values, ...data });
        setDisableSubmit(false);
      }
    } catch (error) {
      setDisableSubmit(false);
      console.error("Quote Calculation API call failed", error);
    }
  };

  // Utility function to get approval date stamp
  const getApprovalDateStamp = (isApproved, date, user, fieldName) => {
    if ((isApproved === null) || (isApproved === false)) return ""; // Return empty string if approval status is null

    const formattedDate = date ? new Date(date).toLocaleDateString() : "";

    // Determine the field-specific label
    let fieldLabel = '';
    if (fieldName === QUOTE_APPROVAL_FIELDS.quoteApproved) {
      fieldLabel = 'Quote';
    } else if (fieldName === QUOTE_APPROVAL_FIELDS.umapApproved) {
      fieldLabel = 'UMAP';
    }

    return `Approved ${fieldLabel} Date Stamp: ${formattedDate} | ${user}`;
  };

  const handleQuoteApproval = async (fieldName, checked) => {
    try {
      // Dynamically update the changed field (either costApproved or dnApproved) while including field in the API call
      const approvalData = {
        id: Number(id),
        [fieldName]: checked,
      };

      setDisableSubmit(true);

      const quoteApprovalResponse = await api.post<ApiResponse<boolean>>(
        "/Quote/approval",
        approvalData
      );

      if (quoteApprovalResponse?.isSuccess) {
        const { message } = quoteApprovalResponse;
        toast.success(message);
        fetchQuoteDetails(Number(id));
        setDisableSubmit(false);
      }
    } catch (error) {
      setDisableSubmit(false);
      console.error("API call failed", error);
    }
  };

  const isFieldDisabled = (fieldName) => {
    if (isCopyQuoteDetailViewMode) {
      return false;
    }

    const { quoteApproved, umapApproved } = formik.values;

    // First, check if the user has the necessary permission
    const hasPermission = sessionService.hasPermission(Permissions.ManageQuote);
    if (!hasPermission) {
      return true; // Disable the field if the user lacks permission
    }

    // Check specific condition for `umapPrice` field
    if ((fieldName === "umapPrice") && quoteApproved === true && umapApproved !== true) {
      return false; // Allow editing in this case
    }

    // Default behavior for other cases
    return quoteApproved === true;
  };

  const getFieldBackgroundColor = (fieldName, disabled = false) => {    
    return (disabled || isFieldDisabled(fieldName)) ? "#EBEBE4" : "#FFFFFF";
  };

  // Helper function to check if a Toggle button field should be read-only
  const isCheckBoxDisabled = (fieldName) => {

    // If quoteDetails is not provided, disable it
    if (!quoteDetails || isCopyQuoteDetailViewMode) return true;

    // Check if user has necessary permission
    const hasPermission =
      (fieldName === QUOTE_APPROVAL_FIELDS.quoteApproved && sessionService.hasPermission(Permissions.ApproveQuoteCost)) ||
      (fieldName === QUOTE_APPROVAL_FIELDS.umapApproved && sessionService.hasPermission(Permissions.ApproveDNAndUMAP) && quoteDetails.umapPrice);

    if (!hasPermission) return true;
    // Check conditions for read-only fields based on quoteDetails and permissions
    
    return (
      (fieldName === QUOTE_APPROVAL_FIELDS.umapApproved &&
        (quoteDetails.quoteApproved !== true))
    );

  };

  /**
    * This function is used to render text field
    * @param fieldName - Name of the field
    * @param label - Label of the field
    * @param numeric - Boolean for numeric type field
    */
  const renderTextField = (fieldName, label, numeric = false, disabled = false, required = true, currency = false, percent = false) => (
    <FormControl className="quote-form-control-row">
      <label htmlFor={`outlined-${label}`}>
        {label}
        {required && <span className="required-field-asterisk-icon">{` *`}</span>}
      </label>
      <TextField
        id={`outlined-${label}`}
        value={formik.values[fieldName] || ""}
        label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
        onChange={formik.handleChange}
        onBlur={(e) => {
          if(validations.fields[fieldName]) {
            formik.validateField(fieldName);
          }
          formik.handleBlur(e);
          if (shouldFetchCalculationAPI(fieldName)) {
            if (!(formik.values.productId)) {
              toast.error('Please enter product details before processing field calculations')
            }
            else {
              fetchCalculationAPI();
            }
          }
        }}
        name={fieldName}
        InputProps={{
          startAdornment: (currency && formik.values[fieldName]) ? <InputAdornment position="start">$</InputAdornment> : null,
          endAdornment: (percent && formik.values[fieldName])? <InputAdornment position="end">%</InputAdornment> : null,
        }}
        onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
        className="hide-input-arrow"
        inputProps={{ type: numeric ? 'number' : 'text' }}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName, disabled), flex: 1 }}
        disabled={disabled || isFieldDisabled(fieldName)}
      />
    </FormControl>
  );

  /**
    * This function is used to render multi-line text field
    * @param fieldName - Name of the field
    * @param label - Label of the field
    */
  const renderMultilineTextField = (fieldName, label) => (
    <FormControl className="quote-form-control-row">
      <label htmlFor={`outlined-${label}`}>
        {label}
      </label>
      <TextField
        id={`outlined-${label}`}
        value={formik.values[fieldName] || ""}
        label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
        onChange={formik.handleChange}
        onBlur={(e) => {
          if(validations.fields[fieldName]) {
            formik.validateField(fieldName);
          }
          formik.handleBlur(e);
        }}
        name={fieldName}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName), flex: 1 }}
        disabled={isFieldDisabled(fieldName)}
        multiline
        rows={1}
      />
    </FormControl>
  );

  /**
    * This function is used to render checkbox field
    * @param fieldName - Name of the field
    * @param label - Label of the field
    */
  const renderCheckboxField = (fieldName, label) => (
    <FormControl className="quote-form-control-row" component="fieldset">
      <FormControlLabel
        sx={{
          width: "max-content",
          cursor: isCheckBoxDisabled(fieldName) ? "default" : "auto"
        }}
        control={
          <Checkbox
            id={`checkbox-${fieldName}`}
            disabled={isCheckBoxDisabled(fieldName)}
            checked={formik.values[fieldName]}
            onChange={(e) => {
              const fieldValue = e.target.checked;
              if (fieldName === QUOTE_APPROVAL_FIELDS.quoteApproved && (quoteDetails?.quoteApproved === true && quoteDetails?.umapApproved === true)) {
                toast.error('Please uncheck UMAP Approved before changing Quote Approved');
              }
              else if ((fieldName in QUOTE_APPROVAL_FIELDS) && fieldValue !== null) {
                handleOpenApprovalModal(fieldName, fieldValue);
              }
            }}
            onBlur={(e) => {
              if(validations.fields[fieldName]) {
                formik.validateField(fieldName);
              }
              formik.handleBlur(e);
            }}
            name={fieldName}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 28, // Increase the checkbox size
              },
            }}
          />
        }
        label={label}
      />
      <div style={{ fontSize: "14px", color: "gray", marginTop: "5px", marginLeft: "30px" }}>
        {(formik.values.quoteApproved !== null || formik.values.umapApproved !== null) && (
          <>
            {fieldName === QUOTE_APPROVAL_FIELDS.quoteApproved &&
              getApprovalDateStamp(formik.values.quoteApproved, formik.values.quoteApprovedDate, formik.values.quoteApprovedByDisplayText, fieldName)}
            {fieldName === QUOTE_APPROVAL_FIELDS.umapApproved &&
              getApprovalDateStamp(formik.values.umapApproved, formik.values.umapApprovedDate, formik.values.umapApprovedByDisplayText, fieldName)}
          </>
        )}
      </div>
    </FormControl>
  );


  /**
   * This function is used to render autocomplete with dropdown option list
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param optionList - Dropdown option array list
   * @param optionLabelGetter - Custom label for dropdown option list
   */
  const renderAutocomplete = (
    fieldName: string = "",
    label: string = "",
    optionList: Array<any> | undefined,
    optionLabelGetter: (option) => string = null
  ) => (
    <FormControl className="quote-form-control-row">
      <label
        htmlFor={`outlined-${fieldName}`}
      >
        {label}
        <span className="required-field-asterisk-icon">{` *`}</span>
      </label>
      <Autocomplete
        id={`outlined-${fieldName}`}
        value={
          selectedDropDownOptions[fieldName]
            ? selectedDropDownOptions[fieldName]
            : null
        }
        onChange={(event, newValue) =>
          handleAutocompleteOptionsChange(fieldName, newValue)
        }
        onBlur={(e) => {
          if(validations.fields[fieldName]) {
            formik.validateField(fieldName);
          }
          formik.handleBlur(e);
        }}
        options={optionList ? optionList : []}
        getOptionLabel={(option) =>
          optionLabelGetter ? optionLabelGetter(option) : option.name || option
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            error={
              Boolean(formik.errors[fieldName])
            }
            label={formik.errors[fieldName] ? String(formik.errors[fieldName]) : ""}
            key={params.id}
            {...params}
            placeholder="- Select -"
          />
        )}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName), flex: 1 }}
        disabled={isFieldDisabled(fieldName)}
      />
    </FormControl>
  );

  /**
   * This function is used to render date picker field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderDatePicker = (fieldName, label) => (
    <FormControl className="quote-form-control-row">
      <label htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
        <span className="required-field-asterisk-icon">{` *`}</span>
      </label>
      <TextField
        type="date"
        name={fieldName}
        label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
        value={moment(formik.values[fieldName]).format("YYYY-MM-DD")}
        onChange={(e) => {
          const value = e.target.value;
          formik.setFieldValue(
            fieldName,
            value ? moment(e.target.value, "YYYY-MM-DD").toDate() : null
          )
        }}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        onBlur={(e) => {
          if(validations.fields[fieldName]) {
            formik.validateField(fieldName);
          }
          formik.handleBlur(e);
        }}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName), flex: 1 }}
        disabled={isFieldDisabled(fieldName)}
      />
    </FormControl>
  );

  /**
   * This function is used to render read-only label field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderReadOnlyLabel = (fieldName, label) => {

    const symbol = QUOTE_FIELD_SYMBOLS[fieldName];
    const value = formik.values[fieldName] || 0;

    const formattedValue = symbol
      ? symbol === "%" // Check for postfix fields
        ? `${value}${symbol}` // Append for postfix
        : `${symbol}${value}` // Prepend for prefix
      : value; // Default case with no symbol

    return (
      <FormControl className="quote-form-control-row">
        <label htmlFor={`outlined-${fieldName}`}>{label}</label>
        <span style={{ margin: "16px 0 0 10px", fontWeight: 500 }}>{formattedValue}</span>
      </FormControl>
    );
  };

  const renderBlankSpace = () => (
    <FormControl className="quote-form-control-row"></FormControl>
  );

  /**
   * This function is used to handle Autocomplete dropdown value
   * @param fieldName - Name of the field
   * @param selectedItem - Selected option value
  */
  const handleAutocompleteOptionsChange = (fieldName, selectedItem) => {
    if (!selectedItem) {
      formik.setFieldValue("vendor", null);
      formik.setFieldValue("vendorDisplayText", null);
      setSelectedDropDownOptions({
        [fieldName]: undefined
      });
    } else {
      const selectedId = selectedItem?.id;
      const selectedName = selectedItem?.name;
      formik.setFieldValue(fieldName, selectedId);
      formik.setFieldValue("vendorDisplayText", selectedName);
      setSelectedDropDownOptions({
        [fieldName]: selectedItem
      });
    }
  };

  const labelFieldsRenderer = {
    freightCost: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    dnPrice: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    grossGMPercent: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    landedCost: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    netMargin: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
  };

  const readOnlyFieldsRenderer = {
    brandCollectionDisplayText: (field) => () => renderTextField(field.fieldName, field.label, false, true, false),
    marketingDesignerDisplayText: (field) => () => renderTextField(field.fieldName, field.label, false, true, false),
    royaltyDesignerDisplayText: (field) => () => renderTextField(field.fieldName, field.label, false, true, false),
    categoryDisplayText: (field) => () => renderTextField(field.fieldName, field.label, false, true, false),
    htsMaterial: (field) => () => renderTextField(field.fieldName, field.label, false, true, false),
    htsMaterialDtl: (field) => () => renderTextField(field.fieldName, field.label, false, true, false),
    countryOfOriginDisplayText: (field) => () => renderTextField(field.fieldName, field.label, false, true, false),
  }
  // fieldName: any, label: any, numeric?: boolean, disabled?: boolean, required?: boolean, currency?
  const editableFieldsRenderer = {
    fobCost: (field) => () => renderTextField(field.fieldName, field.label, true, false, true, true),
    targetFOBCost: (field) => () => renderTextField(field.fieldName, field.label, true, false, false, true),
    tariffPercent: (field) => () => renderTextField(field.fieldName, field.label, true, false, true, false, true),
    umapPrice: (field) => () => renderTextField(field.fieldName, field.label, true, false, false, true),
    quoteNumber: (field) => () => renderTextField(field.fieldName, field.label),
    ipCube: (field) => () => renderTextField(field.fieldName, field.label, true),
    vendor: (field) => () => renderAutocomplete(field.fieldName, field.label, vendorDropdownOptions),
    quoteDate: (field) => () => renderDatePicker(field.fieldName, field.label),
    notes: (field) => () => renderMultilineTextField(field.fieldName, field.label),
    blankField: () => () => renderBlankSpace(),
    quoteApproved: (field) => () => renderCheckboxField(field.fieldName, field.label),
    umapApproved: (field) => () => renderCheckboxField(field.fieldName, field.label),
  };

  /**
   * This function is used to handle accordian group expand / collapse
   * @param group - Accordian group name
   */
  const handleAccordianExpand = (group: string) => {
    const isSectionOpen = accordianExpanded.includes(group);

    // Check if section is expanded (group name present in array)
    if (isSectionOpen) {
      // Remove the group from the expanded array
      const updatedOpenSections = accordianExpanded.filter(sec => sec !== group);
      setAccordianExpanded(updatedOpenSections);
    } else {
      // Add the group to the expanded array
      setAccordianExpanded([...accordianExpanded, group]);
    }
  };

  // Combine editable and read-only field renderers
  const combinedFieldsRenderer = {
    ...editableFieldsRenderer,
    ...readOnlyFieldsRenderer,
    ...labelFieldsRenderer,
  };

  const combinedRendererNames = Object.keys(combinedFieldsRenderer);

  /**
   * This function is used to render accordian based on grouped fields. each groups accordian containg some fields
   * @param group - Accordian group name
   * @param fields - Accordian field name of groups
   */
  const renderFormSection = (
    group: string,
    fields: ProductLayoutFieldDetail[]
  ) => {
    const sortedFields = [...fields].sort((a, b) => a.tabOrder - b.tabOrder);

    return (
      <Accordion
        key={group}
        expanded={accordianExpanded.includes(group)}
        onChange={() => handleAccordianExpand(group)}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls={`panel-${group}-content`}
          id={`panel-${group}-header`}
          className={"active-accordian-expanded"}
        >
          {accordianExpanded.includes(group) ? <RemoveIcon /> : <AddIcon />}
          <Typography style={{ fontWeight: accordianExpanded.includes(group) ? "bold" : "normal" }}>
            {group}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="quote-accordian-fields">
          {sortedFields.map(field => {
            const renderFunction = combinedFieldsRenderer[combinedRendererNames.find((fieldName) => fieldName.toLowerCase() === field.fieldName.toLowerCase())]; // Use the camelCase version of the field name
            return renderFunction ? renderFunction(field)() : null;
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const handleProductSearchSelectedItem = (productSearchResult: QuoteBasicDetail | null) => {
    if (productSearchResult && Object.keys(productSearchResult)?.length > 0) {
      const {
        id,
        brandCollection,
        brandCollectionDisplayText,
        category,
        categoryDisplayText,
        marketingDesigner,
        marketingDesignerDisplayText,
        royaltyDesigner,
        royaltyDesignerDisplayText,
        htsMaterial,
        htsMaterialDtl,
        countryOfOrigin,
        countryOfOriginDisplayText,
        vendor,
        vendorDisplayText,
        ipCube
      } = productSearchResult;

      formik.setValues({
        ...formik.values,
        productId: id,
        brandCollection,
        brandCollectionDisplayText,
        category,
        categoryDisplayText,
        marketingDesigner,
        marketingDesignerDisplayText,
        royaltyDesigner,
        royaltyDesignerDisplayText,
        htsMaterial,
        htsMaterialDtl,
        countryOfOrigin,
        countryOfOriginDisplayText,
        vendor,
        vendorDisplayText,
        ipCube
      });

      setSelectedDropDownOptions({
        vendor: productSearchResult?.vendor ?
          {
            id: productSearchResult?.vendor,
            name: productSearchResult?.vendorDisplayText
          } : undefined
      });

    }

  };

  const renderSearchBox = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <label htmlFor="product-search">
          Search Product:
        </label>
        <span style={{ marginLeft: "8px", marginRight: "20px" }}>
          <ProductSearchAutoComplete
            selectedItem={handleProductSearchSelectedItem}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </span>
        <label htmlFor="product-search">
          [Search by Product Id / Stock Code / Short Description]
        </label>
      </div>
    )
  }

  const QuoteHistoryGridColumns = [
    (quoteDetails?.isAccepted !== true && !!sessionService.hasPermission(Permissions.ManageQuote)) && {
      field: "actions", headerName: "Actions", width: 80, sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => fetchQuoteDetailsFromHistory(params.row.id)}
          disabled={quoteDetails?.quoteApproved}
        >
          <ContentCopyIcon />
        </IconButton>
      ),
    },
    { field: "id", headerName: "Id", width: 80 },
    { field: "quoteNumber", headerName: "Quote Number", width: 120 },
    {
      field: "quoteDate", headerName: "Quote Date", width: 110,
      valueGetter: (params) => params.row.quoteDate ? moment(params.row.quoteDate).format("YYYY-MM-DD") : ""
    },
    { field: "notes", headerName: "Notes", width: 100 },
    { field: "vendorDisplayText", headerName: "Vendor", width: 100 },
    { field: "ipCube", headerName: "IP Cube", width: 100 },
    {
      field: "fobCost", headerName: "FOB Cost", width: 100,
      valueGetter: (params) => params.row.fobCost ? formatAmount(params.row.fobCost, "USD") : ""
    },
    {
      field: "targetFOBCost", headerName: "Target FOB Cost", width: 100,
      valueGetter: (params) => params.row.targetFOBCost ? formatAmount(params.row.targetFOBCost, "USD") : ""
    },
    {
      field: "tariffPercent", headerName: "Tariff Percent", width: 100,
      valueGetter: (params) => params.row.tariffPercent ? `${params.row.tariffPercent}%` : ""
    },
    {
      field: "dnPrice", headerName: "DN Price", width: 100,
      valueGetter: (params) => params.row.dnPrice ? formatAmount(params.row.dnPrice, "USD") : ""
    },
    {
      field: "umapPrice", headerName: "UMAP Price", width: 100,
      valueGetter: (params) => params.row.umapPrice ? formatAmount(params.row.umapPrice, "USD") : ""
    },
    {
      field: "grossGMPercent", headerName: "Gross GM Percent", width: 100,
      valueGetter: (params) => params.row.grossGMPercent ? `${params.row.grossGMPercent}%` : ""
    },
    {
      field: "netMargin", headerName: "Net Margin", width: 100,
      valueGetter: (params) => params.row.netMargin ? `${params.row.netMargin}%` : ""
    },
    { field: "historyByDisplayText", headerName: "History By", width: 100 },
    {
      field: "historyDate", headerName: "History Date", width: 110,
      valueGetter: (params) => params.row.historyDate ? moment(params.row.historyDate).format("YYYY-MM-DD") : ""
    },
  ].filter(Boolean); // Remove `false` values from the array;

  const renderQuoteHistoryGridView = () => {
    return (
      <Box style={{ marginTop: '2%', width: '100%' }}>
        <DataGrid
          rows={quoteHistoryGridData || []}
          columns={QuoteHistoryGridColumns}
          disableColumnMenu
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }], // Change "asc" for ascending
            },
          }}
          sortingOrder={['desc', 'asc']}
          pageSizeOptions={[10, 25, 50, 100]}
          pagination
          paginationMode="client"
          rowCount={quoteHistoryGridData.length}
          sortingMode="client"
          getRowHeight={() => 'auto'}
          rowSelection={false}
        />
      </Box>
    );
  };

  const handleOpenApprovalModal = (fieldName, checked) => {
    setFieldToApprove(fieldName);
    setApprovalChecked(checked);
    setShowConfirmationModal(true);
  };

  const handleConfirmApproval = () => {
    // Proceed with API call
    handleQuoteApproval(fieldToApprove, approvalChecked);
    setShowConfirmationModal(false); // Close modal after confirmation
  };

  const isQuoteFormVisible = sessionService.hasPermission(Permissions.ManageQuote) || sessionService.hasPermission(Permissions.ApproveQuoteCost) ||
    sessionService.hasPermission(Permissions.ApproveDNAndUMAP) || sessionService.hasPermission(Permissions.ViewQuoteHistory);

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title={"Confirm Approval"}
        message={`Are you sure you want to ${approvalChecked ? "approve" : "reject"
          } this quote?`}
        handleConfirmYes={handleConfirmApproval}
        handleConfirmNo={() => setShowConfirmationModal(false)}
      />
      <Card sx={{ marginBottom: 1 }}>
        <CardContent>
          {isQuoteFormVisible &&
            <>
              <div style={{ display: "flex" }}>
                <Typography variant="h5" fontWeight="bold">
                  {id ? 'Update' : 'Create'} Quote &nbsp;
                  {id && <>
                    ({shortDesc && `${shortDesc} - `}
                    {stockCode ? stockCode : `Product Id ${formik.values.productId || ''}`})
                  </>}
                </Typography>
                <span style={{ marginLeft: "auto", cursor: "pointer" }}>
                  <CloseIcon onClick={() => navigate("/quotes")} />
                </span>
              </div>
              {id && formik.values?.updatedDate &&
                <Box style={{ display: "flex", flexDirection: "column", alignItems: "end", fontStyle: "italic" }}>
                  <span>DATE OF LAST QUOTE</span>
                  <span>{new Date(formik.values.updatedDate).toLocaleDateString()}</span>
                </Box>}
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <Box sx={{ margin: "20px 0" }}>
                    {!id && renderSearchBox()}
                    {renderFormSection(PRODUCT_BASIC_CATEGORY.categoryName, PRODUCT_BASIC_CATEGORY.fields)}
                    {renderFormSection(PRICING_LAYOUT_CATEGORY.categoryName, PRICING_LAYOUT_CATEGORY.fields)}
                  </Box>
                  {sessionService.hasPermission(Permissions.ManageQuote) && isSubmitButtonHidden && (
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ margin: "8px 5px", width: "135px" }}
                      disabled={disableSubmit}
                    >
                      Submit
                    </Button>)}
                </form>
              </Box>
            </>}
          {(sessionService.hasPermission(Permissions.ViewQuoteHistory) || sessionService.hasPermission(Permissions.ManageQuote)) && id && (
            <div style={{ marginTop: "30px" }}>
              <Typography variant="h5" fontWeight="bold" margin={2} textAlign={"center"}>Quote History</Typography>
              {quoteHistoryGridData?.length > 0 && renderQuoteHistoryGridView()}
              {quoteHistoryGridData?.length === 0 &&
                <Typography variant="body1" margin={3} textAlign={"center"}>No records found</Typography>}
            </div>)}
        </CardContent>
      </Card>
    </>
  )
}

export default CreateUpdateQuote;