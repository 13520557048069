import { Item } from "../../../models/Items/Item";
import * as Yup from "yup";

  export const FIELD_NAMES = {
    certiType: "certiType",
    certiNote: "certiNote",
    height: "height",
    width: "width",
    extension: "extension",
    baseDepth: "baseDepth",
    baseHeight: "baseHeight",
    baseLength: "baseLength",
    baseWidth: "baseWidth",
    canopyDepth: "canopyDepth",
    canopyLength: "canopyLength",
    canopyShape: "canopyShape",
    backplateDepth: "backplateDepth",
    backplateHeight: "backplateHeight",
    backplateLength: "backplateLength",
    backplateShape: "backplateShape",
    backplateWidth: "backplateWidth",
    fixture_Height: "fixture_Height",
    height_min: "height_min",
    jBox: "jBox",
    oaHeight: "oaHeight",
    style: "style",
    romanticSize: "romanticSize",
    designGrouping: "designGrouping",
    stockcode: "stockcode",
    longDesc: "longDesc",
    pdFamilyName: "pdFamilyName",
    shortDesc: "shortDesc",
    // familyName: "familyName",
    seriesName: "seriesName",
    category: "category",
    function: "function",
    baseCode: "baseCode",
    shortDescPrv: "shortDescPrv",
    pdCode: "pdCode",
    stage: "stage",
    status: "status",
    stockcodePrv: "stockcodePrv",
    basecodePrv: "basecodePrv",
    relatives: "relatives",
    department: "department",
    introdate: "introdate",
    pdStockcode: "pdStockcode",
    marketingDesigner: "marketingDesigner",
    groupName: "groupName",
    brandCollection: "brandCollection",
    socketQty: "socketQty",
    socketType: "socketType",
    socketDtl: "socketDtl",
    bulbShape: "bulbShape",
    lightSource: "lightSource",
    lightQty: "lightQty",
    ledType: "ledType",
    ledDriverType: "ledDriverType",
    ledDimmingType: "ledDimmingType",
    ledColorTemp: "ledColorTemp",
    ledLumens: "ledLumens",
    assembly: "assembly",
    assemblyDetail: "assemblyDetail",
    mounting: "mounting",
    priMtrlManufMethod: "priMtrlManufMethod",
    secMtrlManufMethod: "secMtrlManufMethod",
    thirdMtrlManufMethod: "thirdMtrlManufMethod",
    fourACDesc: "fourACDesc",
    primaryMaterial: "primaryMaterial",
    secondaryMaterial: "secondaryMaterial",
    treatmentType: "treatmentType",
    treatment: "treatment",
    thirdMaterial: "thirdMaterial",
    skuType: "skuType",
    packaging: "packaging",
    shadeDesc: "shadeDesc",
    wiringDtl: "wiringDtl",
    candleTube: "candleTube",
    candleTubeDiameter: "candleTubeDiameter",
    candleTubeFinish: "candleTubeFinish",
    candleTubeHeight: "candleTubeHeight",
    candleTubeShape: "candleTubeShape",
    harpFinish: "harpFinish",
    harpType: "harpType",
    riser: "riser",
    finialFinish: "finialFinish",
    finialHeight: "finialHeight",
    finialMaterial: "finialMaterial",
    finialShape: "finialShape",
    finialWidth: "finialWidth",
    hardwareFinish: "hardwareFinish",
    hardwareMaterial: "hardwareMaterial",
    rod_Count: "rod_Count",
    rod_Diameter: "rod_Diameter",
    break_count: "break_count",
    break_length: "break_length",
    rod_Shape: "rod_Shape",
    knobType: "knobType",
    finialFinishCd: "finialFinishCd",
    chainLink: "chainLink",
    chain: "chain",
    baseMaterial: "baseMaterial",
    baseFinishCd: "baseFinishCd",
    factoryItemNum: "factoryItemNum",
    factShadeGlassCode: "factShadeGlassCode",
    htsMaterial: "htsMaterial",
    htsMaterialDtl: "htsMaterialDtl",
    subContVendor1: "subContVendor1",
    subContVendor1FactoryItem: "subContVendor1FactoryItem",
    subContVendor2: "subContVendor2",
    subContVendor2FactoryItem: "subContVendor2FactoryItem",
    subContVendor3: "subContVendor3",
    subContVendor3FactoryItem: "subContVendor3FactoryItem",
    subContVendor4: "subContVendor4",
    subContVendor4FactoryItem: "subContVendor4FactoryItem",
    subContVendor5: "subContVendor5",
    subContVendor5FactoryItem: "subContVendor5FactoryItem",
    targetWholesalePrice: "targetWholesalePrice",
    targetIMAP: "targetIMAP",
    primaryFinishId: "primaryFinishId",
    secondaryFinishId: "secondaryFinishId",
    thirdFinishDesc: "thirdFinishDesc",
    baseFinishDesc: "baseFinishDesc",
    bC_DesignerSeq: "bC_DesignerSeq",
    bC_CategorySeq: "bC_CategorySeq",
    bomSearch: "bomSearch",
    dataGridView: "dataGridView",
    sellingLineInStockDate: "sellingLineInStockDate",
    slIntroDate: "slIntroDate",
    bomType: "bomType",
    reqDate: "reqDate",
    agreedSampleReadyDate: "agreedSampleReadyDate",
    rod1Length: "rod1Length",
    rod2Length: "rod2Length",
    rod3Length: "rod3Length",
    shadeSlant: "shadeSlant",
    cordCoverBreakLength: "cordCoverBreakLength",
    cordCoverBreakWidth: "cordCoverBreakWidth",
    couplingLength: "couplingLength",
    couplingWidth: "couplingWidth",
    ledBoardWattage: "ledBoardWattage",
    itemStockingType: "itemStockingType",
    developmentType: "developmentType",
    itemVendorCost: "itemVendorCost",
    itemStatus: "itemStatus",
  }
  
  // the values in below object properties must be same as the value of field property of api/PickList/data-fields-types-list response
  // This is used to filter and find appropriate types based on field value for displaying as dropdown on edit item page
  export const dataFieldNames = {
    certiNote: "CertiNote",
    baseShape: "BaseShape",
    batteryType: "BatteryType",
    brandCollection: "BrandCollection",
    bulbShape: "BulbShape",
    bulbShapeCode: "BulbShapeCode",
    canopyShape: "CanopyShape",
    category: "Category",
    colorTemperature: "LED Color Temperature",
    cri: "CRI",
    department: "Department",
    ledDimmingType: "LED Dimming Type",
    driverLocation: "DriverLocation",
    ledDriverType: "LED Driver Type",
    ledType: "LED Type",
    lightSource: "Light Source",
    locationRating: "LocationRating",
    mountingType: "Mounting",
    reportingBrand: "ReportingBrand",
    socketType: "SocketType",
    wireColor: "WireColor",
    jBox: "JBox",
    ledColorTemp: "LED Color Temperature",
    mounting: "Mounting",
    packaging: "Packaging",
    riser: "Riser",
    knobType: "Knob Type",
    chainLink: "Chain Link",
    certiType: "CertiType",
    sKURank: "Sku Rank",
    vendorMOQType: "MOQ Type",
    itemStockingType: "StockingType",
    warehouse: "Warehouse",
    sLStatus: "SL Status",
    developmentType: "DevelopmentType",
    romanticSize: "RomanticSize",
    prop65: "prop65",
    etlControlNumber: "ETLControlNumber",
    ukcaCertification: "UKCACertification",
    ukcaCertificationNumber: "UKCACertificationNumber",
    customerAssemblyDetail: "CustomerAssemblyDetail",
    estimatedTimeRequiredForAssembly: "EstimatedTimeRequiredForAssembly",
    patentStatus: "PatentStatus",
    suggestedNumberOfPeopleRequiredForInstall: "SuggestedNumberOfPeopleRequiredForInstall",
    toolsRequired: "ToolsRequired",
    warrantyYears: "WarrantyYears",
    lightKitIncluded: "LightKitIncluded",
    masterFinish: "MasterFinish",
    motorType: "MotorType",
    relativeStyle: "RelativeStyle",
    slopeCeiling: "SlopeCeiling",
    specialAssembly: "SpecialAssembly",
    swiveling: "Swiveling",
    countryOfOrigin: "CountryOfOrigin",
    itemRoyaltyCode: "ItemRoyaltyCode",
    batteryLocation: "BatteryLocation",
    batteryQty: "BatteryQty",
    bulbBase: "BulbBase",
    bulbColorTemp: "BulbColorTemp",
    bulbCri: "BulbCri",
    bulbIncandescentEquivalentWattage: "BulbIncandescentEquivalentWattage",
    bulbLumen: "BulbLumen",
    bulbVoltage: "BulbVoltage",
    chargeTime: "ChargeTime",
    dimmer: "Dimmer",
    ledClass: "LedClass",
    lumensOnHighPower: "LumensOnHighPower",
    lumensOnLowPower: "LumensOnLowPower",
    lumensOnMedPower: "LumensOnMedPower",
    recBulbBothRequired: "RECBulbBothRequired",
    voltageInput: "VoltageInput",
    voltageOutput: "VoltageOutput",
    fssPrimaryMaterial: "FSSPrimaryMaterial",
    fssSecondaryMaterial: "FSSSecondaryMaterial",
    fssThirdMaterial: "FSSThirdMaterial",
    recommendedRoom1: "RecommendedRoom1",
    diffuser1FactoryFinishCode: "Diffuser1FactoryFinishCode",
    diffuser1Finish: "Diffuser1Finish",
    diffuser1Material: "Diffuser1Material",
    diffuser2FactoryFinishCode: "Diffuser2FactoryFinishCode",
    diffuser2Finish: "Diffuser2Finish",
    diffuser2Material: "Diffuser2Material",
    finishCode: "FinishCode",
    finishDescription: "FinishDescription",
    treatmentMaterial: "TreatmentMaterial",
    ipFreightClass: "IPFreightClass",
    ipUpsable: "IPUPSable",
    mpUpsable: "MPUpsable",
    chainRodFinish: "ChainRodFinish",
    connectionType: "ConnectionType",
    glass: "Glass",
    productType: "ProductType",
    websites: "Websites",
    reverseSwitchLocation: "ReverseSwitchLocation",
    shadeCode: "ShadeCode",
    shadeFinishingType: "ShadeFinishingType",
    shadeOuterMaterial: "ShadeOuterMaterial",
    shadeTreatment: "ShadeTreatment",
    socketFinishFactoryCode: "SocketFinishFactoryCode",
    socketHousingFinish: "SocketHousingFinish",
    socketHousingMaterial: "SocketHousingMaterial",
    productTrim: "ProductTrim",
    itemClass: "ItemClass",
    class: "Class",
    relativeTrend: "RelativeTrend",
    seriesName: "SeriesName",
    brand: "Brand",
    buyerNumber: "BuyerNumber",
    collection: "Collection",
    color: "Color",
    division: "Division",
    engineeringGroup: "EngineeringGroup",
    inventoryStrategy: "InventoryStrategy",
    itemFlashMessage: "ItemFlashMessage",
    itemPoolCode: "ItemPoolCode",
    lampingCode: "LampingCode",
    ledRoyalty: "LEDRoyalty",
    plannerNumber: "PlannerNumber",
    prepackedBanded: "PrepackedBanded",
    productNameDescription: "ProductNameDescription",
    purchasingABC: "PurchasingABC",
    quickShipItem: "QuickShipItem",
    relatedUnitofMeasure: "RelatedUnitofMeasure",
    shippingMethod: "ShippingMethod",
    unitofMeasure: "UnitofMeasure",
    yearCode: "YearCode",
    designGrouping: "DesignGrouping",
  }

export const attachmentLevels = {
  BC: "BC",
  SC: "SC"
}

export const initialValues: Item = {
  stockcode: "",
  longDesc: "",
  shortDesc: "",
  stage: null,
  status: null,
  pdFamilyName: "",
  // familyName: "",
  category: null,
  style: null,
  function: null,
  skuType: null,
  assembly: "",
  factoryItemNum: null,
  shadeDesc: "",
  primaryMaterial: null,
  secondaryMaterial: null,
  factShadeGlassCode: "",
  height: null,
  width: null,
  socketQty: null,
  socketType: null,
  socketDtl: "",
  wiringDtl: "",
  shortDescPrv: "",
  hardwareMaterial: null,
  pdCode: "",
  sampleQty: null,
  stockcodePrv: "",
  basecodePrv: "",
  fixture_Height: null,
  height_min: null,
  mounting: "",
  bulbShape: null,
  certiType: "",
  jBox: "",
  oaHeight: "",
  treatmentType: "",
  treatment: "",
  rod_Count: null,
  rod_Diameter: "",
  break_count: null,
  break_length: "",
  rod_Shape: "",
  title20: false,
  department: null,
  introdate: null,
  htsMaterial: "",
  htsMaterialDtl: "",
  priMtrlManufMethod: "",
  secMtrlManufMethod: "",
  thirdMtrlManufMethod: "",
  thirdMaterial: null,
  testing: false,
  marketingDesigner: null,
  lightSource: null,
  ledType: null,
  ledDriverType: null,
  ledDimmingType: null,
  ledLumens: "",
  ledColorTemp: "",
  knobType: "",
  finialFinishCd: "",
  baseMaterial: null,
  baseMaterialDisplayText: "",
  subContVendor1: "",
  subContVendor1FactoryItem: "",
  subContVendor2: "",
  subContVendor2FactoryItem: "",
  subContVendor3: "",
  subContVendor3FactoryItem: "",
  subContVendor4: "",
  subContVendor4FactoryItem: "",
  subContVendor5: "",
  subContVendor5FactoryItem: "",
  groupName: "",
  romanticSize: null,
  romanticSizeDisplayText: "",
  fourACDesc: "",
  designGrouping: null,
  designGroupingDisplayText: "",
  brandCollection: null,
  canopyShape: null,
  candleTubeHeight: null,
  finialMaterial: "",
  bC_DesignerSeq: null,
  bC_CategorySeq: null,
  changeBaseCodeForAllProducts: false,
  certiNote: null,
  certiNoteDisplayText: "",
  baseShape: null,
  baseShapeDisplayText: "",
  batteryType: null,
  batteryTypeDisplayText: "",
  bulbShapeDisplayText: "",
  bulbShapeCode: null,
  bulbShapeCodeDisplayText: "",
  canopyShapeDisplayText: "",
  categoryDisplayText: "",
  colorTemperature: null,
  colorTemperatureDisplayText: "",
  cri: null,
  criDisplayText: "",
  departmentDisplayText: "",
  ledDimmingTypeDisplayText: "",
  driverLocation: null,
  driverLocationDisplayText: "",
  ledDriverTypeDisplayText: "",
  ledTypeDisplayText: "",
  lightSourceDisplayText: "",
  locationRating: null,
  locationRatingDisplayText: "",
  mountingType: null,
  mountingTypeDisplayText: "",
  reportingBrand: null,
  reportingBrandDisplayText: "",
  // socketType: null,
  socketTypeDisplayText: "",
  wireColor: null,
  wireColorDisplayText: "",
  // New string fields
  allThreadType: "",
  assemblyDetail: "",
  backplateShape: "",
  baseCode: "",
  basecodeDescription: "",
  binning: "",
  bladeFinish1: "",
  bladeFinish2: "",
  bulbInBox1: "",
  bulbWattage: "",
  candleTubeFinish: "",
  cbCertificationNumber: "",
  cccCertificationNumber: "",
  ceCertificationNumber: "",
  chainLength: "",
  compatibleLightKit: "",
  copyFreeForm: "",
  cordColor: "",
  cordCoverLength: "",
  cordCoverTotalLength: "",
  cordLength: "",
  couplingWidth: null,
  criticalToQuality: "",
  depth: "",
  driverDimensions: "",
  eacCertificateNumber: "",
  emcCertificationNumber: "",
  energyStarCertificationNumber: "",
  etlCertificationNumber: "",
  extension: "",
  factoryFinishCode: "",
  fcCertificationNumber: "",
  feature1: "",
  feature2: "",
  feature3: "",
  canBeInverted: false,
  fieldModify: false,
  hangStraight: false,
  finialFinish: "",
  hardwareFinish: "",
  harpFinish: "",
  harpType: "",
  icCertificationNumber: "",
  lampshadeType: "",
  maxRotation: "",
  maxSlopeCeiling: "",
  mountingProcedure: "",
  notesWeb: "",
  packaging: "",
  patentNumber: "",
  pdStockcode: "",
  itemClass: null,
  itemClassDisplayText: null,
  profileRomanceCopy: "",
  prop65: null,
  prop65DisplayText: "",
  recBulb1Hyperlink: "",
  recBulb1ManufactureID: "",
  recBulb1Name: "",
  recBulb2Hyperlink: "",
  recBulb2ManufactureID: "",
  recBulb2Name: "",
  relativeTrend: null,
  relativeTrendDisplayText: null,
  remoteType: "",
  riser: "",
  rod1Length: null,
  rod2Length: null,
  rod3Length: null,
  seoCopy: "",
  seriesDescription: "",
  seriesName: null,
  seriesNameDisplayText: "",
  shadeExteriorMaterial: "",
  shadeFitterType: "",
  shadeInteriorMaterial: "",
  shadeShape: "",
  shadeSlant: "",
  standardCare: "",
  stockCode: "",
  switchDetails: "",
  switchLocation: "",
  tagFinishDescription: "",
  title20Bulb: "",
  transformerType: "",
  treatmentTypeInterior: "",
  vendor: null,
  vendorDisplayText: "",
  averageBulbLife: null,
  backplateDepth: null,
  backplateHeight: null,
  backplateLength: null,
  backplateWidth: null,
  baseDepth: null,
  baseHeight: null,
  baseLength: null,
  baseWidth: null,
  bladePitch: null,
  bladeToCeilingMaxHeight: null,
  bladeToCeilingMinHeight: null,
  bulbBeamSpread: null,
  bulbDimmingRangeMax: null,
  bulbDimmingRangeMin: null,
  cableLength: null,
  candleTubeDiameter: null,
  candleTubeShape: null,
  canopyDepth: null,
  canopyLength: null,
  canopyShade: null,
  capacitorValue: null,
  carton1DimensionalWeight: null,
  carton1Height: null,
  carton1Length: null,
  carton1Weight: null,
  carton1Width: null,
  carton2DimensionalWeight: null,
  carton2Height: null,
  carton2Length: null,
  carton2Weight: null,
  carton2Width: null,
  carton3Height: null,
  carton3Length: null,
  carton3Weight: null,
  carton3Width: null,
  cartonCount: null,
  cfmAverage: null,
  cfmHigh: null,
  cfmLow: null,
  cfmMedium: null,
  cfmWattageHigh: null,
  cfmWattageLow: null,
  cfmWattageMedium: null,
  chainLink1Height: null,
  chainLink1Thickness: null,
  chainLink1Width: null,
  chainLink2Height: null,
  chainLink2Thickness: null,
  chainLink2Width: null,
  class: null,
  classDisplayText: null,
  cordCoverBreakLength: null,
  cordCoverBreakWidth: null,
  cordCoverQty: null,
  couplingLength: null,
  cutoutSize: null,
  deliveredLumens: null,
  diameter: null,
  dnPrice: null,
  efficacy: null,
  fanDiameter: null,
  finialHeight: null,
  finialShape: null,
  finialWidth: null,
  heightFromCenterOfJbox: null,
  heightWithoutTail: null,
  hoursLifeOnHighPower: null,
  hoursLifeOnLowPower: null,
  hoursLifeOnMedPower: null,
  imap: null,
  installableHeight1: null,
  installableHeight2: null,
  installableHeight3: null,
  installableHeight4: null,
  installableHeight5: null,
  ipCube: null,
  ipHeight: null,
  ipLength: null,
  ipWeight: null,
  ipWidth: null,
  lampSKUNetWeight: null,
  ledBoardLumen: null,
  ledBoardWattage: null,
  length: null,
  lightQty: null,
  maxDegree: null,
  maxWattage: null,
  maximumHeight: null,
  minimumCustomHeight: null,
  minimumHeight: null,
  motorSize: null,
  mountingHoleQty: null,
  mpCube: null,
  mpHeight: null,
  mpLength: null,
  mpWidth: null,
  mpWeight: null,
  numberOfBlades: null,
  numberOfSockets: null,
  packQty: null,
  qtyFabricGlassShades: null,
  rod1Qty: null,
  rod2Qty: null,
  rod3Qty: null,
  rodTotalQty: null,
  shadeBottomDepth: null,
  shadeBottomWidth: null,
  shadeHeight: null,
  shadeQty: null,
  shadeTopDepth: null,
  shadeTopWidth: null,
  slNetWeight: null,
  standardEnergyUseWave: null,
  standardEstimatedYearlyEnergyCost: null,
  standardWeightedAverageAirflow: null,
  itemVendorCost: null,
  wattsHigh: null,
  wattsLow: null,
  wattsMedium: null,
  widthMax: null,
  widthMin: null,
  wireLength: null,
  is_220V: false,
  is_220VNotAvailable: false,
  ada: false,
  adjustable: false,
  adjustableArm: false,
  articulatingShadeHead: false,
  assembledInTheUSA: false,
  assembleRequired: false,
  bulbIncluded: false,
  cannotShipToCalifornia: false,
  cannotShipToCanada: false,
  cccCertification: false,
  convertible: false,
  convertibleKitRequired: false,
  darkSky: false,
  diffuser: false,
  dwpIncluded: false,
  emcCertification: false,
  energyStar: false,
  euCertification: false,
  extendable: false,
  fcCertification: false,
  fwcCompliant: false,
  globalAssortmentAvailable: false,
  hasCordCover: false,
  hasFanMount: false,
  icCertification: false,
  indicatorLight: false,
  ipRating: false,
  lightKitAdaptable: false,
  madeInTheUSA: false,
  madeToOrder: false,
  motionSensorIncluded: false,
  mountWallCeiling: false,
  mountingPlate: false,
  photocellIncluded: false,
  pivotable: false,
  preLacedChain: false,
  remoteIncluded: false,
  replaceableLamp: false,
  rod1Cuttable: false,
  rod1Removeable: false,
  rod2Cuttable: false,
  rod2Removeable: false,
  rod3Cuttable: false,
  rod3Removeable: false,
  shipByTruck: false,
  smartEnabled: false,
  title24: false,
  voltageConvertible: false,
  warmColorDimming: false,
  sellingLineInStockDate: null,
  bomType: null,
  commission: false,
  skuRank: null,
  skuRankDisplayText: "",
  bcRank: null,
  bcRankDisplayText: "",
  inventoryItem: false,
  sellingItem: false,
  purchasingItem: false,
  etlCertification: false,
  warehouse: null,
  warehouseDisplayText: "",
  vendorMOQ: null,
  vendorMOQType: null,
  vendorMOQTypeDisplayText: "",
  vendorLeadTime: null,
  slStatus: null,
  slStatusDisplayText: "",
  agreedSampleReadyDate: null,
  reqDate: null,
  targetIMAP: null,
  targetWholesalePrice: null,
  drawingNum: "",
  itemStockingType: null,
  itemStockingTypeDisplayText: "",
  targetFOB: null,
  sampleNotes: "",
  notes: "",
  canopyHeight: null,
  canopyWidth: null,
  bulbInBox2: "",
  transformerMount: "",
  reverseSwitch: "",
  factoryPrimaryFinish: "",
  factorySecondaryFinish: "",
  factoryThirdFinish: "",
  primaryFinish: null,
  primaryFinishCode: "",
  primaryFinishName: "",
  secondaryFinish: null,
  secondaryFinishCode: "",
  secondaryFinishName: "",
  thirdFinish: null,
  thirdFinishCode: "",
  thirdFinishName: "",
  developmentType: null,
  developmentTypeDisplayText: "",
  mfgSocketDetail: "",
  primaryMaterialDisplayText: "",
  secondaryMaterialDisplayText: "",
  thirdMaterialDisplayText: "",
  royaltyDesigner: null,
  royaltyDesignerDisplayText: "",
  royalty: false,
  itemStatus: null,
  etlControlNumber: null,
  etlControlNumberDisplayText: "",
  ukcaCertification: null,
  ukcaCertificationDisplayText: "",
  ukcaCertificationNumber: null,
  ukcaCertificationNumberDisplayText: "",
  customerAssemblyDetail: null,
  customerAssemblyDetailDisplayText: "",
  estimatedTimeRequiredForAssembly: null,
  estimatedTimeRequiredForAssemblyDisplayText: "",
  patentStatus: null,
  patentStatusDisplayText: "",
  suggestedNumberOfPeopleRequiredForInstall: null,
  suggestedNumberOfPeopleRequiredForInstallDisplayText: "",
  toolsRequired: null,
  toolsRequiredDisplayText: "",
  warrantyYears: null,
  warrantyYearsDisplayText: "",
  lightKitIncluded: null,
  lightKitIncludedDisplayText: "",
  masterFinish: null,
  masterFinishDisplayText: "",
  motorType: null,
  motorTypeDisplayText: "",
  relativeStyle: null,
  relativeStyleDisplayText: "",
  slopeCeiling: null,
  slopeCeilingDisplayText: "",
  specialAssembly: null,
  specialAssemblyDisplayText: "",
  swiveling: null,
  swivelingDisplayText: "",
  countryOfOrigin: null,
  countryOfOriginDisplayText: "",
  itemRoyaltyCode: null,
  itemRoyaltyCodeDisplayText: "",
  batteryLocation: null,
  batteryLocationDisplayText: "",
  batteryQty: null,
  batteryQtyDisplayText: "",
  bulbBase: null,
  bulbBaseDisplayText: "",
  bulbColorTemp: null,
  bulbColorTempDisplayText: "",
  bulbCri: null,
  bulbCriDisplayText: "",
  bulbIncandescentEquivalentWattage: null,
  bulbIncandescentEquivalentWattageDisplayText: "",
  bulbLumen: null,
  bulbLumenDisplayText: "",
  bulbVoltage: null,
  bulbVoltageDisplayText: "",
  chargeTime: null,
  chargeTimeDisplayText: "",
  dimmer: null,
  dimmerDisplayText: "",
  ledClass: null,
  ledClassDisplayText: "",
  lumensOnHighPower: null,
  lumensOnHighPowerDisplayText: "",
  lumensOnLowPower: null,
  lumensOnLowPowerDisplayText: "",
  lumensOnMedPower: null,
  lumensOnMedPowerDisplayText: "",
  recBulbBothRequired: null,
  recBulbBothRequiredDisplayText: "",
  voltageInput: null,
  voltageInputDisplayText: "",
  voltageOutput: null,
  voltageOutputDisplayText: "",
  fssPrimaryMaterial: null,
  fssPrimaryMaterialDisplayText: "",
  fssSecondaryMaterial: null,
  fssSecondaryMaterialDisplayText: "",
  fssThirdMaterial: null,
  fssThirdMaterialDisplayText: "",
  recommendedRoom1: null,
  recommendedRoom1DisplayText: "",
  diffuser1FactoryFinishCode: null,
  diffuser1FactoryFinishCodeDisplayText: "",
  diffuser1Finish: null,
  diffuser1FinishDisplayText: "",
  diffuser1Material: null,
  diffuser1MaterialDisplayText: "",
  diffuser2FactoryFinishCode: null,
  diffuser2FactoryFinishCodeDisplayText: "",
  diffuser2Finish: null,
  diffuser2FinishDisplayText: "",
  diffuser2Material: null,
  diffuser2MaterialDisplayText: "",
  finishCode: null,
  finishCodeDisplayText: "",
  finishDescription: null,
  finishDescriptionDisplayText: "",
  treatmentMaterial: null,
  treatmentMaterialDisplayText: "",
  ipFreightClass: null,
  ipFreightClassDisplayText: "",
  ipUpsable: null,
  ipUpsableDisplayText: "",
  mpUpsable: null,
  mpUpsableDisplayText: "",
  chainRodFinish: null,
  chainRodFinishDisplayText: "",
  connectionType: null,
  connectionTypeDisplayText: "",
  glass: null,
  glassDisplayText: "",
  productType: null,
  productTypeDisplayText: "",
  websites: null,
  websitesDisplayText: "",
  reverseSwitchLocation: null,
  reverseSwitchLocationDisplayText: "",
  shadeCode: null,
  shadeCodeDisplayText: "",
  shadeFinishingType: null,
  shadeFinishingTypeDisplayText: "",
  shadeOuterMaterial: null,
  shadeOuterMaterialDisplayText: "",
  shadeTreatment: null,
  shadeTreatmentDisplayText: "",
  socketFinishFactoryCode: null,
  socketFinishFactoryCodeDisplayText: "",
  socketHousingFinish: null,
  socketHousingFinishDisplayText: "",
  socketHousingMaterial: null,
  socketHousingMaterialDisplayText: "",
  productTrim: null,
  productTrimDisplayText: "",
  brand: null,
  brandDisplayText: null,
  buyerNumber: null,
  buyerNumberDisplayText: null,
  collection: null,
  collectionDisplayText: null,
  color: null,
  colorDisplayText: null,
  conversionFactor: null,
  conversionFactorDisplayText: null,
  shortDesc2: null,
  shortDesc2DisplayText: null,
  division: null,
  divisionDisplayText: null,
  engineeringGroup: null,
  engineeringGroupDisplayText: null,
  harmonizedShipCode: null,
  harmonizedShipCodeDisplayText: null,
  inventoryStrategy: null,
  inventoryStrategyDisplayText: null,
  itemFlashMessage: null,
  itemFlashMessageDisplayText: null,
  itemPoolCode: null,
  itemPoolCodeDisplayText: null,
  lampingCode: null,
  lampingCodeDisplayText: null,
  leadTimeLevel: null,
  leadTimeLevelDisplayText: null,
  ledRoyalty: null,
  ledRoyaltyDisplayText: null,
  plannerNumber: null,
  plannerNumberDisplayText: null,
  prepackedBanded: null,
  prepackedBandedDisplayText: null,
  productNameDescription: null,
  productNameDescriptionDisplayText: null,
  purchasingABC: null,
  purchasingABCDisplayText: null,
  quickShipItem: null,
  quickShipItemDisplayText: null,
  relatedUnitofMeasure: null,
  relatedUnitofMeasureDisplayText: null,
  searchText: null,
  searchTextDisplayText: null,
  shippingMethod: null,
  shippingMethodDisplayText: null,
  unitofMeasure: null,
  unitofMeasureDisplayText: null,
  yearCode: null,
  yearCodeDisplayText: null,
}

// These fields stores the reference id and not the actual value e.g. Brand, Category
export const REFERENCE_FIELDS_FOR_EDIT_PRODUCT = [
  "certiNote",
  "baseShape",
  "batteryType",
  "bulbShape",
  "bulbShapeCode",
  "canopyShape",
  "category",
  "colorTemperature",
  "cri",
  "department",
  "ledDimmingType",
  "driverLocation",
  "ledDriverType",
  "ledType",
  "lightSource",
  "locationRating",
  "mountingType",
  "reportingBrand",
  "socketType",
  "wireColor",
  "stage",
  "status",
  "royaltyDesigner",
  "marketingDesigner",
  "category",
  "function",
  "brandCollection",
  "style",
  "skuType",
  "bomType",
  "slStatus",
  "skuRank",
  "bcRank",
  "warehouse",
  "vendorMOQType",
  "itemStockingType",
  "primaryFinish",
  "secondaryFinish",
  "thirdFinish",
  "developmentType",
  "primaryMaterial",
  "secondaryMaterial",
  "thirdMaterial",
  "romanticSize",
  "vendor",
  "prop65",
  "itemStatus",
  "baseMaterial",
  "etlControlNumber",
  "ukcaCertification",
  "ukcaCertificationNumber",
  "customerAssemblyDetail",
  "estimatedTimeRequiredForAssembly",
  "patentStatus",
  "suggestedNumberOfPeopleRequiredForInstall",
  "toolsRequired",
  "warrantyYears",
  "lightKitIncluded",
  "masterFinish",
  "motorType",
  "relativeStyle",
  "slopeCeiling",
  "specialAssembly",
  "swiveling",
  "countryOfOrigin",
  "itemRoyaltyCode",
  "batteryLocation",
  "batteryQty",
  "bulbBase",
  "bulbColorTemp",
  "bulbCri",
  "bulbIncandescentEquivalentWattage",
  "bulbLumen",
  "bulbVoltage",
  "chargeTime",
  "dimmer",
  "ledClass",
  "lumensOnHighPower",
  "lumensOnLowPower",
  "lumensOnMedPower",
  "recBulbBothRequired",
  "voltageInput",
  "voltageOutput",
  "fssPrimaryMaterial",
  "fssSecondaryMaterial",
  "fssThirdMaterial",
  "recommendedRoom1",
  "diffuser1FactoryFinishCode",
  "diffuser1Finish",
  "diffuser1Material",
  "diffuser2FactoryFinishCode",
  "diffuser2Finish",
  "diffuser2Material",
  "finishCode",
  "finishDescription",
  "treatmentMaterial",
  "ipFreightClass",
  "ipUpsable",
  "mpUpsable",
  "chainRodFinish",
  "connectionType",
  "glass",
  "productType",
  "websites",
  "reverseSwitchLocation",
  "shadeCode",
  "shadeFinishingType",
  "shadeOuterMaterial",
  "shadeTreatment",
  "socketFinishFactoryCode",
  "socketHousingFinish",
  "socketHousingMaterial",
  "productTrim",
  "itemClass",
  "class",
  "relativeTrend",
  "seriesName",
  "brand",
  "buyerNumber",
  "collection",
  "color",
  "division",
  "engineeringGroup",
  "inventoryStrategy",
  "itemFlashMessage",
  "itemPoolCode",
  "lampingCode",
  "ledRoyalty",
  "plannerNumber",
  "prepackedBanded",
  "productNameDescription",
  "purchasingABC",
  "quickShipItem",
  "relatedUnitofMeasure",
  "shippingMethod",
  "unitofMeasure",
  "yearCode",
  "designGrouping",
];

export const VALIDATION_MESSAGE_TEXT = {
  characters: "characters",
  digitsNumber: "digits number"
}

export const checkMaxLengthValidation = (maxLength: number, fieldText: string, messageText: string) => {
  return Yup.string()
    .trim()
    .nullable()
    .max(maxLength, `${fieldText} must be at most ${maxLength} ${messageText}`)
};

export const checkDecimalNumberValidation = (fieldText: string, maxDigits: number = 10, maxDecimalPlaces: number = 2) => {
  const decimalRegex = maxDecimalPlaces === 2 ? /^\d{1,8}(?:\.\d{0,2})?$/ : /^\d{1,8}(?:\.\d{0,3})?$/;
  return Yup.string()
    .trim()
    .nullable()
    .matches(decimalRegex, `${fieldText} must be at most ${maxDigits} digits with ${maxDecimalPlaces} decimal places`)
};

export const validations = Yup.object().shape({
  brandCollection: Yup.string().trim().nullable().required("Brand Collection is required"),
  shortDesc: Yup.string()
    .trim()
    .required("Short Description is required")
    .min(1, "Short Description must be at least 1 characters")
    .max(200, "Short Descriptione must be at most 200 characters"),
  height: checkDecimalNumberValidation("Height"),
  width: checkDecimalNumberValidation("Width"),
  baseDepth: checkDecimalNumberValidation("Base Depth"),
  baseHeight: checkDecimalNumberValidation("Base Height"),
  baseLength: checkDecimalNumberValidation("Base Length"),
  baseWidth: checkDecimalNumberValidation("Base Width"),
  canopyDepth: checkDecimalNumberValidation("Canopy Depth"),
  canopyLength: checkDecimalNumberValidation("Canopy Length"),
  canopyShape: checkMaxLengthValidation(100, "Canopy Shape", VALIDATION_MESSAGE_TEXT.characters),
  backplate: checkMaxLengthValidation(100, "Backplate", VALIDATION_MESSAGE_TEXT.characters),
  backplateDepth: checkDecimalNumberValidation("Backplate Depth"),
  backplateHeight: checkDecimalNumberValidation("Backplate Height"),
  backplateLength: checkDecimalNumberValidation("Backplate Length"),
  backplateShape: checkMaxLengthValidation(100, "Backplate Shape", VALIDATION_MESSAGE_TEXT.characters),
  backplateWidth: checkDecimalNumberValidation("Backplate Width"),
  fixture_Height: checkDecimalNumberValidation(FIELD_NAMES.fixture_Height),
  height_min: checkDecimalNumberValidation(FIELD_NAMES.height_min),
  rod1Length: checkDecimalNumberValidation(FIELD_NAMES.rod1Length),
  rod2Length: checkDecimalNumberValidation(FIELD_NAMES.rod2Length),
  rod3Length: checkDecimalNumberValidation(FIELD_NAMES.rod3Length),
  shadeSlant: checkDecimalNumberValidation(FIELD_NAMES.shadeSlant),
  oaHeight: checkMaxLengthValidation(50, FIELD_NAMES.oaHeight, VALIDATION_MESSAGE_TEXT.characters),
  stockcode: checkMaxLengthValidation(50, FIELD_NAMES.stockcode, VALIDATION_MESSAGE_TEXT.characters),
  longDesc: checkMaxLengthValidation(200, FIELD_NAMES.longDesc, VALIDATION_MESSAGE_TEXT.characters),
  pdFamilyName: checkMaxLengthValidation(50, FIELD_NAMES.pdFamilyName, VALIDATION_MESSAGE_TEXT.characters),
  seriesName: checkMaxLengthValidation(100, FIELD_NAMES.seriesName, VALIDATION_MESSAGE_TEXT.characters),
  baseCode: checkMaxLengthValidation(30, FIELD_NAMES.baseCode, VALIDATION_MESSAGE_TEXT.characters),
  shortDescPrv: checkMaxLengthValidation(200, FIELD_NAMES.shortDescPrv, VALIDATION_MESSAGE_TEXT.characters),
  pdCode: checkMaxLengthValidation(50, FIELD_NAMES.pdCode, VALIDATION_MESSAGE_TEXT.characters),
  stockcodePrv: checkMaxLengthValidation(100, FIELD_NAMES.stockcodePrv, VALIDATION_MESSAGE_TEXT.characters),
  basecodePrv: checkMaxLengthValidation(50, FIELD_NAMES.basecodePrv, VALIDATION_MESSAGE_TEXT.characters),
  pdStockcode: checkMaxLengthValidation(100, FIELD_NAMES.pdStockcode, VALIDATION_MESSAGE_TEXT.characters),
  groupName: checkMaxLengthValidation(200, FIELD_NAMES.groupName, VALIDATION_MESSAGE_TEXT.characters),
  socketQty: checkMaxLengthValidation(10, FIELD_NAMES.socketQty, VALIDATION_MESSAGE_TEXT.digitsNumber),
  socketDtl: checkMaxLengthValidation(100, FIELD_NAMES.socketDtl, VALIDATION_MESSAGE_TEXT.characters),
  lightQty: checkMaxLengthValidation(10, FIELD_NAMES.lightQty, VALIDATION_MESSAGE_TEXT.digitsNumber),
  ledLumens: checkMaxLengthValidation(20, FIELD_NAMES.ledLumens, VALIDATION_MESSAGE_TEXT.characters),
  assembly: checkMaxLengthValidation(50, FIELD_NAMES.assembly, VALIDATION_MESSAGE_TEXT.characters),
  assemblyDetail: checkMaxLengthValidation(150, FIELD_NAMES.assemblyDetail, VALIDATION_MESSAGE_TEXT.characters),
  fourACDesc: checkMaxLengthValidation(1000, FIELD_NAMES.fourACDesc, VALIDATION_MESSAGE_TEXT.characters),
  treatmentType: checkMaxLengthValidation(20, FIELD_NAMES.treatmentType, VALIDATION_MESSAGE_TEXT.characters),
  treatment: checkMaxLengthValidation(20, FIELD_NAMES.treatment, VALIDATION_MESSAGE_TEXT.characters),
  shadeDesc: checkMaxLengthValidation(200, FIELD_NAMES.shadeDesc, VALIDATION_MESSAGE_TEXT.characters),
  wiringDtl: checkMaxLengthValidation(100, FIELD_NAMES.wiringDtl, VALIDATION_MESSAGE_TEXT.characters),
  candleTubeDiameter: checkDecimalNumberValidation(FIELD_NAMES.candleTubeDiameter),
  candleTubeFinish: checkMaxLengthValidation(200, FIELD_NAMES.candleTubeFinish, VALIDATION_MESSAGE_TEXT.characters),
  candleTubeHeight: checkDecimalNumberValidation(FIELD_NAMES.candleTubeHeight),
  harpFinish: checkMaxLengthValidation(100, FIELD_NAMES.harpFinish, VALIDATION_MESSAGE_TEXT.characters),
  harpType: checkMaxLengthValidation(100, FIELD_NAMES.harpType, VALIDATION_MESSAGE_TEXT.characters),
  riser: checkMaxLengthValidation(100, FIELD_NAMES.riser, VALIDATION_MESSAGE_TEXT.characters),
  finialFinish: checkMaxLengthValidation(100, FIELD_NAMES.finialFinish, VALIDATION_MESSAGE_TEXT.characters),
  finialHeight: checkDecimalNumberValidation(FIELD_NAMES.finialHeight),
  finialMaterial: checkMaxLengthValidation(100, FIELD_NAMES.finialMaterial, VALIDATION_MESSAGE_TEXT.characters),
  finialWidth: checkDecimalNumberValidation(FIELD_NAMES.finialWidth),
  rod_Count: checkMaxLengthValidation(4, FIELD_NAMES.rod_Count, VALIDATION_MESSAGE_TEXT.digitsNumber),
  rod_Diameter: checkMaxLengthValidation(40, FIELD_NAMES.rod_Diameter, VALIDATION_MESSAGE_TEXT.characters),
  break_count: checkMaxLengthValidation(4, FIELD_NAMES.break_count, VALIDATION_MESSAGE_TEXT.digitsNumber),
  break_length: checkMaxLengthValidation(40, FIELD_NAMES.break_length, VALIDATION_MESSAGE_TEXT.characters),
  rod_Shape: checkMaxLengthValidation(40, FIELD_NAMES.rod_Shape, VALIDATION_MESSAGE_TEXT.characters),
  finialFinishCd: checkMaxLengthValidation(10, FIELD_NAMES.finialFinishCd, VALIDATION_MESSAGE_TEXT.characters),
  chain: checkMaxLengthValidation(50, FIELD_NAMES.chain, VALIDATION_MESSAGE_TEXT.characters),
  factoryItemNum: checkMaxLengthValidation(100, FIELD_NAMES.factoryItemNum, VALIDATION_MESSAGE_TEXT.characters),
  factShadeGlassCode: checkMaxLengthValidation(50, FIELD_NAMES.factShadeGlassCode, VALIDATION_MESSAGE_TEXT.characters),
  subContVendor1FactoryItem: checkMaxLengthValidation(200, FIELD_NAMES.subContVendor1FactoryItem, VALIDATION_MESSAGE_TEXT.characters),
  subContVendor2FactoryItem: checkMaxLengthValidation(200, FIELD_NAMES.subContVendor2FactoryItem, VALIDATION_MESSAGE_TEXT.characters),
  subContVendor3FactoryItem: checkMaxLengthValidation(200, FIELD_NAMES.subContVendor3FactoryItem, VALIDATION_MESSAGE_TEXT.characters),
  subContVendor4FactoryItem: checkMaxLengthValidation(200, FIELD_NAMES.subContVendor4FactoryItem, VALIDATION_MESSAGE_TEXT.characters),
  subContVendor5FactoryItem: checkMaxLengthValidation(200, FIELD_NAMES.subContVendor5FactoryItem, VALIDATION_MESSAGE_TEXT.characters),
  targetWholesalePrice: checkDecimalNumberValidation(FIELD_NAMES.targetWholesalePrice),
  targetIMAP: checkDecimalNumberValidation(FIELD_NAMES.targetIMAP),
  cordCoverBreakLength: checkDecimalNumberValidation(FIELD_NAMES.cordCoverBreakLength, 7, 3),
  cordCoverBreakWidth: checkDecimalNumberValidation(FIELD_NAMES.cordCoverBreakWidth, 7, 3),
  couplingLength: checkDecimalNumberValidation(FIELD_NAMES.couplingLength, 7, 3),
  couplingWidth: checkDecimalNumberValidation(FIELD_NAMES.couplingWidth, 7, 3),
  ledBoardWattage: checkDecimalNumberValidation(FIELD_NAMES.ledBoardWattage, 7),
  itemVendorCost: checkDecimalNumberValidation("Vendor Cost")
});

// New validation schema to extend for Create Product screen
const extendedCreateProductValidations = Yup.object().shape({
  stage: Yup.string().trim().nullable().required("Stage is required"),
  category: Yup.string().trim().nullable().required("Category is required"),
  status: Yup.string().trim().nullable().required("Status is required"),
  function: Yup.string().trim().nullable().required("Function is required"),
});

// Combine the existing validations with the new create product schema validations
export const createProductValidations = validations.concat(extendedCreateProductValidations);

export const validExtensions = {
  tif: "tif",
  tiff: "tiff",
  jpg: "jpg",
  jpeg: "jpeg",
  png: "png",
  pdf: "pdf",
  doc: "doc",
  docx: "docx",
  zip: "zip",
  ai: "ai",
};

export const toolingValidExtensions = {
  xls: "xls",
  xlsx: "xlsx"
};

export const attachmentsValidationMessages = {
  attachmentTypeToastError: "Please select attachment type for all new added attachment(s) !",
  stockCodeInputBoxError: "Stock code is required",
  baseCodeInputBoxError: "Base code is required",
  stockCodeToastError: "Stock code is required for adding StockCode level attachments",
  baseCodeToastError: "Base code is required for adding BaseCode level attachments"
};

export const USER_ASSIGNMENTS_VALIDATION_MESSAGES = {
  userError: "Please select user in Product Assignments",
  assignmentRoleError: "Please select project role in Product Assignments"
};

export const ACCORDIAN_NAME = {
  certification: "CERTIFICATION & COMPLIANCE",
  dimension: "DIMENSIONS",
  features: "FEATURES",
  item: "ITEM",
  lamping: "LAMPING",
  maufacturing: "MANUFACTURING DETAILS",
  marketing: "MARKETING",
  materialFinish: "MATERIAL/FINISH DETAILS",
  packagingWeights:"PACKAGING AND WEIGHTS",
  productDetails:"PRODUCT DETAILS",
  vendor: "VENDOR",
  bom:"BILL OF MATERIALS (BOM)"
}
export const THUMBNAIL_IMAGE_API_URL = `${process.env.REACT_APP_API_URL}/Attachment/thumb-image`;

// BILL OF MATERIALS category needed as it is not present in get layout fields api response
export const BILL_OF_MATERIALS_LAYOUT_CATEGORY = {
  categoryName: "BILL OF MATERIALS (BOM)",
  tabOrder: 1,
  fields: [
    {
      fieldName: "bomType",
      label: "BOM Type",
      tabOrder: 1
    },
    {
      fieldName: "bomSearch",
      label: "bomSearch",
      tabOrder: 1
    },
    {
      fieldName: "dataGridView",
      label: "dataGridView",
      tabOrder: 1
    }],
}

export const PRICING_LAYOUT_CATEGORY = {
  categoryName: "Pricing",
  tabOrder: 1,
  fields: [
    {
      fieldName: "fobCost",
      label: "FOB Cost",
      tabOrder: 4
    },
    {
      fieldName: "quoteDate",
      label: "Quote Date",
      tabOrder: 2
    },
    {
      fieldName: "quoteNumber",
      label: "Quote Number",
      tabOrder: 1
    },
    {
      fieldName: "notes",
      label: "Notes",
      tabOrder: 3
    },
    {
      fieldName: "targetFOBCost",
      label: "Target FOB Cost",
      tabOrder: 5
    },
    {
      fieldName: "ipCube",
      label: "IP Cube",
      tabOrder: 7
    },
    {
      fieldName: "vendor",
      label: "Vendor",
      tabOrder: 8
    },
    {
      fieldName: "tariffPercent",
      label: "Tariff Percent",
      tabOrder: 6
    }, 
    {
      fieldName: "blankField",
      label: "Blank Field",
      tabOrder: 9
    },  
    {
      fieldName: "umapPrice",
      label: "UMAP Price",
      tabOrder: 13
    },
    // Calculation Read-Only Fields
    // {
    //   fieldName: "cubeMultiplier",
    //   label: "Cube Multiplier",
    //   tabOrder: 9
    // },
    {
      fieldName: "freightCost",
      label: "Freight Cost",
      tabOrder: 10
    },
    {
      fieldName: "landedCost",
      label: "Landed Cost",
      tabOrder: 11
    },
    {
      fieldName: "quoteApproved",
      label: "Quote Approved",
      tabOrder: 12
    },
    {
      fieldName: "dnPrice",
      label: "DN Price",
      tabOrder: 14
    },
    {
      fieldName: "grossGMPercent",
      label: "Gross GM Percent",
      tabOrder: 16
    },
    {
      fieldName: "netMargin",
      label: "Net Margin",
      tabOrder: 17
    },
    /* {
      fieldName: "blankField",
      label: "Blank Field",
      tabOrder: 12
    }, */
    {
      fieldName: "umapApproved",
      label: "UMAP Approved",
      tabOrder: 15
    },
    /* {
      fieldName: "commissionDollars",
      label: "Commission Dollars",
      tabOrder: 12
    },
    {
      fieldName: "commissionPercent",
      label: "Commission Percent",
      tabOrder: 13
    },
    {
      fieldName: "dnToUMAPMultiplier",
      label: "DN to UMAP Multiplier",
      tabOrder: 11
    },
    {
      fieldName: "dutyCost",
      label: "Duty Cost",
      tabOrder: 21
    },
    {
      fieldName: "grossGMDollars",
      label: "Gross GM Dollars",
      tabOrder: 20
    },
    {
      fieldName: "netMarginDollars",
      label: "Net Margin Dollars",
      tabOrder: 15
    },
    
    {
      fieldName: "percentOfFOB",
      label: "Percent of FOB",
      tabOrder: 15
    },
    {
      fieldName: "royaltyDollars",
      label: "Royalty Dollars",
      tabOrder: 16
    },
    {
      fieldName: "royaltyPercent",
      label: "Royalty Percent",
      tabOrder: 17
    },
    {
      fieldName: "tariffDollars",
      label: "Tariff Dollars",
      tabOrder: 19
    }, */
   
  ],
};

export const PRODUCT_BASIC_CATEGORY = {
  categoryName: "Product Basic",
  tabOrder: 1,
  fields: [
    {
      fieldName: "brandCollectionDisplayText",
      label: "Brand Collection",
      tabOrder: 1
    },
    {
      fieldName: "marketingDesignerDisplayText",
      label: "Marketing Designer",
      tabOrder: 2
    },
    {
      fieldName: "royaltyDesignerDisplayText",
      label: "Royalty Designer",
      tabOrder: 3
    },
    {
      fieldName: "categoryDisplayText",
      label: "Category",
      tabOrder: 4
    },
    {
      fieldName: "htsMaterial",
      label: "HTS Material",
      tabOrder: 5
    },
    {
      fieldName: "htsMaterialDtl",
      label: "HTS Material Detail",
      tabOrder: 6
    },
    {
      fieldName: "countryOfOriginDisplayText",
      label: "Country Of Origin",
      tabOrder: 7
    },
  ]
};

export const TOOLING_LAYOUT_CATEGORY = {
  categoryName: "Tooling",
  tabOrder: 1,
  fields: [
    {
      fieldName: "carNumber",
      label: "CAR Number",
      tabOrder: 2
    },
    {
      fieldName: "processedDate",
      label: "Processed Date",
      tabOrder: 3
    },
    {
      fieldName: "toolingNumber",
      label: "Tooling Number",
      tabOrder: 1
    },
    {
      fieldName: "toolingOpened",
      label: "Tooling Opened",
      tabOrder: 4
    }],
};

export const FIELDS_WITH_DISPLAY_TEXT = {
  brandCollection: "brandCollectionDisplayText",
  marketingDesigner: "marketingDesignerDisplayText",
  royaltyDesigner: "royaltyDesignerDisplayText",
  category: "categoryDisplayText",
  countryOfOrigin: "countryOfOriginDisplayText"
}

export const DEVELOPMENT_TYPE_DROPDOWN_OPTIONS = [
  { id: 1, name: "New Design" },
  { id: 2, name: "Family Extension" },
  { id: 3, name: "Finish Add" },
];

export const MAX_SIZE_FOR_REGULAR_UPLOAD = 25 * 1024 * 1024; // 25 MB

export const FILE_UPLOAD_CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB

export const BOM_COMPONENT_DEVELOPMENT_TYPE_DROPDOWN_OPTIONS = [
  { id: 4, name: "New Component" },
  { id: 5, name: "Existing Component" },
];

// Below is used to hide dropdown options from Stocking Type field that is displayed in Create BOM component modal
export const STOCKING_TYPE_HIDDEN_OPTIONS_FOR_BOM_COMPONENT = {
  sellingLine: "SAP - 02 - Selling Line",
  purchasedRawMaterial: "P - Purchased inc. Raw Material"
}
export const NO_TAGS_OPTION = { id: 0, name: "No Tags" };

export const PROJECT_MANAGER_ASSIGNMENT = "Project Manager";

export const PRODUCT_TYPE_DROPDOWN_OPTIONS = [
  { id: 0, name: "All Products" },
  { id: 2, name: "Components Only" },
  { id: 3, name: "New Components Only" },
  { id: 4, name: "Existing Components Only" },
  { id: 1, name: "Selling Line Items Only" },
];

export const QUOTE_APPROVAL_TYPE_DROPDOWN_OPTIONS = [
  { id: 1, name: "Quote Approved" },
  { id: 2, name: "UMAP Approved" },
];

export const QUOTE_CALCULATION_FIELDS = [
  "fobCost",
  "ipCube",
  "tariffPercent",
  "umapPrice",
];

export const QUOTE_APPROVAL_FIELDS = {
  quoteApproved: "quoteApproved",
  umapApproved: "umapApproved",
};

export const QUOTE_FIELD_SYMBOLS = {
  netMargin: "%",
  commissionDollars: "$",
  commissionPercent: "%",
  dutyCost: "$",
  dnPrice: "$",
  grossGMPercent: "%",
  grossGMDollars: "$",
  percentOfFOB: "%",
  royaltyPercent: "%",
  royaltyDollars: "$",
  freightCost: "$",
  landedCost: "$",
  netMarginDollars: "$",
  tariffDollars: "$",
  umapPrice: "$",
  fobCost: "$",
  tariffPercent: "%"
};

export const HEX_COLORS = [
  "#454545", "#F44336", "#9C27B0", "#3F51B5", "#2196F3", "#4CAF50", "#FFEB3B", "#FF9800", "#795548", "#607D8B", 
  "#3E2723", "#C2185B", "#D32F2F", "#1976D2", "#388E3C", "#0288D1", "#7B1FA2", "#0097A7", "#D32F2F", "#E91E63", 
  "#9C27B0", "#FF5722", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FF9800", "#9E9D24", "#F57C00", "#C2185B", 
  "#F06292", "#E91E63", "#9C27B0", "#2196F3", "#00BCD4", "#CDDC39", "#FFEB3B", "#8BC34A", "#FF5722", "#FFC107", 
  "#795548", "#9E9D24", "#FF9800", "#607D8B", "#FF5722", "#673AB7", "#9C27B0", "#00BCD4", "#3F51B5", "#FFEB3B", 
  "#FF9800", "#607D8B", "#009688", "#7B1FA2", "#0288D1", "#8BC34A", "#FFEB3B", "#FF5722", "#FF9800", "#9C27B0", 
  "#607D8B", "#795548", "#9E9D24", "#F57C00", "#2196F3", "#4CAF50", "#3E2723", "#D32F2F", "#1976D2", "#9C27B0", 
  "#CDDC39", "#4CAF50", "#FF9800", "#E91E63", "#2196F3", "#009688", "#FF5722", "#8BC34A", "#FFEB3B", "#F44336", 
  "#3F51B5", "#4CAF50", "#FF9800", "#8BC34A", "#9C27B0", "#FF5722", "#0288D1", "#7B1FA2", "#FFEB3B", "#CDDC39", 
  "#FF9800", "#9E9D24", "#607D8B", "#009688", "#FFEB3B", "#F44336", "#795548", "#E91E63", "#2196F3", "#388E3C"
];
export const EXCLUDED_ENDPOINTS_FOR_PREVENTING_GLOBAL_LOADER = ["/attachment/add", "/attachment/upload-chunks"];

export const ITEM_STATUS_DROPDOWN_OPTIONS = [
  { id: 1, name: "Active" },
  { id: 2, name: "Released To Inriver" },
  { id: 3, name: "Archived Development" },
];

export enum ITEM_STATUS_ENUM {
  Active = "Active",
  ReleasedToInriver = "ReleasedToInriver" ,
  ArchivedDevelopment = "ArchivedDevelopment" 
};

export const TOOLING_ATTACHMENT_LABEL = "Tooling Attachment";

export const VIEW_ATTACHMENTS = "View Attachments";

export const VIEW_TOOLINGS = "View Toolings";