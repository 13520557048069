import { Button, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid"
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { SeriesName } from "../../../models/SeriesName";
import { useEffect, useState } from "react";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { SessionDetails } from "../../../models/SessionDetails";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import SeriesNameModal from "./SeriesNameModal";
import * as Yup from "yup"; 
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON, GRID_READ_ONLY } from "../../../components/GridUtilityComponents";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";
import { formatAmount } from "../../../utils/helpers";

export default function SeriesNameSetting() {
    const [SeriesNameList, setSeriesNameList] = useState<SeriesName[]>([]);
    const [showAddUUpdateModal, setShowAddUpdateModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const SeriesNameColumns : GridColDef [] = [
        { field: "id", headerName: "Id", width: 100},
        { field: "description", headerName: "Series Name", width: 300},
        { field: "pimKey", headerName: "Pim Key", width: 300},
        { field: "jdeCode", headerName: "JDE Code", width: 300},
        { field: "budget", headerName: "Budget", width: 200,
            valueGetter: (params) => params?.value ? formatAmount(params?.value || 0, "USD") : null},
        { field: "isActive", headerName: "Is Active", width: 150, sortable: false,
            renderCell: (params: any) => {
                if(params.row.readOnlyField)
                    return GRID_READ_ONLY();

                return(
                    <Switch
                        checked = {params.value}
                        onChange = {async (e) => {
                            await updateSeriesName(params.row, e.target.checked);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "controlled" }}
                        disabled = {!manageAppSetting}
                    />
                )
            }
        },
        { field: "actions", type: "actions", headerName: "Actions", width: 200,
        getActions: (params: any) => {
            if (params.row.readOnlyField) {
                return [
                    GRID_READ_ONLY()
                ];
            }
            return [
                GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
                GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
            ];
            }
        }
    ];

    const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

    useEffect(() =>{
        fetchData();
    },[]);

    const fetchData = async () => {
        const getSeriesNameListResponse = await api.get<ApiResponse<SeriesName[]>>("/Picklist/series-name-list");
        setSeriesNameList(getSeriesNameListResponse?.data || []);
    };

    const handleEditClickOpen = (gridParams: any) => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
        formik.setValues({...gridParams.row});
    };

    const handleAddClickOpen = () => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
    };

    const handleDeleteModalOpen = (gridParams: any) => {
        setDeleteModalOpen(true);
        formik.setValues({...gridParams.row}); 
    }

    const handleCloseModal = () => {
        setShowAddUpdateModal(false);
        setDeleteModalOpen(false);
        formik.resetForm();
    };

    const updateSeriesName = async (row: SeriesName, isActive: boolean) => {
        row.isActive = isActive;
        try{
            api 
                .put<ApiResponse<number>>("/Picklist/series-name", row)
                .then(async (response: any) => {
                    if(response.isSuccess) {                        
                        toast.success(response.message);
                        await fetchData();
                    } else {
                        throw new Error(response.message);
                    }
                })
                .catch(() => {});
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const deleteSubmit = async () => {
        try{
            api
                .delete<ApiResponse<SessionDetails>>(`/Picklist/series-name/${formik.values.id}`)
                .then(async (response: any) => {
                    if(response.isSuccess) {
                        setDeleteModalOpen(false);
                        formik.resetForm();
                        toast.success(response.message);
                        await fetchData();
                    } else {
                        throw new Error(response.message);
                    }
                })
                .catch(() => {});
        } catch(err: any) {
            toast.error(err.message);
        }
    };

    const formik = useFormik ({
        initialValues: {
            id: 0,
            description: '',
            pimKey: '',
            jdeCode: null,
            budget: null,
            isActive: true
        },
        validationSchema: Yup.object({
            description: Yup.string()
                .trim()
                .min(1, "Series Name must be at least 1 characters")
                .max(100, "Series Name must be at most 100 characters")
                .required("Series Name is required")
                .test("check-description-duplicate", "Series Name already exists", 
                (value) => {
                    return !SeriesNameList.some(
                        (item) => item.description.trim().toLowerCase() === 
                        value.trim().toLowerCase() && item.id !== formik.values.id
                        )
                }),
            pimKey: Yup.string()
                .trim()
                .min(1, "Pim Key must be at least 1 characters")
                .max(100, "Pim Key must be at most 100 characters")
                .required("Pim Key is required")
                .test("check-pimkey-duplicate", "Pim Key already exists", 
                (value) => {
                    return !SeriesNameList.some(
                        (item) => item.pimKey.trim().toLowerCase() === 
                        value.trim().toLowerCase() && item.id !== formik.values.id
                        )
                }),
            jdeCode: Yup.string()
                .nullable()
                .max(20, "JDE Code must be at most 20 characters")
                .test("check-jdeCode-duplicate", "JDE Code already exists", 
                    (value) => {
                        if(!value) {
                            return true;
                        }

                        return !!value && !SeriesNameList.some(
                            (item) => item?.jdeCode && value && item.jdeCode?.trim().toLowerCase() === 
                            value.trim().toLowerCase() && item.id !== formik.values.id
                        )
                    }),
            budget: Yup.number()
                .nullable()
                .min(1, "Budget cannot be 0")
        }),
        onSubmit: async (values) => {
            setDisableSubmit(true);
            if(values.id > 0) {
                api 
                    .put<ApiResponse<number>>("/Picklist/series-name", {
                        id: values.id,
                        description: values.description,
                        pimKey: values.pimKey,
                        jdeCode: values?.jdeCode || null,
                        budget: values?.budget || null,
                        isActive: JSON.parse(`${values.isActive}`)
                    })
                    .then(async (response: any) => {
                        if(response.isSuccess) {
                            setShowAddUpdateModal(false);
                            formik.resetForm();
                            toast.success(response.message);
                            await fetchData();
                        } else {
                            throw new Error(response.message);
                        }
                    })
                    .catch(() => {});
            } else {
                api 
                .post<ApiResponse<number>>("/Picklist/series-name", {
                    description: values.description,
                    pimKey: values.pimKey,
                    jdeCode: values?.jdeCode || null,
                    budget: values?.budget || null,
                    isActive: JSON.parse(`${values.isActive}`)
                })
                .then(async (response: any) => {
                    if(response.isSuccess) {
                        setShowAddUpdateModal(false);
                        formik.resetForm();
                        toast.success(response.message);
                        await fetchData();
                    } else {
                        throw new Error(response.message);
                    }
                })
                .catch(() => {});
            }
        }
    })


    return (
        <>
            <div>
                {manageAppSetting && <Button variant = "contained" onClick = {handleAddClickOpen}>
                    Add Series Name
                </Button>}
                <SeriesNameModal 
                    isOpen={showAddUUpdateModal} 
                    onClose={handleCloseModal} 
                    formik={formik}
                    isButtonDisabled={disableSubmit}
                />
                <DeleteValidationModal 
                    addModalOpen={deleteModalOpen} 
                    handleCloseModal={handleCloseModal} 
                    deleteSubmit={deleteSubmit}
                    fieldIdentifier={"Series Name"}
                />
            </div>
            <SettingGrid 
                data = {SeriesNameList} 
                column = {SeriesNameColumns}
                sortByField = "id"
                sortByOrder = "desc"
                columnVisibility={{
                    actions: manageAppSetting,
                  }}          
            />
        </>
    )
};