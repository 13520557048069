import { Box, Grid, Typography } from "@mui/material";
import { formatAmount } from "../utils/helpers";

export type ToolingDetails = {
  seriesName: string;
  runningTotal: number;
  totalBudget: number;
};

type Props = {
  toolingDetails: ToolingDetails;
};

const ToolingInformation = (props: Props) => {

  const {
    toolingDetails
  } = props;

  return (
    <div style={{ marginTop: "20px" }}>
      <Box
        sx={{
          border: "1px solid",
          borderRadius: "4px",
          padding: "15px",
          margin: "20px 0",
        }}
      >
        <Typography variant="h6" sx={{ marginLeft: "5px", marginBottom: "30px" }}>Tooling</Typography>
        <Grid container sx={{ marginLeft: 0, gap: "150px"}}>
          {toolingDetails?.totalBudget ? <>
            <Typography variant="h6" sx={{ marginLeft: "5px", marginBottom: "10px" }}>{`Tooling Running Total: ${formatAmount(toolingDetails?.runningTotal || 0, "USD")}`}</Typography>
            <Typography variant="h6" sx={{ marginLeft: "5px", marginBottom: "10px" }}>{`Tooling Budget: ${formatAmount(toolingDetails.totalBudget, "USD")}`}</Typography>
          </> : 
            <Typography variant="h6" sx={{ marginLeft: "5px", marginBottom: "10px", color: "#ff0000" }}>{`Tooling budget is not set for series name "${toolingDetails?.seriesName}".`}</Typography>
          }
        </Grid>
      </Box>
    </div>
  )
}

export default ToolingInformation;