import * as signalR from "@microsoft/signalr";
import { SignalREvents } from "../utils/constants";
import { Notification } from "../models/Notification";
const URL = process.env.REACT_APP_HUB_ADDRESS;

type SendMessageType = keyof typeof SignalREvents.SendMessages;
type ReceiveMessageType = keyof typeof SignalREvents.ReceivedMessages;

type ReceiveMessageEvents = {
    itemCreated?: (notification: Notification) => void,
    itemFieldUpdated?: (fieldName: string, itemId: number) => void,
    userOpendItemInEditMode?: (userName:string, itemId: number) => void,
    itemModified?: (notification: Notification) => void,
    processExecutiveReview?: (notification: Notification) => void,
    productCommentUpdated?: (notification: Notification) => void,
};

class SignalRConnector {
    private connection: signalR.HubConnection;
    private subscribers: ReceiveMessageEvents[] = [];    
    
    static instance: SignalRConnector;
    constructor() {
        const sessionInfo: any = JSON.parse(
            window?.localStorage.getItem("session") || "{}"
          );
          
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL, {
                accessTokenFactory: () => {
                    return sessionInfo.token
                },
                withCredentials: false
            })
            .withAutomaticReconnect()
            .build();
        this.connection
            .start()
            .then(() => console.log("Connected"))
            .catch(err => console.error("SignalR Failure", err));

        this.listenForMessages();
    }

    private async listenForMessages() {
        var receiveEvents = Object.keys(SignalREvents.ReceivedMessages);
        receiveEvents.forEach(e => {
            this.connection.on(e, async (msg) => this.notifySubscribers(e as any, msg));
        });
    }
    
    public async sendMessage<T>(eventType: SendMessageType, message: T) {
        return this.connection.send(SignalREvents.SendMessages[eventType], message).then(x => console.log("sent"));
    }

    public subscribe(subscriber: ReceiveMessageEvents) {
        this.subscribers.push(subscriber);
    }
    
    public unsubscribe(subscriber: ReceiveMessageEvents) {
        this.subscribers = this.subscribers.filter((s) => s !== subscriber);
    }

    private notifySubscribers(eventType: keyof ReceiveMessageType, ...args: any[]) {
        this.subscribers.forEach((subscriber) => {
          const eventHandler = subscriber[eventType];
          if (eventHandler) {
            eventHandler(...args);
          }
        });
    }
    
    public static getInstance(): SignalRConnector {
        if (!SignalRConnector.instance)
        SignalRConnector.instance = new SignalRConnector();
        return SignalRConnector.instance;
    }
}

export default SignalRConnector.getInstance;